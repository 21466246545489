import {
  DataGrid,
  dayjs,
  Modal as ModalUiv2,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ModalInterface } from "./types";

import { FlexContainer } from "@components/FlexContainer";
import { DataGridContainer } from "./styles";
import Table from "@components/v2/Table";
const { Root: ModalRoot } = ModalUiv2;

const EmployeesModal = ({ open, employees, onClose }: ModalInterface) => {
  return (
    <ModalRoot
      open={open}
      onClose={onClose}
      showClose={false}
      children={
        <FlexContainer
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <FlexContainer
            flexDirection="column"
            alignItems="flex-start"
            gap={8}
            padding={24}
            width="100%"
            style={{ borderBottom: "1px solid var(--color-neutral-90)" }}
          >
            <Typography
              variant="headline9"
              variantColor="var(--color-neutral-30)"
              weight={700}
            >
              Pergunta
            </Typography>
            <Typography
              variant="headline7"
              variantColor="var(--color-neutral-10)"
              weight={700}
            >
              Relacionamento com a equipe
            </Typography>
          </FlexContainer>
          <FlexContainer
            padding={24}
            width="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Typography
              variant="headline9"
              variantColor="var(--color-neutral-30)"
              weight={700}
            >
              Pessoas respondentes
            </Typography>
          </FlexContainer>
          <FlexContainer width="100%" style={{ padding: "0 24px" }}>
            <Table
              columns={[
                {
                  header: "Pessoas",
                  accessorKey: "name",
                  cell: (e) => <div>{e?.row?.original?.name}</div>,
                },
                {
                  header: "Nota",
                  accessorKey: "answer",
                  minSize: 100,
                  cell: (e) => <div>{e?.row?.original?.answer}</div>,
                },
                {
                  header: "Data da resposta",
                  accessorKey: "date",
                  cell: (e) => (
                    <div>
                      {e?.row?.original?.date
                        ? dayjs(e?.row?.original?.date)?.format("DD/MM/YYYY")
                        : ""}
                    </div>
                  ),
                },
              ]}
              data={employees || []}
            />
          </FlexContainer>
        </FlexContainer>
      }
    />
  );
};

export { EmployeesModal };
