import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { ModelContent } from "../FloatContent";
import EngajaModelQuestions from "@components/EngajaModelQuestions";
import { segment } from "@utils";

export default function EngajaModelDetails({
  innerCardProps,
  modelDescription,
}) {
  return (
    <Tab
      onTabChanged={(e) => {
        if (e === 1) {
          segment({
            track:
              "people_strategic_hr_engagement_company_engajadetails_questions_clicked",
          });
        }
      }}
      tabItens={[
        {
          label: `Detalhes`,
          component: (
            <ModelContent
              innerCardProps={innerCardProps}
              description={modelDescription}
            />
          ),
        },
        {
          label: `Perguntas`,
          component: <EngajaModelQuestions />,
        },
      ]}
    />
  );
}
