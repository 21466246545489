import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { DraftQuestion, DraftSurvey } from "../../../screen/types";
import { MiniSection } from "../../../steps/content/components/MiniSection";
import { Divider } from "@components/v2";
import { SectionModalForm } from "../../../steps/content/components/DragAndDrop/components";
import { Container } from "./styles";

const reorder = (
  list: DraftSurvey["sections"],
  startIndex: number,
  endIndex: number
) => {
  if (list?.length) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  return [];
};

const DragAndDrop = ({ formik }: { formik?: any }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <DragDropContext
        onDragEnd={(e) => {
          e?.destination
            ? formik.setValues({
                ...formik.values,
                selectedSectionIndex: e?.destination?.index,
                sections: reorder(
                  formik?.values?.sections,
                  e?.source?.index,
                  e?.destination?.index
                ),
              })
            : null;
        }}
      >
        <Droppable droppableId="section-droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ width: "100%" }}
            >
              {formik?.values?.sections?.map((s, i) => {
                const draggableId = `d-${i}`;
                return (
                  <Draggable
                    key={draggableId}
                    draggableId={draggableId}
                    index={i}
                    isDragDisabled={false}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided?.draggableProps?.style}
                      >
                        <MiniSection
                          onClick={() => {
                            formik.handleChange({
                              target: {
                                id: "selectedSectionIndex",
                                value: i,
                              },
                            });
                          }}
                          title={s?.title}
                          totalQuestions={s?.questions?.length || 0}
                          isDragging={snapshot?.isDragging}
                          selected={formik?.values?.selectedSectionIndex === i}
                        />
                        <Divider spacing="xxxs" />
                        {provided?.placeholder}
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <LinkButton
        disabled={false}
        variant="neutral"
        onClick={() => setOpen(true)}
      >
        <Icons name="IconPlus" />
        Adicionar nova seção
      </LinkButton>
      <SectionModalForm
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(newSection) => {
          const question: DraftQuestion = {
            title: "",
            answerFormat: undefined,
            isENPS: false,
            justification: false,
            required: false,
          };

          formik?.handleChange({
            target: {
              id: "sections",
              value: [
                ...(formik?.values?.sections || []),
                { ...newSection, questions: [question] },
              ],
            },
          });
          setOpen(false);
        }}
      />
    </Container>
  );
};

export { DragAndDrop };
