import {
  DatePicker,
  dayjs,
  Dayjs,
  SelectField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Row, StyleContainer } from "./styles";
import { Typography } from "@components/Typography";
import { Divider } from "@components/v2";
import { useEffect, useState } from "react";

const Single = ({ formik }: { formik: any }) => {
  const [periodOptions, setPeriodOptions] = useState<
    { label: string; value: "morning" | "afternoon" | "night" }[]
  >([
    {
      label: "Manhã (08h-12h)",
      value: "morning",
    },
    {
      label: "Tarde (12h-18h)",
      value: "afternoon",
    },
    {
      label: "Noite (18h-22h)",
      value: "night",
    },
  ]);

  useEffect(() => {
    const referenceDate =
      formik?.values?.startDate?.date || dayjs().format("YYYY-MM-DD");

    const diff = dayjs(dayjs()?.format("YYYY-MM-DD")).diff(
      dayjs(referenceDate)?.format("YYYY-MM-DD"),
      "day"
    );

    if (diff >= 0) {
      const hour = parseInt(dayjs()?.format("HH"));

      if (hour < 12) {
        setPeriodOptions([
          {
            label: "Imediatamente",
            value: "morning",
          },
          {
            label: "Tarde (12h-18h)",
            value: "afternoon",
          },
          {
            label: "Noite (18h-22h)",
            value: "night",
          },
        ]);
        return;
      }

      if (hour < 18) {
        setPeriodOptions([
          {
            label: "Imediatamente",
            value: "afternoon",
          },
          {
            label: "Noite (18h-22h)",
            value: "night",
          },
        ]);
        return;
      }

      setPeriodOptions([
        {
          label: "Imediatamente",
          value: "night",
        },
      ]);
    } else {
      setPeriodOptions([
        {
          label: "Manhã (08h-12h)",
          value: "morning",
        },
        {
          label: "Tarde (12h-18h)",
          value: "afternoon",
        },
        {
          label: "Noite (18h-22h)",
          value: "night",
        },
      ]);
    }
  }, [formik?.values?.startDate?.date]);

  useEffect(() => {
    if (formik?.values?.startDate?.period) {
      const selectedPeriod = periodOptions.find(
        ({ value }) => value === formik.values.startDate.period
      );

      if (!selectedPeriod) {
        formik.setValues({
          ...formik.values,
          startDate: {
            ...formik.values.startDate,
            period: undefined,
          },
        });
      }
    }
  }, [periodOptions]);

  return (
    <StyleContainer>
      <Typography
        variant="headline9"
        fontWeight={700}
        color="var(--color-neutral-30)"
      >
        Envio e prazo de respostas
      </Typography>

      <Divider spacing="quark" />

      <Typography
        variant="body4"
        color="var(--color-neutral-50)"
        fontWeight={400}
      >
        A pesquisa estará disponível para respostas durante o período abaixo.
      </Typography>

      <Divider spacing="xs1" />

      <Row>
        <DatePicker
          className="delivery-field"
          disabled={false}
          fromDate={dayjs(new Date())}
          toDate={
            formik?.values?.interactionDeadline
              ? dayjs(formik?.values?.interactionDeadline).subtract(1, "day")
              : undefined
          }
          label="Data de envio"
          onDateChange={(date: Dayjs | undefined) => {
            if (date) {
              const value = dayjs(date)?.format("YYYY-MM-DD");
              if (value && value !== "Invalid Date")
                formik.setValues({
                  ...formik.values,
                  startDate: {
                    date: value,
                    period: formik.values.startDate?.period,
                  },
                });
            }
          }}
          value={
            formik?.values?.startDate?.date &&
            formik?.values?.startDate?.date !== "Invalid Date"
              ? dayjs(formik?.values?.startDate?.date)
              : undefined
          }
          error={
            (formik.touched.startDate || formik.submitCount > 0) &&
            Boolean(formik.errors.startDate?.date)
          }
          helperText={
            formik?.touched?.startDate?.date &&
            (formik?.errors?.startDate?.date as string)
          }
        />

        <SelectField
          className="delivery-field"
          disabled={false}
          label="Período"
          fullWidth
          options={periodOptions}
          onSelectChange={(_: any, option) => {
            const value = option?.value;

            if (value) {
              formik.setValues({
                ...formik.values,
                startDate: {
                  date: formik.values.startDate?.date || undefined,
                  period: value,
                },
              });
            }
          }}
          value={formik?.values?.startDate?.period}
          error={
            (formik.touched.startDate || formik.submitCount > 0) &&
            Boolean(formik.errors.startDate?.period)
          }
          helperText={
            formik?.touched?.startDate?.period &&
            (formik?.errors?.startDate?.period as string)
          }
        />
      </Row>

      <Divider spacing="xs1" />

      <DatePicker
        className="delivery-field"
        disabled={!formik?.values?.startDate?.date}
        fromDate={
          formik?.values?.startDate?.date
            ? dayjs(formik?.values?.startDate?.date)
            : dayjs(new Date())
        }
        label="Prazo de resposta"
        onDateChange={(date: Dayjs | undefined) => {
          if (date) {
            const value = dayjs(date)?.format("YYYY-MM-DD");
            if (value && value !== "Invalid Date")
              formik.handleChange({
                target: {
                  id: "interactionDeadline",
                  value: value,
                },
              });
          }
        }}
        value={
          formik?.values?.interactionDeadline &&
          formik?.values?.interactionDeadline !== "Invalid Date"
            ? dayjs(formik?.values?.interactionDeadline)
            : undefined
        }
        error={
          (formik.touched.interactionDeadline || formik.submitCount > 0) &&
          Boolean(formik.errors.interactionDeadline)
        }
        helperText={
          formik?.touched?.interactionDeadline &&
          (formik?.errors?.interactionDeadline as string)
        }
      />
    </StyleContainer>
  );
};

export { Single };
