import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, SectionContainer, NumberContainer } from "./styles";
import { SectionNavigateProps } from "./types";

const SectionNavigate = ({
  sections,
  selectedSection,
  onClick,
}: SectionNavigateProps) => (
  <Container>
    {sections?.map((s) => (
      <SectionContainer
        key={s?.id}
        onClick={() => onClick(s?.id || "")}
        selected={selectedSection === s?.id}
      >
        <Typography
          variant="body4"
          variantColor={
            selectedSection === s?.id
              ? "var(--color-secondary-50)"
              : "var(--color-neutral-50)"
          }
          weight={600}
        >
          {s?.title}
        </Typography>
        <NumberContainer selected={selectedSection === s?.id}>
          <Typography
            variant="caption"
            variantColor={
              selectedSection === s?.id
                ? "var(--color-secondary-50)"
                : "var(--color-neutral-10)"
            }
            weight={600}
          >
            {s?.questions?.length || 0}
          </Typography>
        </NumberContainer>
      </SectionContainer>
    ))}
  </Container>
);

export { SectionNavigate };
