import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: calc(100% - 152px);
  overflow-x: auto;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
`;

const PageTitleContainer = styled.div`
  padding: 24px 0;
  width: 100%;
`;

export { Container, FormContainer, PageTitleContainer };
