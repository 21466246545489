import { dayjs, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, DateContainer } from "../styles";

export default ({
  createdAt,
  lastSendDate,
}: {
  createdAt: string;
  lastSendDate?: string;
}) => {
  return (
    <Container>
      <DateContainer>
        <Icons name="IconCalendar" color="var(--color-secondary-50)" />
        <Typography
          variant="body4"
          weight={600}
          variantColor="var(--color-neutral-50)"
        >
          Criado em
        </Typography>
        <Typography
          variant="headline9"
          weight={700}
          variantColor="var(--color-neutral-30)"
        >
          {createdAt ? dayjs(createdAt)?.format("DD/MM/YYYY") : ""}
        </Typography>
      </DateContainer>
      {lastSendDate ? (
        <DateContainer>
          <Icons name="IconCalendar" color="var(--color-secondary-50)" />
          <Typography
            variant="body4"
            weight={600}
            variantColor="var(--color-neutral-50)"
          >
            último envio
          </Typography>
          <Typography
            variant="headline9"
            weight={700}
            variantColor="var(--color-neutral-30)"
          >
            {dayjs(lastSendDate)?.format("DD/MM/YYYY")}
          </Typography>
        </DateContainer>
      ) : null}
      <DateContainer>
        <Icons name="IconRefresh" color="var(--color-secondary-50)" />
        <Typography
          variant="body4"
          weight={600}
          variantColor="var(--color-neutral-50)"
        >
          Dados atualizados a cada hora
        </Typography>
      </DateContainer>
    </Container>
  );
};
