import {
  dayjs,
  getCroppedImage as getCroppedImageWebUi,
} from "@flash-tecnologia/hros-web-ui-v2";
import { segmentEventTracking } from "@flash-tecnologia/hros-web-utility";

type ObjectLiteral = {
  [key: string]: string;
};

interface UploadProps {
  file: File;
  fileName?: string;
  module?: string;
  onProgress?: (percentage: number) => any;
}

interface getS3PresignedProps {
  filename: string;
  module?: string;
}

const alert = (message: string, type?: string) => {
  dispatchEvent(
    new CustomEvent("showToast", {
      detail: {
        content: message,
        type: type || "error",
      },
    })
  );
};

function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/);
  const type = mime && mime[1] ? mime[1] : "";
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type });
}

const getCroppedImage = async ({
  fileName,
  fileData,
  croppedAreaPixels,
}: {
  fileName: string;
  fileData: string;
  croppedAreaPixels: any;
}) => {
  const base64 = await getCroppedImageWebUi(fileData, croppedAreaPixels);

  const file = dataURLtoFile(base64 || "", fileName);

  return {
    data: base64,
    file,
  };
};

const segment = ({
  track,
  params,
  identify,
}: {
  track: string;
  params?: ObjectLiteral;
  identify?: {
    id: string;
    params?: ObjectLiteral;
  };
}) => {
  console.log(track);
  segmentEventTracking({
    name: track,
    module: "engagement",
    businessUnit: "people",
    params,
    identify,
  });
};

const handleTrackNameByCampaignType = (
  track: string,
  type: "research" | "communication"
) =>
  track?.replace(
    type === "communication" ? "survey" : "communication",
    type === "research" ? "survey" : "communication"
  );

const handleImagePath = (path: string) => {
  const pathWihoutSignature = path?.split("?")[0];
  const result = pathWihoutSignature?.split("amazonaws.com/")[1];
  return result || path;
};

const getPeriod = (date: dayjs.Dayjs) => {
  const dateHour = date?.hour();

  if (dateHour > 11 && dateHour < 18) return "afternoon";
  if (dateHour > 17) return "night";
  return "morning";
};

const capitalize = (text: string) => text[0].toUpperCase() + text.slice(1);

const formatNumberToTwoDecimals = (num: number) => {
  const value = `${num}`;
  if (!value?.includes(".")) return value;
  const [int, decimal] = value?.split(".");
  return `${int}.${decimal.slice(0, 2)}`;
};

export {
  alert,
  capitalize,
  dataURLtoFile,
  getCroppedImage,
  getPeriod,
  handleImagePath,
  handleTrackNameByCampaignType,
  segment,
  formatNumberToTwoDecimals,
};

export * from "./common-styled.utils";
export { default as dispatchToast } from "./dispatchToast";
export * from "./remove-html-tags.utils";
export * from "./sanitize-html.utils";
export * from "./upload";
export * from "./use-before-unload.utils";
export * from "./validate-user-ownership.utils";
