import { GenericBox } from "@components/GenericBox";
import { GraphStackedBar } from "@components/Graphs";
import { GraphEmptyState } from "@components/Graphs/GraphEmptyState";
import { GraphHeader } from "@components/Graphs/GraphHeader";
import { StackedChartProps } from "./types";

export const StackedChart = ({ info, settings, data }: StackedChartProps) => {
  const isEmpty = !data?.length;

  return (
    <GenericBox size="custom">
      <GraphHeader
        info={info}
        // reportName="FEELING_SECOND_GRAPH"
        // testCompanyId={testCompanyId}
      />
      {isEmpty ? (
        <GraphEmptyState />
      ) : (
        <GraphStackedBar data={data} settings={settings} />
      )}
    </GenericBox>
  );
};
