import {
  EmptyState,
  ENPSCharts,
  FlexContainer,
  VerticalBarChart,
} from "@components/index";
import { ENPSInterface } from "./types";
import { useState } from "react";
import { SelectField } from "@flash-tecnologia/hros-web-ui-v2";

const ENPS = ({ questions }: ENPSInterface) => {
  const questionIds = questions?.map((q) => q?.id);
  const [selectedQuestion, setSelectedQuestion] = useState(questionIds[0]);

  if (!questions?.length)
    return (
      <FlexContainer width="100%" style={{ marginBottom: "26px" }}>
        <EmptyState
          title="Nenhum dado encontrado
para exibir esse gráfico."
          subTitle=""
        />
      </FlexContainer>
    );

  return (
    <FlexContainer flexDirection="column" gap={24} width="100%">
      <SelectField
        fullWidth
        disabled={false}
        label="Selecione o período"
        options={questions?.map((q) => ({ label: q?.title, value: q?.id }))}
        onSelectChange={(_: any, option) => {
          setSelectedQuestion(option?.value);
        }}
        value={selectedQuestion}
      />
      {questions
        ?.filter((q) => q?.id === selectedQuestion)
        ?.map((question, i) => (
          <FlexContainer flexDirection="column" key={i} width="100%">
            <ENPSCharts
              detractors={question?.enps?.detractors || 0}
              neutrals={question?.enps?.neutrals || 0}
              promoters={question?.enps?.promoters || 0}
              score={question?.enps?.score || 0}
            />
            <VerticalBarChart
              enps={Boolean(question?.enps)}
              title={question?.title || ""}
              data={
                question?.options?.map((o) => ({
                  ...o,
                  name: o?.value || "",
                  value: o?.count,
                })) || []
              }
            />
          </FlexContainer>
        ))}
    </FlexContainer>
  );
};

export default ENPS;
