import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 325px;
  min-height: 340px;
  padding: 40px 24px;
  border-radius: 12px;
  border: 1px solid var(--color-neutral-90);
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const SubtitleContainer = styled.div`
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding-top: 16px;
  margin-top: auto;
  box-sizing: border-box;

  button {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px !important;
    font-size: 16px !important;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
`;

const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  margin-top: 24px;
`;

const CardButton = styled(Button)`
  && {
    min-width: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    padding: 12px 8px !important;
  }
`;

export {
  Container,
  ContentContainer,
  SubtitleContainer,
  CardsContainer,
  ButtonsContainer,
  IconsWrapper,
  CardButton,
};
