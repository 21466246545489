import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  dayjs,
  Icons,
  LinkButton,
  PageContainer,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { FlexContainer } from "@components/index";
import { Insights, Dates, Tags, Tabs, Loading } from "../components";
import { HeaderContainer } from "./styles";
import { useViewModel } from "./useSurveyDetailViewModel";

const SurveyDetailView = () => {
  const { id = "", delivery: dly } = useParams();
  const delivery: "single" | "recurring" | "continuous" = dly as any;
  const navigate = useNavigate();
  const today = dayjs()?.add(1, "days");
  const [period, setPeriod] = useState<number>(0);
  const [startDateFilter, setStartDateFilter] = useState<string>("");
  const [resultType, setResultType] = useState<
    "performance" | "questions" | "enps"
  >();

  const {
    selectedSurvey,
    surveyAnalyses,
    generalAnalysis,
    mergedQuestions,
    isLoading,
  } = useViewModel({
    delivery,
    surveyId: id,
    start: startDateFilter,
    end: today?.format("YYYY-MM-DD"),
  });

  const answers: any = surveyAnalyses?.reduce(
    (a, c): any => [...a, ...(c?.answers || [])],
    []
  );

  const employees: any = surveyAnalyses?.reduce(
    (a, c): any => [...a, ...(c?.employees || [])],
    []
  );

  const getChartData = ({
    sectionIndex,
    questionIndex,
  }: {
    sectionIndex: number;
    questionIndex: number;
  }) => {
    if (selectedSurvey?.model_id?.includes("feeling_")) {
      if (selectedSurvey?.sections?.length) {
        if (
          selectedSurvey?.sections[sectionIndex]?.questions[questionIndex]
            ?.options?.length
        ) {
          const settings =
            selectedSurvey?.sections[sectionIndex]?.questions[
              questionIndex
            ]?.options?.map((o, i) => ({
              dataKey: o?.value,
              amt: "0",
              name: o?.value,
              color:
                {
                  0: "#490424",
                  1: "#78003C",
                  2: "#AA0955",
                  3: "#DA0B6D",
                  4: "#F20D7A",
                  5: "#FE67AF",
                  6: "#FF9AC9",
                  7: "#FFCCE4",
                  8: "#FFD7EB",
                  9: "#FFE1F3",
                  10: "#FBE5F6",
                  11: "#F7F0F9",
                }[i] || "#F7F0F9",
            })) || [];

          const data = surveyAnalyses
            ?.filter((s) => s?.answerCount > 0)
            ?.map((s) => {
              const optionKeys = settings?.map((s) => s?.dataKey);

              const rest: { [key: string]: number | string } = {
                name: dayjs(s?.sendAt)?.format("DD/MM/YYYY"),
              };

              let total = 0;

              optionKeys?.forEach((key) => {
                const optionCount =
                  s?.questions[questionIndex]?.options?.find(
                    (o) => o?.value === key
                  )?.count || 0;

                if (optionCount) {
                  total = total + optionCount;
                  rest[key] = optionCount;
                }
              });

              rest["Total"] = total;
              rest["monitoringCount"] = s?.answers?.length || 0;

              return rest;
            });

          return { settings, data };
        }
      }
    }

    return { settings: [], data: [] };
  };

  if (isLoading) return <Loading />;

  return (
    <PageContainer style={{ marginTop: "24px" }}>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" />}
        breadcrumbs={[
          <LinkButton variant="neutral" href="/engagement/survey">
            Gerenciar pesquisas
          </LinkButton>,
          <Link to={`/engagement/v2/survey/${selectedSurvey?.engagement_id}`}>
            Detalhe da pesquisa
          </Link>,
        ]}
      />
      <HeaderContainer>
        <FlexContainer justifyContent="flex-start" gap={8}>
          <Typography
            variant="headline6"
            variantColor="var(--color-neutral-20)"
            weight={700}
          >
            {selectedSurvey?.engagement_name}
          </Typography>
          <Tags
            status={
              selectedSurvey?.recurrence_status || selectedSurvey?.status || ""
            }
            delivery={delivery}
          />
        </FlexContainer>
        {delivery === "continuous" ? (
          <FlexContainer justifyContent="flex-end" gap={24}>
            <SelectField
              style={{ minWidth: "210px" }}
              fullWidth
              disabled={false}
              label="Selecione o período"
              options={[
                {
                  label: "Últimas 24 horas",
                  value: 1,
                },
                {
                  label: "Últimos 7 dias",
                  value: 7,
                },
                {
                  label: "Últimos 15 dias",
                  value: 15,
                },
                {
                  label: "Últimos 30 dias",
                  value: 30,
                },
                {
                  label: "Últimos 3 meses",
                  value: 90,
                },
                {
                  label: "Últimos 6 meses",
                  value: 182,
                },
                {
                  label: "Últimos 12 meses",
                  value: 365,
                },
                {
                  label: "Todas as datas",
                  value: 0,
                },
              ]}
              onSelectChange={(_: any, option) => {
                const value = option?.value;
                if (typeof value === "number") {
                  setStartDateFilter(
                    value
                      ? today?.subtract(value, "day")?.format("YYYY-MM-DD")
                      : ""
                  );
                  setPeriod(value);
                }
              }}
              value={period}
            />
            <div>
              <Button
                variant="primary"
                size="large"
                minWidth="252px"
                onClick={() =>
                  navigate(
                    `/engagement/survey/${selectedSurvey?.model_id}/${selectedSurvey?.engagement_id}/recipients`
                  )
                }
              >
                Adicionar destinatários
              </Button>
            </div>
          </FlexContainer>
        ) : null}
      </HeaderContainer>
      <Insights
        delivery={delivery}
        viewRate={generalAnalysis?.viewRate}
        answerRate={generalAnalysis?.answerRate}
        recurringEmployeeCount={
          selectedSurvey?.number_of_recurrent_employees || 0
        }
        employeeCount={generalAnalysis?.recipientCount}
        questionCount={
          selectedSurvey?.sections?.reduce(
            (a, c) => a + c?.questions?.length || 0,
            0
          ) || 0
        }
        sendCount={surveyAnalyses?.length || 0}
      />
      <Dates
        id={id || ""}
        status={selectedSurvey?.recurrence_status || ""}
        delivery={delivery}
        createdAt={selectedSurvey?.created_at || ""}
        sendDate={selectedSurvey?.send_at || ""}
        interactionDeadline={selectedSurvey?.interaction_deadline || ""}
        lastSendDate={generalAnalysis?.lastShipment?.sendAt}
        endDate={selectedSurvey?.recurrence_end_at || ""}
        occurrenceLimit={
          selectedSurvey?.recurrence_max_occurrences || undefined
        }
      />
      <Tabs
        recurrenceAnalysisTab={
          selectedSurvey?.model_id?.includes("feeling_")
            ? {
                chart1: {
                  info: {
                    title: "Sentimento ao longo dos envios",
                    metric: "Sentimento x Data de envio",
                    explanation: `Sentimento ao longo dos envios Apresenta a média das notas de como as pessoas se sentiram ao longo de cada envio. Caso o envio não apresente respostas, ele não será exibido.`,
                  },
                  settings: {
                    line: "Sentimento Atual",
                    lineColor: "#F20D7A",
                    isPercentage: false,
                    defaultLine: "",
                    defaultLineColor: "",
                  },
                  data: surveyAnalyses
                    ?.filter((s) => s?.answers?.length)
                    ?.map((q) => {
                      const answers = q?.answers;
                      const answersCount = answers?.length || 0;

                      const media =
                        answers?.reduce((a, c) => {
                          const value = c?.sections[0]?.questions[0]?.value;
                          if (typeof value === "string" && value)
                            return a + Number(value);
                          return a;
                        }, 0) || 0;

                      return {
                        name: dayjs(q?.sendAt)?.format("DD/MM/YYYY"),
                        line: media / answersCount || 0,
                        defaultLine: 0,
                      };
                    }),
                },
                chart2: {
                  info: {
                    title:
                      "Qual das opções abaixo melhor descreve seu sentimento?",
                    metric: "Respostas ao longo do tempo",
                    explanation: "",
                  },
                  settings: getChartData({ sectionIndex: 0, questionIndex: 1 })
                    ?.settings,
                  data: getChartData({ sectionIndex: 0, questionIndex: 1 })
                    ?.data,
                },
                chart3: {
                  info: {
                    title: "O que foi bom?",
                    metric: "Respostas ao longo do tempo",
                    explanation: "",
                  },
                  settings: getChartData({ sectionIndex: 0, questionIndex: 2 })
                    ?.settings,
                  data: getChartData({ sectionIndex: 0, questionIndex: 2 })
                    ?.data,
                },
                chart4: {
                  info: {
                    title: "O que poderia melhorar?",
                    metric: "Respostas ao longo do tempo",
                    explanation: "",
                  },
                  settings: getChartData({ sectionIndex: 0, questionIndex: 3 })
                    ?.settings,
                  data: getChartData({ sectionIndex: 0, questionIndex: 3 })
                    ?.data,
                },
              }
            : undefined
        }
        delivery={delivery}
        occurrenceTab={{
          data:
            surveyAnalyses?.map((e) => ({
              id: e?.id,
              date: e?.sendAt,
              employeeCount: e?.employees?.length || 0,
            })) || [],
        }}
        answerTab={{
          questions: mergedQuestions,
          sections: selectedSurvey?.sections || [],
          data: answers?.map((a) => ({
            name: a?.employee_name,
            date: a?.created_at,
            answer: a?.sections?.map((s) => ({
              id: s?.id,
              questions: s?.questions?.map((q) => ({
                label: q?.label,
                title: q?.title,
                id: q?.id,
                value: q?.value || "",
                justification: q?.justification,
              })),
            })),
          })),
        }}
        resultTab={{
          analysisConfig: selectedSurvey?.analysis_config,
          data: mergedQuestions,
          resultType: resultType,
          onChangeResultType: (type) => setResultType(type),
        }}
        recurrenceEmployeeTab={{
          data:
            selectedSurvey?.recurrence_employees_info?.map((e) => ({
              id: e?.employee_nano_id,
              name: e?.name,
            })) || [],
        }}
        employeeTab={{
          data:
            employees?.map((e) => ({
              id: e?.id,
              surveyId: e?.surveyId,
              name: e?.name,
              date: e?.date,
              status: e?.status,
              visualized: e?.visualized,
            })) || [],
        }}
        detailTab={{
          data: {
            anonymous: false,
            modelName: selectedSurvey?.model_name || "",
            startDate: {
              date: selectedSurvey?.start_at || "",
              period: selectedSurvey?.start_period || "morning",
            },
            name: selectedSurvey?.engagement_name || "",
            title: selectedSurvey?.title || "",
            createdAt: new Date(selectedSurvey?.created_at || ""),
            type: selectedSurvey?.type || "research",
            status: selectedSurvey?.status || "draft",
            owners: selectedSurvey?.owners || [],
            createdBy: selectedSurvey?.created_by || "",
            sendDate: selectedSurvey?.send_at
              ? new Date(selectedSurvey?.send_at)
              : undefined,
            description: selectedSurvey?.description || "",
            notifications: {
              email: selectedSurvey?.has_email_notification || false,
              push: selectedSurvey?.has_push_notification || false,
              reminder: {
                enabled: selectedSurvey?.has_notification_reminder || false,
              },
            },
            recurrence:
              typeof selectedSurvey?.recurrence_is_active === "boolean"
                ? {
                    active: selectedSurvey?.recurrence_is_active || false,
                    endDate: selectedSurvey?.recurrence_end_at || "",
                    frequency: selectedSurvey?.recurrence_frequency || "day",
                    occurrenceLimit:
                      selectedSurvey?.recurrence_max_occurrences || 0,
                    repeat: selectedSurvey?.recurrence_repetition || 0,
                    status: selectedSurvey?.recurrence_status || "scheduled",
                  }
                : undefined,
          },
        }}
      />
    </PageContainer>
  );
};

export default SurveyDetailView;
