import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  dayjs,
  PageContainer,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { alert } from "../../utils";
import { NotificationCardV2 } from "@components/NotificationCardV2";
import { Data, TransferListInterface } from "@components/v2/TransferList/types";
import {
  Header,
  EmployeeList,
  SchedulerForm,
  Footer,
  Info,
  SuccessModal,
  PageSkeleton,
} from "./components";
import {
  MainContainer,
  ContentContainer,
  EmployeeListContainer,
  TitleContainer,
  Row,
} from "./styles";

export default () => {
  const navigate = useNavigate();
  const { id = "", model } = useParams<{
    id: string;
    model:
      | "voluntaryExit"
      | "involuntaryExit"
      | "feeling"
      | "engaja"
      | "climate"
      | "enps"
      | "custom";
  }>();

  const [filters, setFilters] = useState<
    TransferListInterface["filter"]["sections"]
  >([
    {
      id: "section-group",
      title: "Grupos",
      options: [],
      search: "",
    },
    {
      id: "section-department",
      title: "Departamento",
      options: [],
      search: "",
    },
    {
      id: "section-role",
      title: "Cargo",
      options: [],
      search: "",
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [employees, setEmployees] = useState<
    { id: string; name: string; email?: string }[]
  >([]);
  const [data, setData] = useState<{
    date?: string;
    period?: "morning" | "afternoon" | "night";
    employees?: Data;
  }>();

  const {
    isFetching: getEmployeesByCompanyIdLoading,
    refetch: getInternalEmployeesByCompanyId,
  } = trpc?.getInternalEmployeesByCompanyId?.useQuery(
    { status: "ACTIVE" },
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (e: any) =>
        alert("Erro ao buscar colaboradores, tente novamente mais tarde."),
    }
  );

  const { data: organizationalData } = trpc?.getOrganizationalData?.useQuery(
    undefined,
    {
      retry: false,
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const organizationalFilters = filters?.map(({ id, ...rest }) => {
          if (id === "section-department")
            return {
              id,
              ...rest,
              options: data?.departments?.map(({ _id, name }) => ({
                value: _id,
                title: name,
                asideText: "",
                checked: false,
                hidden: false,
              })),
            };

          if (id === "section-role")
            return {
              id,
              ...rest,
              options: data?.roles?.map(({ _id, name }) => ({
                value: _id,
                title: name,
                asideText: "",
                checked: false,
                hidden: false,
              })),
            };

          if (id === "section-group")
            return {
              id,
              ...rest,
              options: data?.groups?.map(({ _id, name }) => ({
                value: _id,
                title: name,
                asideText: "",
                checked: false,
                hidden: false,
              })),
            };

          return { id, ...rest };
        });

        setFilters([
          ...organizationalFilters?.filter((s) => s?.options?.length),
        ]);
      },
      onError: () => alert("Erro ao buscar cargos, grupos e departamentos."),
    }
  );

  const { isFetching: getEmployeesWithSurveyLoading } =
    trpc?.getEmployeesWithSurvey?.useQuery(
      { model: model || "custom" },
      {
        retry: false,
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: (e) => {
          getInternalEmployeesByCompanyId().then((result) => {
            const filter =
              result?.data?.employees
                ?.filter((employee) => !e?.includes(employee?._id))
                ?.map((employee) => ({
                  id: employee?._id,
                  name: employee?.name || "",
                  email: employee?.email || "",
                })) || [];

            setEmployees(filter);
          });
        },
        onError: (e: any) => {
          console.log("errr: ", e);
          alert("Erro ao buscar colaboradores, tente novamente mais tarde.");
        },
      }
    );

  const {
    isLoading: upsertSurveyInstanceLoading,
    mutate: upsertSurveyInstance,
  } = trpc?.upsertSurveyInstance?.useMutation({
    onSuccess: () => setIsModalOpen(true),
    onError: () => alert("Erro ao adicionar destinatários", "error"),
  });

  const InfoData = {
    0: {
      title: "Destinatários",
      description:
        "Selecione as pessoas participantes da pesquisa. Só é possível selecionar pessoas que não receberam a pesquisa.",
      card: 'Pesquisa a partir do  modelo "Pesquisa de Desligamento Involuntário".',
    },
    1: {
      title: "Envio",
      description: "Configure como deseja enviar.",
      card: 'Pesquisa a partir do  modelo "Pesquisa de Desligamento Involuntário".',
    },
  };

  if (getEmployeesByCompanyIdLoading || getEmployeesWithSurveyLoading)
    return <PageSkeleton />;

  return (
    <MainContainer>
      <Header activeStep={activeStep} />
      <PageContainer>
        <ContentContainer>
          <TitleContainer>
            <Typography
              variant="headline6"
              variantColor="var(--color-neutral-20)"
            >
              Adicionar destinatários
            </Typography>
          </TitleContainer>
          <Row>
            <Info
              title={InfoData[activeStep]?.title}
              description={InfoData[activeStep]?.description}
            >
              <NotificationCardV2
                type={"info"}
                variant={"contained"}
                iconName={"IconAlertTriangle"}
                title={InfoData[activeStep]?.card}
              />
            </Info>
            {activeStep === 0 ? (
              <EmployeeListContainer>
                <EmployeeList
                  employees={
                    employees
                      ?.filter(({ id: employeeId }) => {
                        let filtered = false;

                        const groupOptions = filters[0]?.options;
                        const groupIds = groupOptions
                          ?.filter(({ checked }) => checked)
                          ?.map(({ value }) => value);

                        const departmentOptions = filters[1]?.options;
                        const departmentIds = departmentOptions
                          ?.filter(({ checked }) => checked)
                          ?.map(({ value }) => value);

                        const roleOptions = filters[2]?.options;
                        const roleIds = roleOptions
                          ?.filter(({ checked }) => checked)
                          ?.map(({ value }) => value);

                        const hasFilter =
                          (departmentIds?.length || 0) +
                          (groupIds?.length || 0) +
                          (roleIds?.length || 0);

                        if (hasFilter === 0) return true;

                        groupIds?.forEach((groupId) => {
                          const group = organizationalData?.groups?.find(
                            ({ _id }) => _id === groupId
                          );
                          if (
                            group?.employees?.some((e) => e?._id === employeeId)
                          )
                            filtered = true;
                        });

                        departmentIds?.forEach((departmentId) => {
                          const department =
                            organizationalData?.departments?.find(
                              ({ _id }) => _id === departmentId
                            );
                          if (department?.employees?.includes(employeeId))
                            filtered = true;
                        });

                        roleIds?.forEach((roleId) => {
                          const role = organizationalData?.roles?.find(
                            ({ _id }) => _id === roleId
                          );
                          if (role?.employees?.includes(employeeId))
                            filtered = true;
                        });

                        return filtered;
                      })
                      ?.map((e) => ({
                        id: e?.id,
                        name: e?.name || "",
                        corporateEmail: e?.email || "",
                      })) || []
                  }
                  selected={data?.employees || []}
                  onChange={(employees) =>
                    setData((e) => ({ ...(e || {}), employees }))
                  }
                  filter={{
                    sections: filters,
                    onChange: (e) =>
                      setFilters([
                        ...filters?.map((s) => (s?.id === e?.id ? e : s)),
                      ]),
                    onReset: (e) => setFilters([...e]),
                  }}
                />
              </EmployeeListContainer>
            ) : (
              <SchedulerForm
                date={data?.date}
                period={data?.period}
                onChange={({ date, period }) =>
                  setData((e) => ({ ...(e || {}), date, period }))
                }
              />
            )}
          </Row>
        </ContentContainer>
      </PageContainer>
      <Footer
        exitWithoutSaveButton={{
          disabled: upsertSurveyInstanceLoading,
          onClick: () => navigate("/engagement/survey"),
        }}
        goBackButton={{
          disabled: upsertSurveyInstanceLoading || activeStep === 0,
          onClick: () => setActiveStep(0),
        }}
        continueButton={{
          disabled:
            activeStep === 0
              ? !!!data?.employees?.length
              : !!!(data?.date && data?.period),
          loading: upsertSurveyInstanceLoading,
          label: activeStep === 1 ? "Adicionar" : "Continuar",
          onClick: () =>
            activeStep === 1
              ? upsertSurveyInstance({
                  surveyId: id,
                  employees: data?.employees?.map((d) => d?.id) || [],
                  startDate: {
                    date: data?.date || "",
                    period: data?.period || "morning",
                  },
                })
              : setActiveStep(1),
        }}
      />
      <SuccessModal
        open={isModalOpen}
        onClose={() => navigate("/engagement/survey")}
        date={data?.date ? dayjs(data?.date)?.format("DD/MM/YYYY") : ""}
      />
    </MainContainer>
  );
};
