import {
  Button,
  LinkButton,
  TextField,
  Modal,
  Typography,
  Icons,
  TextArea as TextAreaDS,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useFormik } from "formik";
import * as yup from "yup";
import { SectionData, SectionModalFormProps } from "./types";
import { FlexContainer } from "@components/FlexContainer";
import { TextAreaContainer } from "@components/ModelForm/steps/StepInfo/styles";

const TextArea: any = TextAreaDS;
const { Root } = Modal;

const SectionModalForm = ({
  open,
  onClose,
  onSubmit,
  initialData,
}: SectionModalFormProps) => {
  const formik = useFormik<SectionData>({
    enableReinitialize: true,
    initialValues: {
      title: initialData?.title || "",
      description: initialData?.description || "",
    },
    validationSchema: yup.object({
      title: yup.string().required(),
      description: yup.string().nullable().optional(),
    }),
    onSubmit: (values) => {
      onSubmit(values);
      formik.resetForm();
    },
  });

  return (
    <Root open={open} onClose={onClose} showClose>
      <FlexContainer
        gap={24}
        flexDirection="column"
        style={{ padding: "0 40px" }}
      >
        <FlexContainer
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <Typography variantColor="#1D161B" weight={600} variant="headline8">
            {initialData ? "Editar seção" : "Adicionar seção"}
          </Typography>
          <Typography variantColor="#6B5B66" weight={400} variant="body4">
            Crie seções para organizar as perguntas da pesquisa. A <br />
            pesquisa será exibida às pessoas dividida em seções.
          </Typography>
        </FlexContainer>
        <FlexContainer width="100%" justifyContent="flex-start">
          <Typography variantColor="#53464F" weight={700} variant="headline9">
            Nome e descrição
          </Typography>
        </FlexContainer>
        <FlexContainer
          width="100%"
          alignItems="flex-start"
          flexDirection="column"
        >
          <TextField
            fullWidth
            label="Nome da seção"
            value={formik?.values?.title || ""}
            onChange={(e) =>
              formik.handleChange({
                target: {
                  id: "title",
                  value: e?.target?.value || "",
                },
              })
            }
            error={
              (formik.touched.title || formik.submitCount > 0) &&
              Boolean(formik.errors.title)
            }
          />
          <div style={{ width: "100%", height: "20px" }} />
          <div style={{ width: "100%" }}>
            <TextAreaContainer>
              <TextArea
                value={formik?.values?.description || ""}
                maxLength={150}
                placeholder="Descrição da seção"
                error={
                  (formik.touched.description || formik.submitCount > 0) &&
                  Boolean(formik.errors.description)
                }
                onChange={(e: any) =>
                  e?.target?.value?.length < 151
                    ? formik.handleChange({
                        target: {
                          id: "description",
                          value: e?.target?.value,
                        },
                      })
                    : null
                }
              />
            </TextAreaContainer>
          </div>
        </FlexContainer>
        <FlexContainer width="100%" justifyContent="space-between">
          <FlexContainer>
            <LinkButton variant="neutral" onClick={onClose}>
              Cancelar
            </LinkButton>
          </FlexContainer>
          <FlexContainer>
            <Button
              disabled={false}
              variant="primary"
              size="large"
              onClick={() => formik?.handleSubmit()}
            >
              Concluir <Icons name="IconCheck" color="#FFFFFF" />
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Root>
  );
};

export { SectionModalForm };
