import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`;

const SectionContainer = styled.div<{ selected?: boolean }>`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid
    ${({ selected }) =>
      selected ? "var(--color-secondary-50)" : "var(--color-neutral-90)"};
  background-color: ${({ selected }) =>
    selected ? "var(--color-secondary-95)" : "var(--color-neutral-100)"};
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const NumberContainer = styled.div<{ selected?: boolean }>`
  height: 22px;
  width: 22px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }) =>
    selected ? "var(--color-secondary-95)" : "var(--color-neutral-90)"};
`;

export { Container, SectionContainer, NumberContainer };
