import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  dayjs,
  Table,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { OccurrenceTabInterface, TableDataType } from "./types";

export default ({ data }: OccurrenceTabInterface) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 5,
  });

  const { pageNumber, pageSize } = pagination;

  const count = data.length;

  const finalData = data?.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize
  );

  const table = tableControllers.useTableColumns<TableDataType>({
    defaultColumn: {
      minSize: 200,
    },
    total: count,
    columns: [
      {
        header: "Data de envio",
        accessorKey: "date",
        sticky: "left",
        minSize: 300,
        cell: (e) => (
          <>
            {e?.row?.original?.date
              ? dayjs(e?.row?.original?.date)?.format("DD/MM/YYYY")
              : ""}
          </>
        ),
      },
      {
        header: "Pessoas",
        accessorKey: "employeeCount",
        minSize: 300,
        cell: (e) => <>{e?.row?.original?.employeeCount || "0"}</>,
      },
      {
        header: "",
        accessorKey: "id",
        sticky: "right",
        size: 200,
        cell: (e) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ minWidth: "173px" }}
                variant="secondary"
                size="medium"
                onClick={() =>
                  navigate(
                    `/engagement/v2/survey/${e?.row?.original?.id}/single`
                  )
                }
              >
                Ver envio
              </Button>
            </div>
          );
        },
      },
    ],
    data: finalData,
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  return (
    <Table.Root>
      <Table.Grid.Root loading={false}>
        <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
        {table.rows.map((row, index) => (
          <Table.Grid.Row key={index + row.id} row={row} />
        ))}
      </Table.Grid.Root>
      <Table.Pagination
        count={count}
        onPaginationChange={({ pageSize, pageNumber }) =>
          setPagination({
            ...pagination,
            pageSize,
            pageNumber: pagination?.pageSize !== pageSize ? 1 : pageNumber,
          })
        }
        pagination={pagination}
        pageSizeOptions={[
          {
            label: "5 itens",
            value: 5,
          },
          {
            label: "10 itens",
            value: 10,
          },
          {
            label: "25 itens",
            value: 25,
          },
        ]}
      />
    </Table.Root>
  );
};
