import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { DraftSection } from "src/Pages/v2/SurveyForm/screen/types";
import { Divider } from "../../../../../../v2";
import { Container, Table, Th, Td, TableContainer } from "./styles";

const TableReview = ({ sections }: { sections?: DraftSection[] }) => {
  const formats = {
    text: "Texto",
    date: "Data",
    "multiple-choice": "Múltipla escolha",
    "select-box": "Caixa de seleção",
    "multi-select": "Seleção Multi-Tags",
    dropdown: "Lista suspensa",
    emoji: "Reação (Emoji)",
    heart: "Reação (Coração)",
    star: "Reação (Estrela)",
    scale: "Escala Numérica",
  };

  return (
    <Container>
      {sections?.map((section, sectionIndex) => (
        <TableContainer key={`section-${sectionIndex}`}>
          <Typography
            variant="headline8"
            variantColor="var(--color-neutral-30)"
            weight={700}
          >
            {section?.title}
          </Typography>
          <Divider spacing="xxxs" />
          <Table>
            <tr>
              <Th style={{ width: "70%" }}>
                <Typography
                  variant="headline9"
                  variantColor="var(--color-neutral-30)"
                  weight={700}
                >
                  Perguntas
                </Typography>
              </Th>
              <Th style={{ width: "20%" }}>
                <Typography
                  variant="headline9"
                  variantColor="var(--color-neutral-30)"
                  weight={700}
                >
                  Formato da resposta
                </Typography>
              </Th>
              <Th>
                <Typography
                  variant="headline9"
                  variantColor="var(--color-neutral-30)"
                  weight={700}
                >
                  Obrigatória
                </Typography>
              </Th>
            </tr>
            {section?.questions?.map((question, questionIndex) => (
              <tr key={`s-${sectionIndex}question-${questionIndex}`}>
                <Td>
                  <Typography
                    variant="body3"
                    variantColor="var(--color-neutral-40)"
                    weight={600}
                  >
                    {question?.title || ""}
                  </Typography>
                </Td>
                <Td>
                  <Typography
                    variant="body3"
                    variantColor="var(--color-neutral-30)"
                    weight={600}
                  >
                    {question?.answerFormat
                      ? question?.isENPS
                        ? "NPS"
                        : formats[question?.answerFormat]
                      : ""}
                  </Typography>
                </Td>
                <Td>
                  <Typography
                    variant="body3"
                    variantColor="var(--color-neutral-30)"
                    weight={600}
                  >
                    {question?.required ? "Sim" : "Não"}
                  </Typography>
                </Td>
              </tr>
            ))}
          </Table>
        </TableContainer>
      ))}
    </Container>
  );
};

export { TableReview };
