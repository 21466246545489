import { NotificationCardV2 } from "@components/NotificationCardV2";
import {
  ChildrenNotificationCard,
  Container,
  Description,
  Title,
} from "./styles";
import { StepDescriptionInterface } from "./types";
import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@components/v2";
import { Typography } from "@components/Typography";

const StepDescription = ({
  model,
  title,
  description,
  currentStep,
  children,
  width,
  notifications,
  containerStyle = {},
  recipients,
  onClick,
}: StepDescriptionInterface) => {
  const wrapText = (text: string) => (
    <>
      {text?.split("<br/>")?.map((t, i) => (
        <Description key={i} variant="body3">
          {t}
        </Description>
      ))}
    </>
  );

  return (
    <Container width={width} style={containerStyle}>
      <Title variant="headline7">{title}</Title>
      <Divider spacing={"xxs2"} />
      {wrapText(description)}
      {model?.name === "engaja" && currentStep === 4 ? (
        <div style={{ width: "100%" }}>
          <Divider spacing={"xxs3"} />
          <NotificationCardV2
            closeButton
            autoClose
            variant="contained"
            type={"primary" as any}
            title={"Dica da Flash!"}
            subtitle="Para resultados mais precisos, garanta que as pessoas estejam em departamentos e/ou grupos."
            iconName={"IconBulb"}
          />
        </div>
      ) : null}
      {model?.name !== "custom" && model ? (
        <div style={{ width: "100%" }}>
          <Divider spacing={"xxs3"} />
          <NotificationCardV2
            variant="outlined"
            type={"info"}
            title={`Pesquisa a partir do modelo "${model.name}"`}
            iconName={"IconAlertTriangle"}
          />
        </div>
      ) : null}
      {model?.anonymous && currentStep === 4 && (
        <div style={{ width: "100%" }}>
          <Divider spacing={"xxs3"} />
          <NotificationCardV2
            variant="outlined"
            type={"info"}
            title="Seleção de pessoas"
            subtitle={
              <>
                Por essa pesquisa ser anônima, para que possamos apresentar os
                resultados da pesquisa garantindo a segurança,
                <strong>
                  {" "}
                  a pesquisa deve ser enviada para pelo menos 3 pessoas.
                </strong>
              </>
            }
            iconName={"IconAlertTriangle"}
          />
        </div>
      )}
      {model?.anonymous ? (
        <div style={{ width: "100%" }}>
          <Divider spacing={"xxs3"} />
          <NotificationCardV2
            variant="contained"
            type={"info"}
            title={`O anonimato está habilitado`}
            subtitle="Para garantir a segurança da informação a pesquisa deve ser enviada para pelo menos 3 pessoas."
            iconName={"IconAlertTriangle"}
          />
        </div>
      ) : null}
      {currentStep === 4 &&
      notifications?.email &&
      recipients?.some((r) => !r?.corporateEmail) ? (
        <div style={{ width: "100%" }}>
          <Divider spacing={"xxs3"} />
          <NotificationCardV2
            children={
              <ChildrenNotificationCard>
                <Typography
                  variant="body3"
                  fontWeight={600}
                  color="var(--color-feedback-info-10)"
                >
                  Destinatários sem e-mails corporativos cadastrado
                </Typography>
                <Typography
                  variant="body4"
                  fontWeight={400}
                  color="var(--color-feedback-info-10)"
                >
                  Alguns destinatários não estão associados a um e-mail
                  corporativo, impedindo o envio da comunicação por e-mail.
                  Clique no link abaixo para atualizar os e-mails destes
                  destinatários.
                </Typography>
                <LinkButton
                  variant={"neutral"}
                  onClick={() => (onClick ? onClick() : null)}
                >
                  Atualizar destinatários
                </LinkButton>
              </ChildrenNotificationCard>
            }
            variant="contained"
            type={"info"}
            title={`Destinatários sem e-mails corporativos cadastrados`}
            subtitle="Alguns destinatários não estão associados a um e-mail corporativo, impedindo o envio da comunicação por e-mail. Clique no link abaixo para atualizar os e-mails destes destinatários."
            iconName={"IconAlertTriangle"}
          />
        </div>
      ) : null}
      {children ? (
        <>
          <Divider spacing={"xxs3"} />
          {children}
        </>
      ) : null}
    </Container>
  );
};

export { StepDescription };
