import styled from "styled-components";

const FlexContainer = styled.div<{
  alignItems?: "flex-start" | "center" | "flex-end" | "space-between";
  justifyContent?: "flex-start" | "center" | "flex-end" | "space-between";
  flexDirection?: "row" | "column";
  width?: string;
  padding?: number;
  gap?: number;
  border?: string;
  radius?: number;
}>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || "center"};
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  gap: ${({ gap }) => (gap ? `${gap}px` : "0px")};
  padding: ${({ padding }) => (padding ? `${padding}px` : "0px")};
  border: ${({ border }) => (border ? border : "none")};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : "none")};
  width: ${({ width }) => width || "auto"};
`;

export { FlexContainer };
