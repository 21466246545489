import { useState } from "react";
import {
  CenterContainer,
  Drawer,
  ENPSCharts,
  HeatMap,
  VerticalBarChart,
} from "@components/index";
import { ReviewTabInterface } from "./types";
import {
  Icons,
  Skeleton,
  Tooltip,
  SelectField,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  ChartContainer,
  ChartSeparator,
  GraphContainer,
  GraphDescription,
  GraphHeader,
  SelectsContainer,
  StrategiesContainer,
  TagsWrapper,
  VerticalBarChartContainer,
  GraphImpactTextsWrapper,
  DrawerHeader,
  IconButtonContainer,
  Header,
  TagsContainer,
} from "./styles";
import { GraphHorizontalBars } from "./GraphHorizontalBars";
import { useTheme } from "styled-components";
import DimensionsDetailsDrawerContent from "../DimensionsDetailsDrawerContent";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { generateSurveyDataByModel } from "@components/EngagementForm/common/components";
import { orderedEngajaQuestions } from "src/utils/ordered-engaja-questions";
import { DrawerContent } from "../DrawerContent";
import { segment, formatNumberToTwoDecimals } from "@utils";

const strategyOptions = [
  {
    label: "Significado do trabalho",
    value: "engaja_job_meaning_actions",
  },
  {
    label: `Boas práticas de gestão`,
    value: "engaja_good_management_practices_actions",
  },
  {
    label: `Ambiente de trabalho positivo`,
    value: "engaja_positive_work_environment_actions",
  },
  {
    label: `Oportunidade de crescimento`,
    value: "engaja_growth_opportunity_actions",
  },
  {
    label: `Confiança na Alta Liderança`,
    value: "engaja_trust_in_top_leadership_actions",
  },
  {
    label: `Remuneração`,
    value: "engaja_compensation_and_benefits_actions",
  },
];

export default ({
  heatMapLoading,
  strategiesLoading,
  dimensions,
  columns,
  selectedSegment,
  selectedReviewType,
  onSetReviewType,
  onSelectSegment,
  enps,
  section,
  setSelectedStrategySection,
  selectedStrategySection,
}: ReviewTabInterface) => {
  const validColumns = columns?.filter(
    (_, i) => dimensions?.filter((d) => d?.values[i] !== undefined)?.length
  );
  const validDimensions = dimensions?.map(({ key, label, values }) => ({
    key,
    label,
    values: values?.filter((v) => v !== undefined),
  }));

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const theme = useTheme() as any;
  const { selectedCompany } = useSelectedCompany();

  const options = [
    {
      label: "Atributos do engajamento",
      value: "engagement-dimensions",
    },
    {
      label: `eNPS`,
      value: "enps",
    },
  ];

  if (
    [
      "FuXPPZ0P6KwR3UHydq7Ru", // prod  Demo AnaB
      "eV6Gye9bqvFhJWZ4khrKE", // prod  [Teste AnaB] Demonstrações
      "Q5ZtohPYg2NY0CqNtuDaF", // staging LMS v2
    ].includes(selectedCompany?.id)
  ) {
    options.splice(1, 0, {
      label: `Ações`,
      value: "strategies",
    });
  }

  const engajaModel = generateSurveyDataByModel("engaja");
  const engajaModelSection = engajaModel.find(
    (section) => section?.referenceId === selectedStrategySection
  );

  return (
    <>
      <Drawer
        content={
          <DrawerContent
            content={[
              {
                title: "Atributos do engajamento",
                description:
                  "Exibe as médias das respostas dos colaboradores relacionadas aos atributos do engajamento, avaliando como eles se sentem em relação a cada dimensão.",
              },
              {
                title: "Ações",
                description:
                  "Apresenta como os colaboradores avaliam o impacto das ações realizadas ou potenciais do RH em seu engajamento. As respostas ajudam a identificar quais práticas têm maior influência no trabalho do seu colaborador.",
              },
              {
                title: "eNPS",
                description:
                  "O eNPS (Employee Net Promoter Score) avalia a lealdade dos colaboradores, medindo o quanto recomendam a empresa como um bom lugar para trabalhar.",
              },
            ]}
          />
        }
        title="Sobre as análises"
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
      />
      <SelectsContainer>
        <CenterContainer
          width="420px"
          style={{ gap: 8, justifyContent: "flex-start" }}
        >
          <div id="survey-detail-select-0" style={{ width: "314px" }}>
            <SelectField
              style={{ maxWidth: "314px" }}
              fullWidth
              disabled={false}
              key="review-tab-select-1"
              label="Selecionar tipo de análise"
              options={options}
              onSelectChange={(_: any, option) => {
                const event = option?.value;
                onSetReviewType(event);

                let track = "";
                if (event === "engagement-dimensions")
                  track =
                    "people_strategic_hr_engagement_company_engajadetails_analysis_attributes_clicked";
                if (event === "enps")
                  track =
                    "people_strategic_hr_engagement_company_engajadetails_analysis_enps_clicked";
                if (event === "strategies")
                  track =
                    "people_strategic_hr_engagement_company_engajadetails_analysis_actions_clicked";
                if (track) segment({ track });
              }}
              value={selectedReviewType}
            />
          </div>
          <IconButtonContainer
            onClick={() => {
              setDrawerIsOpen(true);
              segment({
                track:
                  "people_strategic_hr_engagement_company_engajadetails_analysisdescription_clicked",
              });
            }}
          >
            <Icons
              name="IconInfoCircle"
              color={theme?.colors?.secondary[50]}
              size={15}
            />
            <Typography
              variant="caption"
              style={{ fontWeight: 700 }}
              variantColor={theme?.colors?.secondary[50]}
            >
              Sobre as análises
            </Typography>
          </IconButtonContainer>
        </CenterContainer>
        {selectedReviewType === "engagement-dimensions" ? (
          <SelectField
            style={{ maxWidth: "194px" }}
            fullWidth
            disabled={false}
            key="review-tab-select-2"
            label={"Exibir por"}
            options={[
              {
                label: "Departamentos",
                value: "departments",
              },
              {
                label: `Grupos`,
                value: "groups",
              },
              {
                label: `Cargos`,
                value: "roles",
              },
            ]}
            onSelectChange={(_: any, option) => {
              const event = option?.value;
              onSelectSegment(event);
            }}
            value={selectedSegment}
          />
        ) : null}
        {selectedReviewType === "strategies" && (
          <SelectField
            style={{ maxWidth: "292px " }}
            fullWidth
            disabled={false}
            key="review-tab-select-2"
            label={"Exibir por"}
            options={strategyOptions}
            onSelectChange={(_: any, option) => {
              const event = option?.value;
              setSelectedStrategySection(event);
            }}
            value={selectedStrategySection}
          />
        )}
      </SelectsContainer>
      <ChartContainer
        hideBorder={selectedReviewType === "engagement-dimensions"}
      >
        {enps && selectedReviewType === "enps" ? (
          <>
            <div style={{ height: "44px" }} />
            <Header>
              <TagsContainer>
                <Tag variant="primary">
                  Pontuação da empresa: {formatNumberToTwoDecimals(enps?.score)}
                </Tag>
                {/* <Tag variant="primary">
                  Pontuação do mercado: {enps?.market_score}
                </Tag> */}
              </TagsContainer>
              <Typography
                variant="body4"
                variantColor="var(--color-neutral-40)"
                style={{ fontWeight: 600 }}
              >
                {enps?.detractors + enps?.neutrals + enps?.promoters} respostas
              </Typography>
            </Header>
          </>
        ) : null}
        {selectedReviewType === "engagement-dimensions" ? (
          heatMapLoading ? (
            <Skeleton
              height="400px"
              width="100%"
              style={{ borderRadius: "12px" }}
            />
          ) : (
            <HeatMap
              tableId="engaja-heat-map"
              columns={validColumns}
              rows={validDimensions}
              segment={selectedSegment}
              drawerProps={
                [
                  "FuXPPZ0P6KwR3UHydq7Ru", // prod  Demo AnaB
                  "eV6Gye9bqvFhJWZ4khrKE", // prod  [Teste AnaB] Demonstrações
                  "Q5ZtohPYg2NY0CqNtuDaF", // staging LMS v2
                ].includes(selectedCompany?.id)
                  ? {
                      columnsIndexes:
                        validColumns?.findIndex((c) => c === "Empresa") !== -1
                          ? [validColumns?.findIndex((c) => c === "Empresa")]
                          : [],
                      content: (data) => {
                        return {
                          title: (
                            <DrawerHeader>
                              <Typography variant="headline9">
                                Dimensão
                              </Typography>
                              <Typography variant="headline7">
                                {data?.label}
                              </Typography>
                            </DrawerHeader>
                          ),
                          drawerContent: (
                            <DimensionsDetailsDrawerContent
                              sectionReferenceId={
                                data?.index !== undefined
                                  ? validDimensions[data?.index]?.key
                                  : ""
                              }
                            />
                          ),
                        };
                      },
                    }
                  : undefined
              }
            />
          )
        ) : null}
        {enps && selectedReviewType === "enps" ? (
          <>
            <ENPSCharts {...enps} />
            <ChartSeparator />
            <VerticalBarChartContainer>
              <VerticalBarChart
                lowerLabel={"Pouco provável"}
                upperLabel={"Muito provável"}
                enps={true}
                title="Gráfico respostas x notas"
                data={enps?.statistics || []}
              />
            </VerticalBarChartContainer>
          </>
        ) : null}
        {selectedReviewType === "strategies" && (
          <StrategiesContainer>
            <Typography variant="headline8">
              {
                strategyOptions?.find(
                  (item) => item.value === selectedStrategySection
                )?.label
              }
            </Typography>
            {strategiesLoading ? (
              <Skeleton
                height="400px"
                width="100%"
                style={{ borderRadius: "12px" }}
              />
            ) : (
              <>
                {orderedEngajaQuestions(section, engajaModelSection)?.map(
                  (question) => {
                    const answers_count =
                      question?.question_answer_1_count +
                      question?.question_answer_2_count +
                      question?.question_answer_3_count +
                      question?.question_answer_4_count +
                      question?.question_answer_5_count;

                    const currentSection = engajaModel.find(
                      (section) =>
                        section.referenceId === selectedStrategySection
                    );

                    const questionReferenceId = question?.question_reference_id;

                    const currentQuestion = currentSection?.questions?.find(
                      (modelQuestion) =>
                        modelQuestion.referenceId === questionReferenceId
                    );

                    return (
                      <GraphContainer>
                        <GraphHeader>
                          <TagsWrapper>
                            <Tag variant="primary" as="span">
                              Média da empresa:{" "}
                              {formatNumberToTwoDecimals(
                                question?.question_average
                              )}
                            </Tag>
                            {/* <Tag variant="primary">
                              Média do mercado:{" "}
                              {question?.market_question_average}
                            </Tag> */}
                          </TagsWrapper>
                          <Typography
                            variant="caption"
                            variantColor={theme?.colors.neutral[40]}
                          >
                            {answers_count} respostas
                          </Typography>
                        </GraphHeader>
                        <GraphDescription>
                          <Typography
                            variant="body4"
                            variantColor={theme?.colors.neutral[30]}
                          >
                            {currentQuestion?.title}
                          </Typography>
                        </GraphDescription>
                        <GraphHorizontalBars
                          data={[
                            {
                              name: "1",
                              amt: question?.question_answer_1_count,
                            },
                            {
                              name: "2",
                              amt: question?.question_answer_2_count,
                            },
                            {
                              name: "3",
                              amt: question?.question_answer_3_count,
                            },
                            {
                              name: "4",
                              amt: question?.question_answer_4_count,
                            },
                            {
                              name: "5",
                              amt: question?.question_answer_5_count,
                            },
                          ]}
                          settings={{
                            name: "quantidade",
                            color: "#FE2B8F",
                            showAll: false,
                          }}
                        />
                        <GraphImpactTextsWrapper>
                          <Typography
                            variant="body4"
                            variantColor={theme?.colors.neutral[30]}
                          >
                            Nenhum impacto
                          </Typography>
                          <Typography
                            variant="body4"
                            variantColor={theme?.colors.neutral[30]}
                          >
                            Impacto muito alto
                          </Typography>
                        </GraphImpactTextsWrapper>
                      </GraphContainer>
                    );
                  }
                )}
              </>
            )}
          </StrategiesContainer>
        )}
      </ChartContainer>
    </>
  );
};
