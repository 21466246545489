import { useState } from "react";
import {
  dayjs,
  Table,
  tableControllers,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { Avatar } from "../../../../../../../components/index";
import { AnswerTabInterface, TableDataType, AnswerFormatType } from "./types";
import { Cell, TagContainer, TagsContainer } from "./styles";

function normalizeDate(date: string) {
  date = date.trim();
  const inputFormat = date.includes("/") ? "DD/MM/YYYY" : "YYYY-MM-DD";
  return dayjs(date, inputFormat).format("DD/MM/YYYY");
}

export default ({ data, sections }: AnswerTabInterface) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 5,
  });

  const { pageNumber, pageSize } = pagination;

  const count = data.length;

  const finalData = data.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize
  );

  const getTableColumns = (): any[] => {
    const allQuestions: {
      id: string;
      title: string;
      sectionId: string;
      sectionIndex: number;
      questionIndex: number;
      options?: {
        id?: string;
        value?: string;
      }[];
      answerFormat: AnswerFormatType;
    }[] = [];
    sections?.forEach((s, i) => {
      s?.questions?.forEach((q, qi) =>
        allQuestions?.push({
          id: q?.id || "",
          title: q?.title || "",
          sectionId: s?.id || "",
          questionIndex: qi,
          sectionIndex: i,
          answerFormat: q?.answerFormat,
          options: q?.options,
        })
      );
    });

    const columns =
      allQuestions?.map((question, i) => ({
        header: question?.title || "",
        accessorKey: `question-${question?.id}-${i}`,
        minSize: question?.title?.length * 10,
        cell: (e) => {
          const answerFormat: AnswerFormatType = question?.answerFormat;

          const section = e?.row?.original?.answer?.find((s) =>
            s?.questions?.some((q) => q?.title === question?.title)
          );

          const answer = section?.questions?.find(
            (q) => q?.title === question?.title
          );

          const label = answer?.label || "";
          const value = label || answer?.value || "";
          const justification = answer?.justification || "";

          if (answerFormat === "date")
            return <Cell>{normalizeDate(value)}</Cell>;

          if (
            answerFormat === "multiple-choice" ||
            answerFormat === "dropdown"
          ) {
            if (justification) return <Cell>{justification}</Cell>;
            return <Cell>{label}</Cell>;
          }

          if (
            ["select-box", "multi-select"]?.includes(answerFormat || "") &&
            Array.isArray(value)
          )
            return (
              <TagsContainer>
                {value?.map((v, i) => {
                  const optionValue = question?.options?.find(
                    (o) => o?.id === v
                  )?.value;

                  if (optionValue)
                    return (
                      <TagContainer key={`tag-container-${i}`}>
                        <Tag variant="gray">{optionValue}</Tag>
                      </TagContainer>
                    );
                  return <Cell></Cell>;
                })}
                {justification ? (
                  <TagContainer key={`tag-container-${i}`}>
                    <Tag variant="gray">{justification}</Tag>
                  </TagContainer>
                ) : null}
              </TagsContainer>
            );

          return <Cell>{value}</Cell>;
        },
      })) || [];

    return columns;
  };

  const table = tableControllers.useTableColumns<TableDataType>({
    defaultColumn: {
      minSize: 200,
    },
    total: count,
    columns: [
      {
        header: "Pessoas",
        accessorKey: "name",
        sticky: "left",
        minSize: 300,
        cell: (e) => (
          <Avatar
            name={e?.row?.original?.name || "Anônimo"}
            email={""}
            image={""}
          />
        ),
      },
      {
        header: "Data da resposta",
        accessorKey: "date",
        minSize: 200,
        cell: (e) => (
          <div>
            {e?.row?.original?.date
              ? dayjs(e?.row?.original?.date)?.format("DD/MM/YYYY")
              : ""}
          </div>
        ),
      },
      ...(getTableColumns() || []),
    ],
    data: finalData,
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  return (
    <Table.Root>
      <Table.Grid.Root loading={false}>
        <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
        {table.rows.map((row, index) => (
          <Table.Grid.Row key={index + row.id} row={row} />
        ))}
      </Table.Grid.Root>
      <Table.Pagination
        count={count}
        onPaginationChange={({ pageSize, pageNumber }) =>
          setPagination({
            ...pagination,
            pageSize,
            pageNumber: pagination?.pageSize !== pageSize ? 1 : pageNumber,
          })
        }
        pagination={pagination}
        pageSizeOptions={[
          {
            label: "5 itens",
            value: 5,
          },
          {
            label: "10 itens",
            value: 10,
          },
          {
            label: "25 itens",
            value: 25,
          },
        ]}
      />
    </Table.Root>
  );
};
