import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";
import { SurveyModel } from "../../screen/types";
import { Container, ButtonsContainer } from "./styles";
import { Divider } from "@components/v2";

const Footer = ({
  loading,
  step,
  model,
  showNextButton,
  onDraftButtonClick,
  onPreviousButtonClick,
  onNextButtonClick,
  onSubmitButtonClick,
}: {
  loading?: boolean;
  step: number;
  model?: SurveyModel;
  onDraftButtonClick: () => void;
  onPreviousButtonClick: () => void;
  onNextButtonClick: () => void;
  onSubmitButtonClick: () => void;
  showNextButton?: boolean;
}) => {
  const navigate = useNavigate();
  const isContinuous = model?.delivery?.includes("continuous");
  const isSubmitType =
    (isContinuous && step > 4) || (!isContinuous && step > 5) ? true : false;
  const buttonLabel = isSubmitType ? "Enviar" : "Continuar";

  if (step === 0) {
    return (
      <Container>
        <ButtonsContainer>
          <LinkButton
            variant="neutral"
            onClick={() => navigate("/engagement/survey")}
          >
            Sair
          </LinkButton>
        </ButtonsContainer>
        {showNextButton && (
          <ButtonsContainer>
            <Button
              loading={loading}
              variant="primary"
              size="large"
              style={{ width: "200px" }}
              onClick={onNextButtonClick}
            >
              {buttonLabel}
              <Icons name="IconArrowRight" />
            </Button>
          </ButtonsContainer>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <ButtonsContainer>
        <LinkButton
          variant="neutral"
          onClick={() => navigate("/engagement/survey")}
        >
          Sair sem salvar
        </LinkButton>
        <Divider spacing="s3" type="horizontal" />
        <LinkButton
          disabled={loading}
          variant="neutral"
          onClick={() => onDraftButtonClick()}
        >
          Sair e salvar rascunho
        </LinkButton>
      </ButtonsContainer>
      <ButtonsContainer>
        <Button
          variant="secondary"
          size="large"
          disabled={step === 0}
          style={{ width: "200px" }}
          onClick={onPreviousButtonClick}
        >
          <Icons name="IconArrowLeft" />
          Voltar
        </Button>
        <Divider spacing="xs2" type="horizontal" />
        <Button
          loading={loading}
          variant="primary"
          size="large"
          style={{ width: "200px" }}
          onClick={() =>
            buttonLabel === "Enviar"
              ? onSubmitButtonClick()
              : onNextButtonClick()
          }
        >
          {buttonLabel}
          <Icons name="IconArrowRight" />
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export { Footer };
