import {
  Icons,
  SelectField,
  TextField,
  Toggle,
  Tooltip,
  MultiSelect,
} from "@flash-tecnologia/hros-web-ui-v2";
import { LineSeparator, Typography } from "@components/index";
import { Divider } from "@components/v2";
import {
  Container,
  ContentContainer,
  FieldContainer,
  FieldRow,
  IconContainer,
  IconDivider,
  LeftContainer,
} from "./styles";
import { QuestionInterface } from "./types";
import {
  Options,
  NumericScale,
  React,
  TextPreview,
  DatePreview,
  OptionsPreview,
  ScalePreview,
} from "./components";
import { useState } from "react";
import { generateId } from "@components/EngagementForm/common/components/generateId";
import { segment } from "@utils";

const Question = ({
  id,
  title,
  options,
  answerFormat,
  lowerLabel,
  upperLabel,
  scaleRange,
  justification,
  required,
  index,
  isDragging,
  isDragDisabled,
  disabledFields,
  model,
  active,
  onClick,
  onChange,
  onCloneQuestionClick,
}: QuestionInterface) => {
  const feelingTags = {
    0: [
      "Problemas resolvidos",
      "Expectativas alinhadas",
      "Bom relacionamento com a equipe",
      "Apoio do líder",
      "Satisfação na vida pessoal",
      "Metas alcançadas",
      "Trabalho reconhecido",
      "Oportunidade de desenvolvimento",
      "Equilíbrio entre trabalho e vida pessoal",
    ],
    1: [
      "Produtividade",
      "Relacionamento com a equipe",
      "Apoio do líder",
      "Problemas pessoais",
      "Sobrecarga de tarefas",
      "Dificuldade com prazos",
      "Problemas de comunicação",
      "Falta de autonomia",
      "Falta de reconhecimento",
      "Desalinhamento de prioridades",
      "Feedback do meu trabalho",
      "Falta de planejamento e/ou organização",
    ],
  };

  const [open, setOpen] = useState<boolean>(false);

  const renderPreview = () => {
    if (answerFormat) {
      if (answerFormat === "text") return <TextPreview />;
      if (answerFormat === "date") return <DatePreview />;
      if (["scale", "emoji", "star", "heart"]?.includes(answerFormat))
        return (
          <ScalePreview
            answerFormat={answerFormat}
            scaleRange={scaleRange}
            upperLabel={upperLabel}
            lowerLabel={lowerLabel}
          />
        );
      if (
        ["multiple-choice", "multi-select", "select-box", "dropdown"]?.includes(
          answerFormat
        )
      )
        return (
          <OptionsPreview
            options={options}
            answerFormat={answerFormat}
            justification={justification}
          />
        );
    }

    return null;
  };

  return (
    <Container
      isDragging={isDragging}
      active={active}
      style={{ cursor: !active && isDragDisabled ? "pointer" : "" }}
      onClick={onClick}
    >
      {isDragDisabled ? null : (
        <LeftContainer isDragging={isDragging} active={active}>
          <Icons
            name="IconGripVertical"
            color={
              isDragging
                ? "var(--color-neutral-100)"
                : active
                ? "var(--color-primary)"
                : "var(--color-neutral-30)"
            }
          />
        </LeftContainer>
      )}
      <ContentContainer
        isDragging={isDragging}
        active={active}
        isDragDisabled={isDragDisabled}
      >
        <Typography
          variant="body4"
          color="var(--color-neutral-40)"
          fontWeight={600}
        >
          Pergunta {index}
        </Typography>
        <Divider spacing="quark" />
        {title && !active ? (
          <Typography
            variant="headline8"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            {title}
          </Typography>
        ) : null}
        {active ? (
          <FieldContainer>
            <FieldRow>
              <TextField
                value={title}
                disabled={disabledFields}
                label="Digite sua pergunta"
                className="full-width-field"
                onChange={(e) =>
                  onChange({
                    id,
                    options,
                    answerFormat,
                    lowerLabel,
                    justification,
                    upperLabel,
                    scaleRange,
                    required,
                    // otherOption,
                    title: e?.target?.value,
                  })
                }
              />
              <Divider spacing="xs1" type="horizontal" />
              <SelectField
                fullWidth
                className="answer-format-field"
                disabled={
                  ["enps", "climate", "feeling"]?.includes(model || "") ||
                  disabledFields
                }
                label={"Formato da resposta"}
                options={[
                  {
                    label: "Texto",
                    value: "text",
                  },
                  {
                    label: "Data",
                    value: "date",
                  },
                  {
                    label: "Múltipla escolha",
                    value: "multiple-choice",
                  },
                  {
                    label: "Caixa de seleção",
                    value: "select-box",
                  },
                  {
                    label: "Seleção Multi-Tags",
                    value: "multi-select",
                  },
                  {
                    label: "Lista suspensa",
                    value: "dropdown",
                  },
                  {
                    label: "Escala numérica",
                    value: "scale",
                  },
                  {
                    label: "Reação",
                    value: "react",
                  },
                ]}
                onSelectChange={(_: any, option) => {
                  const value = option?.value;

                  const segmentFormats = {
                    text: "text",
                    date: "date",
                    "multiple-choice": "multiplechoice",
                    "select-box": "checkbox",
                    "multi-select": "multiselect",
                    react: "reaction",
                    scale: "scale",
                    dropdown: "dropdownlist",
                  };

                  segment({
                    track: `company_engagement_${
                      model === "enps" ? "enps" : "survey"
                    }content_answerformat_${segmentFormats[value]}_selected`,
                  });

                  const questionOptions =
                    value === "multi-select"
                      ? []
                      : options?.length
                      ? options
                      : [
                          {
                            id: generateId("option"),
                            value: "",
                          },
                          {
                            id: generateId("option"),
                            value: "",
                          },
                        ];

                  if (value !== "react") {
                    onChange({
                      id,
                      options: questionOptions,
                      lowerLabel,
                      upperLabel,
                      justification:
                        value === "dropdown" ? false : justification,
                      scaleRange: undefined,
                      required,
                      title,
                      answerFormat: value,
                    });
                    setOpen(false);
                  } else {
                    onChange({
                      id,
                      options: questionOptions,
                      lowerLabel,
                      upperLabel,
                      justification,
                      scaleRange,
                      required,
                      title,
                      answerFormat: undefined,
                    });
                    setOpen(true);
                  }
                }}
                value={
                  open ||
                  ["emoji", "heart", "star"]?.includes(answerFormat || "")
                    ? "react"
                    : answerFormat
                }
              />
            </FieldRow>
            {open ||
            ["emoji", "heart", "star"]?.includes(answerFormat || "") ? (
              <FieldContainer>
                <Divider spacing="xs1" />
                <FieldRow>
                  <SelectField
                    fullWidth
                    className="full-width-field"
                    disabled={
                      ["enps", "climate", "feeling"]?.includes(model || "") ||
                      disabledFields
                    }
                    label={"Formato da escala"}
                    options={[
                      {
                        label: "Escala emoji",
                        value: "emoji",
                      },
                      {
                        label: "Escala coração",
                        value: "heart",
                      },
                      {
                        label: "Escala estrela",
                        value: "star",
                      },
                    ]}
                    onSelectChange={(_: any, option) => {
                      const value = option?.value;

                      onChange({
                        id,
                        options,
                        lowerLabel,
                        upperLabel,
                        justification,
                        scaleRange: {
                          min: 1,
                          max: 5,
                        },
                        required,
                        title,
                        answerFormat: value,
                      });
                    }}
                    value={answerFormat}
                  />
                </FieldRow>
              </FieldContainer>
            ) : null}

            {answerFormat === "text" || answerFormat === "date" ? (
              <>
                <Divider spacing="xs1" />
                {renderPreview()}
              </>
            ) : null}
            {answerFormat &&
            ["multiple-choice", "select-box", "dropdown"]?.includes(
              answerFormat
            ) ? (
              <>
                <Divider spacing="xs1" />
                <Options
                  disabled={disabledFields}
                  onChange={(e) =>
                    onChange({
                      id,
                      lowerLabel,
                      upperLabel,
                      scaleRange,
                      required,
                      title,
                      answerFormat,
                      justification: e?.justification,
                      options: e?.options,
                    })
                  }
                  answerFormat={answerFormat}
                  justification={justification}
                  options={options}
                />
              </>
            ) : answerFormat === "multi-select" && model === "feeling" ? (
              <>
                <Divider spacing="xs1" />
                <MultiSelect
                  renderInput={() => null}
                  value={
                    options?.map(({ value, id }) => ({
                      label: value,
                      value: id,
                    })) || []
                  }
                  onSelectChange={(_, values: any) => {
                    onChange({
                      id,
                      lowerLabel,
                      upperLabel,
                      scaleRange,
                      required,
                      title,
                      answerFormat,
                      justification: justification,
                      options:
                        values?.map((v) => ({
                          id: v?.value,
                          value: v?.label,
                        })) || [],
                    });
                  }}
                  loading={false}
                  label="Selecione as opções"
                  noOptionsText="Nenhum valor encontrado."
                  fullWidth
                  options={feelingTags[index === 3 ? 0 : 1]
                    ?.map((label) => ({
                      label,
                      value:
                        options?.find((o) => o?.value === label)?.id ||
                        generateId("option"),
                    }))
                    ?.filter(
                      (o) => !options?.some((op) => op?.value === o?.label)
                    )}
                  error={false}
                />
              </>
            ) : answerFormat === "scale" ? (
              <>
                <Divider spacing="xs1" />
                <NumericScale
                  disabled={disabledFields}
                  model={model}
                  upperLabel={upperLabel}
                  lowerLabel={lowerLabel}
                  scaleRange={scaleRange}
                  onChange={(e) =>
                    onChange({
                      id,
                      required,
                      title,
                      answerFormat,
                      justification,
                      options,
                      // otherOption,
                      lowerLabel: e?.lowerLabel,
                      upperLabel: e?.upperLabel,
                      scaleRange: e?.scaleRange,
                    })
                  }
                />
              </>
            ) : answerFormat &&
              ["star", "heart", "emoji"]?.includes(answerFormat) ? (
              <>
                <Divider spacing="xs1" />
                <React
                  disabled={disabledFields}
                  answerFormat={answerFormat}
                  lowerLabel={lowerLabel}
                  upperLabel={upperLabel}
                  onChange={(e) =>
                    onChange({
                      id,
                      required,
                      title,
                      options,
                      scaleRange,
                      justification,
                      // otherOption,
                      answerFormat: e?.answerFormat,
                      lowerLabel: e?.lowerLabel,
                      upperLabel: e?.upperLabel,
                    })
                  }
                />
              </>
            ) : null}
            {["scale", "emoji", "heart", "star"]?.includes(
              answerFormat || ""
            ) ? (
              typeof scaleRange?.min === "number" &&
              typeof scaleRange?.max === "number" ? (
                <>
                  <Divider spacing="xs1" />
                  <LineSeparator />
                  <Divider spacing="xs1" />
                  <Typography
                    variant="headline9"
                    color="var(--color-neutral-30)"
                    fontWeight={700}
                  >
                    Pré-visualização
                  </Typography>
                  <Divider spacing="xs1" />
                  {renderPreview()}
                </>
              ) : null
            ) : null}
            <Divider spacing="xs1" />
            <LineSeparator />
            <Divider spacing="xs1" />
            <FieldRow>
              <Tooltip placement="top-end" title={"Duplicar"}>
                <IconContainer
                  onClick={() =>
                    model === "enps" ||
                    model === "climate" ||
                    model === "feeling" ||
                    disabledFields
                      ? null
                      : onCloneQuestionClick({
                          id: generateId("question"),
                          required,
                          title,
                          options:
                            options?.map((o) => ({
                              ...o,
                              id: generateId("option"),
                            })) || [],
                          scaleRange,
                          justification,
                          // otherOption,
                          answerFormat,
                          lowerLabel,
                          upperLabel,
                        })
                  }
                >
                  <Icons name="IconCopy" color={"var(--color-neutral-40)"} />
                </IconContainer>
              </Tooltip>
              <Divider spacing="xxxs" type="horizontal" />
              <Tooltip placement="top-end" title={"Excluir"}>
                <IconContainer
                  onClick={() =>
                    (model === "enps" && answerFormat === "scale") ||
                    (model === "climate" && answerFormat === "scale") ||
                    (model === "feeling" && answerFormat !== "text") ||
                    disabledFields
                      ? null
                      : onChange({})
                  }
                >
                  <Icons name="IconTrash" color={"var(--color-neutral-40)"} />
                </IconContainer>
              </Tooltip>
              <Divider spacing="xxs2" type="horizontal" />
              <IconDivider />
              <Divider spacing="xxs2" type="horizontal" />
              <Toggle
                disabled={
                  (model === "enps" && answerFormat === "scale") ||
                  (model === "climate" && answerFormat === "scale") ||
                  (model === "feeling" &&
                    (answerFormat === "emoji" ||
                      answerFormat === "multiple-choice")) ||
                  disabledFields
                }
                checked={required}
                onChange={() =>
                  onChange({
                    id,
                    required: !required,
                    title,
                    options,
                    scaleRange,
                    justification,
                    answerFormat,
                    lowerLabel,
                    upperLabel,
                    // otherOption,
                  })
                }
              />
              <Divider spacing="xxxs" type="horizontal" />
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
              >
                Pergunta obrigatória
              </Typography>
            </FieldRow>
          </FieldContainer>
        ) : answerFormat &&
          answerFormat !== "text" &&
          answerFormat !== "date" ? (
          <>
            <Divider spacing="xs1" />
            {renderPreview()}
          </>
        ) : null}
      </ContentContainer>
    </Container>
  );
};

export { Question };
