import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { AnswerTab, DetailTab, EmployeesTab, ResultsTab } from "./components";
import { TabsProps } from "./types";

const Tabs = ({ answerTab, resultTab, employeeTab, detailTab }: TabsProps) => (
  <Tab
    tabItens={[
      {
        label: `Resultados`,
        component: <ResultsTab {...resultTab} />,
      },
      {
        label: `Respostas (${answerTab?.data?.length || 0})`,
        component: <AnswerTab {...answerTab} />,
      },
      {
        label: `Destinatários (${employeeTab?.data?.length || 0})`,
        component: <EmployeesTab {...employeeTab} />,
      },
      {
        label: `Detalhes`,
        component: <DetailTab {...detailTab} />,
      },
    ]}
  />
);

export default Tabs;
