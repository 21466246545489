import { dayjs, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, DateContainer } from "../styles";

export default ({
  createdAt,
  sendDate,
  interactionDeadline,
}: {
  createdAt: string;
  sendDate?: string;
  interactionDeadline?: string;
}) => (
  <Container>
    <DateContainer>
      <Icons name="IconCalendar" color="var(--color-secondary-50)" />
      <Typography
        variant="body4"
        weight={600}
        variantColor="var(--color-neutral-50)"
      >
        Criado em
      </Typography>
      <Typography
        variant="headline9"
        weight={700}
        variantColor="var(--color-neutral-30)"
      >
        {createdAt ? dayjs(createdAt)?.format("DD/MM/YYYY") : ""}
      </Typography>
    </DateContainer>
    {sendDate ? (
      <DateContainer>
        <Icons name="IconCalendar" color="var(--color-secondary-50)" />
        <Typography
          variant="body4"
          weight={600}
          variantColor="var(--color-neutral-50)"
        >
          Enviado em
        </Typography>
        <Typography
          variant="headline9"
          weight={700}
          variantColor="var(--color-neutral-30)"
        >
          {dayjs(sendDate)?.format("DD/MM/YYYY")}
        </Typography>
      </DateContainer>
    ) : null}
    {interactionDeadline ? (
      <DateContainer>
        <Icons name="IconCalendar" color="var(--color-secondary-50)" />
        <Typography
          variant="body4"
          weight={600}
          variantColor="var(--color-neutral-50)"
        >
          Prazo de resposta
        </Typography>
        <Typography
          variant="headline9"
          weight={700}
          variantColor="var(--color-neutral-30)"
        >
          {dayjs(interactionDeadline)?.format("DD/MM/YYYY")}
        </Typography>
      </DateContainer>
    ) : null}
    <DateContainer>
      <Icons name="IconRefresh" color="var(--color-secondary-50)" />
      <Typography
        variant="body4"
        weight={600}
        variantColor="var(--color-neutral-50)"
      >
        Dados atualizados a cada hora
      </Typography>
    </DateContainer>
  </Container>
);
