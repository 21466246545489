import { Skeleton as SkeletonWebUi } from "@flash-tecnologia/hros-web-ui-v2";
import {
  SkeletonContainer,
  SkeletonHeader,
  SkeletonCardsContainer,
  SkeletonCard,
} from "./styles";

const ModelSkeleton = () => (
  <SkeletonContainer>
    <SkeletonHeader>
      <SkeletonWebUi width="300px" height="32px" />

      <SkeletonWebUi width="100%" height="20px" />
      <SkeletonWebUi width="80%" height="20px" />
    </SkeletonHeader>

    <SkeletonCardsContainer>
      <SkeletonCard>
        <SkeletonWebUi height="100%" />
      </SkeletonCard>

      {[...Array(5)].map((_, index) => (
        <SkeletonCard key={index}>
          <SkeletonWebUi height="100%" />
        </SkeletonCard>
      ))}
    </SkeletonCardsContainer>
  </SkeletonContainer>
);

export { ModelSkeleton };
