import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { segment } from "@utils";
import { useNavigate } from "react-router-dom";
import { Divider } from "../../../../v2";
import { ButtonsContainer, Container } from "./styles";
import { FooterInterface } from "./types";

const Footer = ({
  type,
  currentStep,
  surveyData,
  communicationData,
  onDraftButtonClick,
  onNextButtonClick,
  onPreviousButtonClick,
  loading,
  companyEmployeesLoading,
}: FooterInterface) => {
  const navigate = useNavigate();

  let disabled = false;
  const data = type === "communication" ? communicationData : surveyData;

  const isExitSurveyModel =
    surveyData?.model === "voluntaryExit" ||
    surveyData?.model === "involuntaryExit";

  const validateConfigStep = () => {
    if (!data?.owners?.map((o) => o.id).includes("*") && !data?.owners?.length)
      return true;
    if (!data?.notifications?.email && !data?.notifications?.push) return true;
    return false;
  };

  const validateEmployeeStep = () => {
    if (isExitSurveyModel) return false;
    if (!data?.employees?.length) return true;
    return false;
  };

  const isDraftButtonDisabled = () => {
    if (
      surveyData?.name &&
      surveyData?.type &&
      surveyData?.title &&
      surveyData?.description &&
      !loading
    ) {
      if (surveyData?.model === "engaja") {
        if (
          surveyData?.companyData?.economicSector &&
          surveyData?.companyData?.employees &&
          surveyData?.companyData?.size &&
          surveyData?.termsAndConditions?.type === "engaja"
        )
          return false;
        return true;
      }
      return false;
    }

    return true;
  };

  const validateDispatchStep = () => {
    if (
      surveyData?.model === "voluntaryExit" ||
      surveyData?.model === "involuntaryExit"
    )
      return false;

    if (!data?.startDate?.date || !data?.startDate?.period) return true;
    if (data?.recurrenceMode) {
      if (!data?.recurrence?.frequency) return true;
      if (!data?.recurrence?.repeat) return true;
      if (data?.recurrence?.frequency === "week") {
        if (!data?.recurrence?.daysOfTheWeek?.length) return true;
      }
      if (!data?.recurrence?.endDate) {
        if (
          data?.recurrence?.occurrenceLimit !== -1 &&
          !data?.recurrence?.occurrenceLimit
        )
          return true;
      }

      if (type === "survey") {
        if (data?.recurrence?.interactionDeadlineConfig) {
          if (!data?.recurrence?.interactionDeadlineConfig?.nextMode) {
            if (
              !data?.recurrence?.interactionDeadlineConfig?.calculatedDate
                ?.multiplier ||
              !data?.recurrence?.interactionDeadlineConfig?.calculatedDate
                ?.period
            )
              return true;
          }
        } else {
          return true;
        }
      }
    }

    return false;
  };

  if (type === "survey") {
    if (!surveyData?.type) disabled = true;

    if (currentStep === 1) {
      if (surveyData?.model === "engaja" && !surveyData?.termsAndConditions)
        disabled = true;
      if (!surveyData?.name) disabled = true;
      if (!surveyData?.title) disabled = true;
      if (!surveyData?.description) disabled = true;
    } else if (currentStep === 2) {
      disabled = validateDispatchStep();
    } else if (currentStep === 3) {
      disabled = validateConfigStep();
    } else if (currentStep === 4) {
      if (surveyData?.sections?.length) {
        surveyData?.sections?.forEach((section) => {
          if (section?.title) {
            if (section?.questions?.length) {
              section?.questions?.forEach((question) => {
                if (question?.title && question?.answerFormat) {
                  if (
                    [
                      "multiple-choice",
                      "select-box",
                      "dropdown",
                      "multi-select",
                    ]?.includes(question?.answerFormat)
                  ) {
                    if (question?.options?.length) {
                      question?.options?.forEach((option) => {
                        if (!option?.id || !option?.value) disabled = true;
                      });
                    } else {
                      disabled = true;
                    }
                  } else if (
                    ["emoji", "heart", "star", "scale"]?.includes(
                      question?.answerFormat
                    )
                  ) {
                    if (!question?.lowerLabel || !question?.upperLabel)
                      disabled = true;
                    if (question?.answerFormat === "scale") {
                      if (
                        typeof question?.scaleRange?.min !== "number" ||
                        typeof question?.scaleRange?.max !== "number"
                      )
                        disabled = true;
                    }
                  }
                } else {
                  disabled = true;
                }
              });
            } else {
              disabled = true;
            }
          } else {
            disabled = true;
          }
        });
      } else {
        disabled = true;
      }
    } else if (currentStep === 5) {
      disabled = validateEmployeeStep();
      if (surveyData?.anonymous || surveyData?.type === "enps") {
        if (isExitSurveyModel) {
          disabled = false;
        } else {
          if (surveyData?.employees && surveyData?.employees?.length < 3)
            disabled = true;
        }
      }
    }
  } else {
    if (currentStep === 0) {
      if (!communicationData?.name) disabled = true;
      if (!communicationData?.title) disabled = true;
      if (!communicationData?.message) disabled = true;
    } else if (currentStep === 1) {
      disabled = validateEmployeeStep();
    } else if (currentStep === 2) {
      disabled = validateDispatchStep();
    }
  }

  const handleTrackName = (action: string) => {
    const commonSteps = ["models", "configuration", "content", "review"];
    const stepsMap = {
      custom: {
        type: "survey",
        steps: [...commonSteps, "survey_infos", "send_infos", "recipients"],
      },
      voluntaryExit: {
        type: "voluntaryoffboarding",
        steps: [
          ...commonSteps.slice(0, 1),
          "infos",
          "sendinfos",
          ...commonSteps.slice(1),
          "addrecipients",
        ],
      },
      involuntaryExit: {
        type: "involuntaryoffboarding",
        steps: [
          ...commonSteps.slice(0, 1),
          "infos",
          "sendinfos",
          ...commonSteps.slice(1),
          "addrecipients",
        ],
      },
      default: {
        type: "",
        steps: commonSteps,
      },
    };

    const model = surveyData?.model || "default";
    const { type, steps } = stepsMap[model] || stepsMap.default;

    return `people_strategic_hr_engagement_company_${type}_${steps[currentStep]}_${action}`;
  };

  return (
    <Container>
      {/* Apenas para pesquisas */}
      {currentStep === 0 && type === "survey" ? (
        <>
          <ButtonsContainer>
            <LinkButton
              variant="neutral"
              onClick={() => navigate("/engagement/survey")}
            >
              Sair
            </LinkButton>
          </ButtonsContainer>
          {surveyData?.status === "send" && surveyData?.type ? (
            <ButtonsContainer>
              <Button
                variant="primary"
                size={"large"}
                disabled={disabled}
                style={{ width: "200px" }}
                onClick={() => onNextButtonClick(currentStep + 1)}
              >
                Continuar
              </Button>
            </ButtonsContainer>
          ) : null}
        </>
      ) : (
        <>
          <ButtonsContainer>
            <LinkButton
              variant="neutral"
              onClick={() => {
                segment({ track: handleTrackName("exit_clicked") });
                navigate("/engagement/survey");
              }}
            >
              Sair sem salvar
            </LinkButton>
            {["scheduled", "send"]?.includes(
              surveyData?.status || ""
            ) ? null : (
              <>
                <Divider spacing="s3" type="horizontal" />
                <LinkButton
                  variant="neutral"
                  disabled={isDraftButtonDisabled()}
                  onClick={() => {
                    segment({ track: handleTrackName("savedraft_clicked") });
                    onDraftButtonClick();
                  }}
                >
                  Sair e salvar rascunho
                </LinkButton>
              </>
            )}
          </ButtonsContainer>
          <ButtonsContainer>
            <Button
              variant="secondary"
              size="large"
              disabled={currentStep === 0}
              style={{ width: "200px" }}
              onClick={() => {
                segment({
                  track: handleTrackName("return_clicked"),
                });
                onPreviousButtonClick(currentStep - 1);
              }}
            >
              <Icons name="IconArrowLeft" />
              Voltar
            </Button>
            <Divider spacing={"xs2"} type="horizontal" />
            <Button
              loading={
                loading || (companyEmployeesLoading && currentStep === 4)
              }
              variant="primary"
              size="large"
              disabled={disabled}
              style={{ width: "200px" }}
              onClick={() => {
                segment({
                  track: handleTrackName(
                    currentStep < 6 ? "next_clicked" : `createsurvey_clicked`
                  ),
                });
                onNextButtonClick(currentStep + 1);
              }}
            >
              {(isExitSurveyModel ? currentStep < 5 : currentStep < 6) ? (
                <>
                  {"Continuar"}
                  <Icons name="IconArrowRight" />
                </>
              ) : (
                "Criar pesquisa"
              )}
            </Button>
          </ButtonsContainer>
        </>
      )}
    </Container>
  );
};

export { Footer };
