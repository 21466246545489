import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styles";

const EmptyState = () => (
  <Container>
    <Typography
      variant="body1"
      variantColor="var(--color-neutral-50)"
      weight={600}
    >
      Nenhum dado encontrado
      <br /> para exibir esse gráfico.
    </Typography>
  </Container>
);

export { EmptyState };
