import { Checkbox, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  DaysContainer,
  Container,
  Label,
  MainContainer,
  HelperTextContainer,
} from "./styles";
import { DaysPickerInterface } from "./types";
import { Space } from "../Space";

const DaysPicker = ({
  days,
  onChange,
  disabled,
  errorText,
}: DaysPickerInterface) => {
  const getDayByValue = (value: string) => {
    const selected = days?.find(({ value: dValue }) => dValue === value);
    if (selected?.value) return { ...selected, checked: !selected?.checked };
    return;
  };

  const handleChange = (value: string) =>
    onChange(
      days?.map((day) =>
        day?.value === value ? { ...day, checked: !day?.checked } : day
      ),
      getDayByValue(value)
    );

  return (
    <MainContainer error={Boolean(errorText?.length)}>
      <DaysContainer>
        {days?.map(({ label, value, checked }) => (
          <Container
            key={value}
            onClick={() => (disabled ? null : handleChange(value))}
          >
            <Checkbox checked={checked} disabled={disabled} />
            <Label variant="body3">{label}</Label>
            <Space size="lg" />
          </Container>
        ))}
      </DaysContainer>
      {errorText ? (
        <HelperTextContainer>
          <Icons color="#FEA034" name="IconInfoCircle" size={18} />
          <Typography variant="body4" variantColor="#6B5B66" weight={600}>
            {errorText}
          </Typography>
        </HelperTextContainer>
      ) : null}
    </MainContainer>
  );
};

export { DaysPicker };
