import { TransferList } from "@components/v2";
import { EmployeeProps } from "./types";

const Employee = ({
  employees,
  filters,
  setFilters,
  departments,
  groups,
  roles,
  formik,
}: EmployeeProps) => {
  return (
    <div
      style={{
        width: "100%",
        height: "800px",
        marginBottom: "120px",
      }}
    >
      <TransferList
        disabled={false}
        error={formik?.touched?.employees && formik?.errors?.employees}
        data={
          employees
            ?.filter(({ id: employeeId }) => {
              let filtered = false;

              const groupOptions = filters[0]?.options;
              const groupIds = groupOptions
                ?.filter(({ checked }) => checked)
                ?.map(({ value }) => value);

              const departmentOptions = filters[1]?.options;
              const departmentIds = departmentOptions
                ?.filter(({ checked }) => checked)
                ?.map(({ value }) => value);

              const roleOptions = filters[2]?.options;
              const roleIds = roleOptions
                ?.filter(({ checked }) => checked)
                ?.map(({ value }) => value);

              const hasFilter =
                (departmentIds?.length || 0) +
                (groupIds?.length || 0) +
                (roleIds?.length || 0);

              if (hasFilter === 0) return true;

              groupIds?.forEach((groupId) => {
                const group = groups?.find(({ id }) => id === groupId);
                if (group?.employees?.some((e) => e === employeeId))
                  filtered = true;
              });

              departmentIds?.forEach((departmentId) => {
                const department = departments?.find(
                  ({ id }) => id === departmentId
                );
                if (department?.employees?.includes(employeeId))
                  filtered = true;
              });

              roleIds?.forEach((roleId) => {
                const role = roles?.find(({ id }) => id === roleId);
                if (role?.employees?.includes(employeeId)) filtered = true;
              });

              return filtered;
            })
            ?.map(({ id, name, corporateEmail }) => ({
              id,
              name,
              corporateEmail: corporateEmail || "",
            })) || []
        }
        selected={
          employees?.filter((e) =>
            formik?.values?.employees?.includes(e?.id)
          ) || []
        }
        onChange={(selected) =>
          formik.handleChange({
            target: {
              id: "employees",
              value: selected?.map((e) => e?.id) || [],
            },
          })
        }
        filter={{
          sections: filters,
          onChange: (e) =>
            setFilters([...filters?.map((s) => (s?.id === e?.id ? e : s))]),
          onReset: (e) => setFilters([...e]),
        }}
      />
    </div>
  );
};

export { Employee };
