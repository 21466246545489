import { NotificationCardV2 } from "@components/NotificationCardV2";
import {
  Accordion,
  BoxAdornment,
  Checkbox,
  Icons,
  Radio,
  Tag,
  Toggle,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StyledText, StyledTitle, segment } from "@utils";
import { CounterField, Typography } from "../../../..";
import { Divider } from "../../../../v2";
import {
  StepContainer,
  StepDescription,
  generateSurveyDataByModel,
} from "../../../common/components";
import { Container, Row, Column } from "./styles";
import { StepConfigInterface } from "./types";
import {
  BorderDivider,
  FlexBetween,
  FlexColumn,
  StyledBoxAdornment,
} from "@components/EngagementForm/communication/steps/StepConfiguration/styles";
import { TooltipText } from "@components/Card/styles";
import { useEffect, useState } from "react";
import { OwnersMultiSelect } from "@components/OwnersMultiSelect";
import { OwnersNotificationCard } from "@components/OwnersNotificationCard";

const StepConfig = ({
  disabled,
  hide,
  model,
  anonymous,
  notifications,
  sections,
  disableReminder,
  owners,
  onChange,
}: StepConfigInterface) => {
  const [responsibilityMode, setResponsibilityMode] = useState<
    "everyone" | "specific"
  >(!!owners && owners?.length > 1 ? "specific" : "everyone");

  useEffect(() => {
    setResponsibilityMode(
      !!owners && owners?.length > 1 ? "specific" : "everyone"
    );
  }, [model]);

  const hasScaleRange10 = sections?.reduce((a, c) => {
    if (a) return a;
    return !!c?.questions?.find(({ scaleRange }) => scaleRange?.max === 10)?.id;
  }, false);

  if (hide) return <></>;

  return (
    <StepContainer>
      <StepDescription
        model={model}
        title="Configurações"
        description="Adicione as configurações da pesquisa, como anonimato e notificações."
      />
      <Container>
        {model === "engaja" ? (
          <NotificationCardV2
            variant="outlined"
            type="info"
            title="Essa pesquisa é anônima: As pessoas não serão identificadas"
            subtitle="Por padrão, as respostas desta pesquisa não serão vinculadas à identidade dos participantes, o que garante privacidade nas respostas."
            iconName="IconAlertTriangle"
          />
        ) : model === "feeling" ? (
          <>
            <NotificationCardV2
              variant="contained"
              type="info"
              title="As pessoas serão identificadas"
              subtitle="Por padrão, as respostas desta pesquisa serão vinculadas à identidade dos participantes, o que garante maior precisão na análise dos dados."
              iconName="IconAlertTriangle"
            />
          </>
        ) : (
          <Accordion
            className="step-config-accordion"
            customHeader={
              <>
                <Typography
                  variant="headline8"
                  color="var(--color-neutral-30)"
                  fontWeight={700}
                >
                  Anonimato
                </Typography>
                <Typography
                  variant="body4"
                  color="var(--color-neutral-50)"
                  fontWeight={400}
                >
                  Habilite o botão abaixo caso deseje que a pesquisa seja
                  anônima.
                </Typography>
              </>
            }
            children={
              <>
                {["enps", "climate", "engaja"]?.includes(model || "") ? (
                  <>
                    <NotificationCardV2
                      variant="contained"
                      type="info"
                      title="Modelo bloqueado"
                      subtitle={`Buscando proteger a identidade dos participantes e garantir a confiabilidade da pesquisa, as respostas são padronizadas como anônimas.`}
                      iconName="IconAlertTriangle"
                      closeButton
                      autoClose
                    />
                    <Divider spacing={"xs2"} />
                  </>
                ) : null}
                <BoxAdornment
                  className="step-config-box-adornment"
                  description={
                    <>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={700}
                      >
                        Resposta anônima
                      </Typography>
                      <Typography
                        variant="body4"
                        color="var(--color-neutral-50)"
                        fontWeight={400}
                      >
                        Habilite esta opção para que as pessoas participantes
                        não tenham suas respostas identificadas.
                      </Typography>
                    </>
                  }
                  leftAdornment={
                    <Toggle
                      disabled={
                        ["enps", "climate", "engaja", "feeling"]?.includes(
                          model || ""
                        ) || disabled
                      }
                      checked={anonymous || false}
                      onChange={() => {
                        const a = !anonymous;

                        if (a) {
                          segment({
                            track: `people_strategic_hr_engagement_company_${
                              model === "enps" ? "enps" : "survey"
                            }_configuration_anonymousanswer_selected`,
                          });
                        }

                        onChange({ anonymous: a, notifications, sections });
                      }}
                    />
                  }
                />
              </>
            }
            variant={"default"}
            defaultExpanded
          />
        )}
        <Accordion
          className="step-config-accordion"
          customHeader={
            <>
              <Tag variant="error" style={{ marginBottom: "16px" }} disabled>
                Obrigatório
              </Tag>
              <Typography
                variant="headline8"
                color="var(--color-neutral-30)"
                fontWeight={700}
              >
                Notificações
              </Typography>
              <Typography
                variant="body4"
                color="var(--color-neutral-50)"
                fontWeight={400}
              >
                Selecione pelo menos uma opção de como deseja notificar os
                destinatários.
              </Typography>
            </>
          }
          children={
            <>
              <BoxAdornment
                className="step-config-box-adornment"
                description={
                  <>
                    <Typography
                      variant="body3"
                      color="var(--color-neutral-30)"
                      fontWeight={700}
                    >
                      Push do aplicativo
                    </Typography>
                    <Typography
                      variant="body4"
                      color="var(--color-neutral-50)"
                      fontWeight={400}
                    >
                      O destinatário receberá uma notificação de push do
                      aplicativo em suas notificações do celular.
                    </Typography>
                  </>
                }
                leftAdornment={
                  <Checkbox
                    disabled={disabled}
                    checked={notifications?.push || false}
                    onChange={() => {
                      const p = !notifications?.push;

                      if (p) {
                        segment({
                          track: `people_strategic_hr_engagement_company_${
                            model === "custom" ? "survey" : model || ""
                          }_configuration_pushnotification_selected`,
                        });
                      }

                      onChange({
                        notifications: {
                          ...notifications,
                          push: p,
                        },
                        anonymous,
                        sections,
                      });
                    }}
                  />
                }
              />
              <Divider spacing="xs2" />
              <BoxAdornment
                className="step-config-box-adornment"
                description={
                  <>
                    <Typography
                      variant="body3"
                      color="var(--color-neutral-30)"
                      fontWeight={700}
                    >
                      {["voluntaryExit", "involuntaryExit"]?.includes(
                        model || ""
                      )
                        ? "E-mail pessoal"
                        : "E-mail corporativo"}
                    </Typography>
                    <Typography
                      variant="body4"
                      color="var(--color-neutral-50)"
                      fontWeight={400}
                    >
                      {["voluntaryExit", "involuntaryExit"]?.includes(
                        model || ""
                      )
                        ? "O destinatário receberá uma mensagem em seu e-mail pessoal, informando que possui uma nova pesquisa. Caso não tenha e-mail pessoal cadastrado, a mensagem será enviada para o e-mail corporativo."
                        : " O destinatário receberá um e-mail em seu e-mail corporativo cadastrado informando que possui uma nova pesquisa."}
                    </Typography>
                  </>
                }
                leftAdornment={
                  <Checkbox
                    disabled={disabled}
                    checked={notifications?.email || false}
                    onChange={() => {
                      const e = !notifications?.email;

                      if (e) {
                        segment({
                          track: `people_strategic_hr_engagement_company_${
                            model === "custom" ? "survey" : model || ""
                          }_configuration_emailnotification_selected`,
                        });
                      }

                      onChange({
                        notifications: {
                          ...notifications,
                          email: !notifications?.email,
                        },
                        anonymous,
                        sections,
                      });
                    }}
                  />
                }
              />
              {model === "voluntaryExit" ||
              model === "involuntaryExit" ? null : (
                <>
                  <BorderDivider />
                  <StyledTitle
                    variant="headline9"
                    setColor={"neutral30"}
                    style={{
                      marginBottom: "16px",
                      fontWeight: 700,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    Envio da notificação{" "}
                    {disabled || disableReminder ? (
                      <Icons name="IconInfoCircle" style={{ marginLeft: 5 }} />
                    ) : null}
                  </StyledTitle>
                  <StyledBoxAdornment
                    title={"Habilitar envio de notificações automáticas"}
                    description={
                      <FlexColumn>
                        <div>
                          <StyledText variant="body4" setColor="neutral50">
                            Selecione esta opção para habilitar o envio de
                            notificações automáticas. Quando ativado, a cada 3
                            dias o sistema enviará notificações aos usuários que
                            não responderam a pesquisa até que a taxa de
                            resposta esperada seja alcançada ou o período de
                            prazo de respostas termine.
                          </StyledText>
                        </div>
                        {!!notifications?.reminder?.enabled ? (
                          <>
                            <BorderDivider />
                            <FlexBetween>
                              <StyledText variant="body4" setColor="neutral50">
                                Notificar <b>a cada 3 dias</b> até atingir a
                                taxa de resposta de
                              </StyledText>
                              <CounterField
                                percentageMode
                                setIncrementAndDecrement={5}
                                disabled={
                                  disabled || !notifications?.reminder?.enabled
                                }
                                value={notifications?.reminder?.minRate || 60}
                                max={100}
                                onChange={(value) => {
                                  onChange({
                                    anonymous,
                                    sections,
                                    notifications: {
                                      ...notifications,
                                      reminder: {
                                        enabled:
                                          notifications?.reminder?.enabled ||
                                          false,
                                        minRate: value,
                                      },
                                    },
                                  });
                                }}
                                min={50}
                              />
                            </FlexBetween>
                          </>
                        ) : null}
                      </FlexColumn>
                    }
                    leftAdornment={
                      disabled || disableReminder ? (
                        <Tooltip
                          placement="top-start"
                          title={
                            <>
                              <TooltipText variant="body4">
                                Funcionalidade disponível somente para pesquisas
                                com prazo de resposta de 3 dias ou mais.
                              </TooltipText>
                            </>
                          }
                        >
                          <div>
                            <Toggle
                              disabled={disabled || disableReminder}
                              checked={!!notifications?.reminder?.enabled}
                              onChange={(e) => {
                                const checked = e.target.checked;

                                if (checked) {
                                  segment({
                                    track: `people_strategic_hr_engagement_company_${
                                      model === "custom"
                                        ? "survey"
                                        : model || ""
                                    }_configuration_automaticnotifications_selected`,
                                  });
                                }

                                onChange({
                                  anonymous,
                                  sections,
                                  notifications: {
                                    ...notifications,
                                    reminder: {
                                      enabled: checked,
                                      minRate: checked ? 60 : 0,
                                    },
                                  },
                                });
                              }}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <Toggle
                          disabled={disabled || disableReminder}
                          checked={!!notifications?.reminder?.enabled}
                          onChange={(e) => {
                            const checked = e.target.checked;

                            if (checked) {
                              segment({
                                track: `people_strategic_hr_engagement_company_${
                                  model === "custom" ? "survey" : model || ""
                                }_configuration_automaticnotifications_selected`,
                              });
                            }

                            onChange({
                              anonymous,
                              sections,
                              notifications: {
                                ...notifications,
                                reminder: {
                                  enabled: checked,
                                  minRate: checked ? 60 : 0,
                                },
                              },
                            });
                          }}
                        />
                      )
                    }
                    style={{ width: "100%" }}
                  />
                </>
              )}
            </>
          }
          variant={"default"}
          defaultExpanded
        />
        <Accordion
          className="step-config-accordion"
          customHeader={
            <>
              <Typography
                variant="headline8"
                color="var(--color-neutral-30)"
                fontWeight={700}
              >
                Responsáveis
              </Typography>
              <Typography
                variant="body4"
                color="var(--color-neutral-50)"
                fontWeight={400}
              >
                Escolha abaixo quem terá permissão para acessar, visualizar
                dados e realizar ações. Administradores com permissão geral
                terão acesso independente da seleção feita.
              </Typography>
            </>
          }
          children={
            <Column>
              <Row>
                <BoxAdornment
                  style={{ width: "100%" }}
                  description={
                    <>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                      >
                        Todos com acesso ao módulo
                      </Typography>
                    </>
                  }
                  leftAdornment={
                    <Radio
                      disabled={disabled}
                      checked={responsibilityMode === "everyone"}
                      onChange={() => {
                        segment({
                          track: `people_strategic_hr_engagement_company_${
                            model === "custom" ? "survey" : model || ""
                          }_configuration_responsible_everyone_clicked`,
                        });

                        setResponsibilityMode("everyone");

                        onChange({
                          owners: [{ name: "*", id: "*" }],
                        });
                      }}
                    />
                  }
                />
                <Divider spacing="xs1" type="horizontal" />
                <BoxAdornment
                  style={{ width: "100%" }}
                  description={
                    <>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                      >
                        Selecionar pessoas
                      </Typography>
                    </>
                  }
                  leftAdornment={
                    <Radio
                      disabled={disabled}
                      checked={responsibilityMode === "specific"}
                      onChange={() => {
                        segment({
                          track: `people_strategic_hr_engagement_company_${
                            model === "custom" ? "survey" : model || ""
                          }_configuration_responsible_specificpeople_clicked`,
                        });

                        setResponsibilityMode("specific");
                        onChange({
                          owners: undefined,
                        });
                      }}
                    />
                  }
                />
              </Row>
              {responsibilityMode === "specific" && (
                <>
                  <OwnersNotificationCard />
                  <OwnersMultiSelect
                    owners={owners?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    onChange={(values) => {
                      onChange({
                        owners: values.map((value) => ({
                          name: value?.label,
                          id: value?.value,
                        })),
                      });
                    }}
                  />
                </>
              )}
            </Column>
          }
          variant={"default"}
          defaultExpanded
        />
        {model === "climate" ? (
          <Accordion
            className="step-config-accordion"
            customHeader={
              <>
                <Typography
                  variant="headline8"
                  color="var(--color-neutral-30)"
                  fontWeight={700}
                >
                  eNPS
                </Typography>
                <Typography
                  variant="body4"
                  color="var(--color-neutral-50)"
                  fontWeight={400}
                >
                  Habilite a opção para adicionar pergunta e análise de eNPS em
                  sua pesquisa.
                </Typography>
              </>
            }
            children={
              <BoxAdornment
                className="step-config-box-adornment"
                description={
                  <>
                    <Typography
                      variant="body3"
                      color="var(--color-neutral-30)"
                      fontWeight={700}
                    >
                      Habilitar pergunta de eNPS
                    </Typography>
                    <Typography
                      variant="body4"
                      color="var(--color-neutral-50)"
                      fontWeight={400}
                    >
                      Ao habilitar essa opção, uma pergunta sobre o eNPS será
                      incluída no final da pesquisa. Com <br /> essa
                      configuração, além da análise das perguntas de
                      engajamento, sua pesquisa também fornecerá uma <br />{" "}
                      seção dedicada à análise da pergunta de eNPS.
                    </Typography>
                  </>
                }
                leftAdornment={
                  <Toggle
                    disabled={disabled}
                    checked={hasScaleRange10}
                    onChange={() => {
                      if (!hasScaleRange10) {
                        onChange({
                          anonymous,
                          notifications,
                          sections: [
                            ...(sections || []),
                            ...generateSurveyDataByModel("climate-with-enps"),
                          ],
                        });
                      } else {
                        onChange({
                          anonymous,
                          notifications,
                          sections: sections?.filter(
                            (s) =>
                              !!!s?.questions?.find(
                                (q) =>
                                  q?.scaleRange?.max === 10 ||
                                  q?.answerFormat === "text"
                              )?.id
                          ),
                        });
                      }
                    }}
                  />
                }
              />
            }
            variant={"default"}
            defaultExpanded
          />
        ) : null}
      </Container>
    </StepContainer>
  );
};

export { StepConfig };
