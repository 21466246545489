import {
  Checkbox,
  dayjs,
  Icons,
  LinkButton,
  SelectField,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@components/v2";
import { Typography } from "@components/Typography";
import { StepContainer } from "../../components/StepContainer";
import { Box as BoxComponent } from "../../components/Box";
import { SurveyModel } from "../../screen/types";
import {
  SectionContainer,
  SectionSubContainer,
  TextAreaContainer,
} from "./styles";
import { FlexContainer } from "@components/FlexContainer";

const Info = ({
  formik,
  model,
  engajaPDFUrl,
}: {
  engajaPDFUrl?: any;
  formik: any;
  model?: SurveyModel;
}) => (
  <StepContainer>
    <BoxComponent>
      <SectionContainer>
        <SectionSubContainer>
          <Typography
            variant="headline7"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            Nome e descrição
          </Typography>
          <Divider spacing="quark" />
          <Typography
            variant="body4"
            color="var(--color-neutral-50)"
            fontWeight={400}
          >
            Dê um nome para identificar a pesquisa, um nome público e descreva o
            objetivo.
          </Typography>
        </SectionSubContainer>
      </SectionContainer>
      <Divider spacing="s1" />

      <TextField
        label="Nome de identificação da pesquisa *"
        helperText={
          formik.touched.name && formik.errors.name
            ? formik.errors.name
            : "O nome ficará visível apenas para você na listagem da tabela."
        }
        value={formik.values.name}
        fullWidth
        inputProps={{ maxLength: 50 }}
        onChange={(e) =>
          formik.handleChange({
            target: {
              id: "name",
              value: e?.target?.value || "",
            },
          })
        }
        onBlur={formik.handleBlur("name")}
        error={formik.touched.name && Boolean(formik.errors.name)}
      />
      <Divider spacing="xs2" />

      <TextField
        label="Nome de exibição pública *"
        helperText={
          formik.touched.title && formik.errors.title
            ? formik.errors.title
            : "O nome será exibido para os usuários."
        }
        value={formik.values.title}
        fullWidth
        inputProps={{ maxLength: 30 }}
        onChange={(e) =>
          formik.handleChange({
            target: {
              id: "title",
              value: e?.target?.value || "",
            },
          })
        }
        onBlur={formik.handleBlur("title")}
        error={formik.touched.title && Boolean(formik.errors.title)}
      />
      <Divider spacing="xs2" />

      <TextAreaContainer>
        <TextField
          label="Descrição pública da pesquisa *"
          helperText={
            formik.touched.description && formik.errors.description
              ? formik.errors.description
              : "A descrição pública da pesquisa será exibida para os usuários."
          }
          value={formik.values.description}
          placeholder="Descrição pública da pesquisa *"
          fullWidth
          inputProps={{ maxLength: 1200 }}
          onChange={(e) =>
            formik.handleChange({
              target: {
                id: "description",
                value: e?.target?.value || "",
              },
            })
          }
          onBlur={formik.handleBlur("description")}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
        />
      </TextAreaContainer>
    </BoxComponent>
    {model?._id?.includes("engaja_") ? (
      <FlexContainer flexDirection="column" width="100%">
        <BoxComponent>
          <SectionContainer>
            <SectionSubContainer>
              <Typography
                variant="headline7"
                color="var(--color-neutral-30)"
                fontWeight={700}
              >
                Dados da empresa
              </Typography>
              <Divider spacing="quark" />
              <Typography
                variant="body4"
                color="var(--color-neutral-50)"
                fontWeight={400}
              >
                Preencha os dados da sua empresa.
              </Typography>
            </SectionSubContainer>
          </SectionContainer>
          <Divider spacing="xs2" />
          <SelectField
            disabled={false}
            label="Setor econômico da empresa *"
            fullWidth
            options={[
              {
                label: "Indústria",
                value: "industry",
              },
              {
                label: "Comércio varejista",
                value: "retail",
              },
              {
                label: "Comércio atacadista",
                value: "wholesale",
              },
              {
                label:
                  "Serviços (consultoria, advocacia, telefonia, seguros, etc.)",
                value: "services",
              },
              {
                label: "Serviços financeiros",
                value: "financial",
              },
              {
                label: "Educação",
                value: "education",
              },
              {
                label: "Saúde",
                value: "healthcare",
              },
              {
                label: "Cultura ou turismo",
                value: "culture",
              },
              {
                label: "Construção civil",
                value: "construction",
              },
              {
                label: "Tecnologia",
                value: "technology",
              },
              {
                label: "Transportes",
                value: "transportation",
              },
              {
                label: "Outros",
                value: "other",
              },
            ]}
            onSelectChange={(_: any, option) => {
              const value = option?.value;

              formik.handleChange({
                target: {
                  id: "companyData",
                  value: {
                    ...(formik?.values?.companyData || {}),
                    economicSector: value,
                  },
                },
              });
            }}
            value={formik?.values?.companyData?.economicSector}
            error={
              (formik.touched.companyData || formik.submitCount > 0) &&
              Boolean(formik.errors.companyData?.economicSector)
            }
            helperText={
              formik?.touched?.companyData?.economicSector &&
              (formik?.errors?.companyData?.economicSector as string)
            }
          />
          <Divider spacing="xs2" />
          <SelectField
            disabled={false}
            label="Número de colaboradores da empresa *"
            fullWidth
            options={[
              {
                label: "Até 50 colaboradores",
                value: "1-50",
              },
              {
                label: "De 51 a 200 colaboradores",
                value: "51-200",
              },
              {
                label: "De 201 a 500 colaboradores",
                value: "201-500",
              },
              {
                label: "De 501 a 1000 colaboradores",
                value: "501-1000",
              },
              {
                label: "De 1001 a 5000 colaboradores",
                value: "1001-5000",
              },
              {
                label: "Acima de 5001",
                value: "5000+",
              },
              {
                label: "Não sei/Não tenho certeza",
                value: "unknown",
              },
            ]}
            onSelectChange={(_: any, option) => {
              const value = option?.value;

              formik.handleChange({
                target: {
                  id: "companyData",
                  value: {
                    ...(formik?.values?.companyData || {}),
                    employees: value,
                  },
                },
              });
            }}
            value={formik?.values?.companyData?.employees}
            error={
              (formik.touched.companyData || formik.submitCount > 0) &&
              Boolean(formik.errors.companyData?.employees)
            }
            helperText={
              formik?.touched?.companyData?.employees &&
              (formik?.errors?.companyData?.employees as string)
            }
          />
          <Divider spacing="xs2" />
          <SelectField
            disabled={false}
            label="Porte da empresa (Faturamento anual) *"
            fullWidth
            options={[
              {
                label: "MEI - Até R$ 81 mil",
                value: "mei",
              },
              {
                label: "Microempresa - Até R$ 360 mil",
                value: "microenterprise",
              },
              {
                label: "Pequeno Porte - Até R$ 4,8 milhões",
                value: "small",
              },
              {
                label: "Médio Porte - Até R$ 300 milhões",
                value: "medium",
              },
              {
                label: "Grande Porte - Acima de R$ 300 milhões",
                value: "large",
              },
              {
                label: "Não sei/Não tenho certeza",
                value: "unknown",
              },
            ]}
            onSelectChange={(_: any, option) => {
              const value = option?.value;

              formik.handleChange({
                target: {
                  id: "companyData",
                  value: {
                    ...(formik?.values?.companyData || {}),
                    size: value,
                  },
                },
              });
            }}
            value={formik?.values?.companyData?.size}
            error={
              (formik.touched.companyData || formik.submitCount > 0) &&
              Boolean(formik.errors.companyData?.size)
            }
            helperText={
              formik?.touched?.companyData?.size &&
              (formik?.errors?.companyData?.size as string)
            }
          />
        </BoxComponent>

        <FlexContainer
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          width="100%"
          style={{ marginTop: "16px" }}
        >
          <Typography
            variant="body4"
            variantColor="var(--color-neutral-40)"
            weight={700}
          >
            A pesquisa apresentará dados de análise personalizados entre as
            empresas participantes. O anonimato dos dados é garantido.
          </Typography>
          <div
            style={
              (formik.touched.termsAndConditions || formik.submitCount > 0) &&
              Boolean(formik.errors.termsAndConditions?.date)
                ? {
                    backgroundColor: "#FFECD6",
                    borderColor: "#FEA034",
                    boxShadow: "0px 0px 0px 1px #FEA034",
                    padding: "12px",
                    borderRadius: "12px",
                  }
                : undefined
            }
          >
            <FlexContainer>
              <div style={{ marginLeft: "-10px" }}>
                <Checkbox
                  checked={Boolean(
                    formik?.values?.termsAndConditions?.acceptedBy
                  )}
                  onChange={(e) => {
                    formik.handleChange({
                      target: {
                        id: "termsAndConditions",
                        value: e.target.checked
                          ? {
                              acceptedBy: "_",
                              date: dayjs().toDate().toDateString(),
                              type: "engaja",
                            }
                          : undefined,
                      },
                    });
                  }}
                />
              </div>
              <FlexContainer alignItems="center" gap={4}>
                <Typography
                  variant="headline9"
                  variantColor="var(--color-neutral-50)"
                  weight={600}
                >
                  Li e concordo com os
                </Typography>
                <LinkButton
                  variant="default"
                  onClick={() =>
                    engajaPDFUrl ? window.open(engajaPDFUrl, "_blank") : null
                  }
                >
                  termos e condições
                </LinkButton>
                <Typography
                  variant="headline9"
                  variantColor="var(--color-neutral-50)"
                  weight={600}
                >
                  da pesquisa
                </Typography>
              </FlexContainer>
            </FlexContainer>
          </div>

          <FlexContainer>
            <Icons
              name="IconInfoCircle"
              size={16}
              color="var(--color-feedback-error-50)"
            />
            <Typography
              variant="caption"
              variantColor="var(--color-neutral-50)"
              weight={600}
            >
              Você deve concordar com os termos e condições para continuar.
            </Typography>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    ) : null}
  </StepContainer>
);

export { Info };
