import {
  Button,
  Icons,
  Modal as ModalUiv2,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  Body,
  CloseButton,
  Description,
  Footer,
  IconContainer,
  MainIcon,
  SubTitle,
  Title,
} from "./styles";
import { ModalInterface } from "./types";
import { ReactNode } from "react";
import { Divider } from "@components/v2";
const { Root: ModalRoot } = ModalUiv2;

interface MaintenanceModalProps extends Omit<ModalInterface, "description"> {
  description?: string | ReactNode;
}

const MaintenanceModal = ({
  children,
  footer,
  onClose,
  open,
  title,
  subTitle,
  description,
  closeButton,
  iconName,
  iconStatus,
}: MaintenanceModalProps) => {
  const handleText = (text: string, type: "title" | "subtitle") => {
    const splitedText = text?.split("<br/>");
    return (
      <>
        {splitedText?.map((word, idx) => {
          if (type === "title")
            return (
              <Title key={idx} status={iconStatus} variant="body3">
                {word}
              </Title>
            );
          if (type === "subtitle")
            return (
              <SubTitle key={idx} variant="headline6">
                {word}
              </SubTitle>
            );
          return null;
        })}
      </>
    );
  };

  return (
    <ModalRoot
      open={open}
      onClose={onClose}
      children={
        children ? (
          children
        ) : (
          <Body>
            {closeButton ? (
              <CloseButton onClick={onClose}>
                <Icons
                  name="IconX"
                  size={18}
                  color={"var(--color-neutral-40"}
                />
              </CloseButton>
            ) : null}
            {iconName ? (
              <IconContainer status={iconStatus}>
                <MainIcon
                  status={iconStatus || "info"}
                  name={iconName}
                  size={60}
                  onClick={onClose}
                />
              </IconContainer>
            ) : null}
            {title && (
              <>
                <Divider spacing="quark" />
                {handleText(title, "title")}
              </>
            )}
            {subTitle && (
              <>
                <Divider spacing="quark" />
                {handleText(subTitle, "subtitle")}
              </>
            )}
            {description && (
              <>
                <Divider spacing="xxs3" />
                {typeof description === "string" ? (
                  <Description
                    variant="body3"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {description}
                  </Description>
                ) : (
                  <Description variant="body3">{description}</Description>
                )}
              </>
            )}
          </Body>
        )
      }
      footer={
        footer?.submitButton ? (
          <Footer style={{ justifyContent: "center" }}>
            <Button
              variant={footer?.submitButton?.variant || "primary"}
              variantType={footer?.submitButton?.variantType || "default"}
              size={footer?.submitButton?.size || "large"}
              onClick={footer?.submitButton?.onClick}
              disabled={footer?.submitButton?.disabled}
              loading={footer?.submitButton?.loading}
            >
              {footer?.submitButton?.label}
            </Button>
          </Footer>
        ) : null
      }
    />
  );
};

export { MaintenanceModal };
