import {
  Icons,
  LinkButton,
  SelectField,
  TextField,
  Toggle,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Draggable } from "react-beautiful-dnd";
import { FlexContainer } from "@components/FlexContainer";
import { GripContainer, QuestionContainer } from "./styles";
import { QuestionProps } from "./types";
import { QuestionOptions } from "../QuestionOptions";
import { NumericScale } from "../NumericScale";

const Question = ({
  question,
  questionIndex,
  draggableId,
  error,
  onDelete,
  onCopy,
  onChange,
}: QuestionProps) => {
  return (
    <div style={{ width: "100%" }}>
      <Draggable
        key={draggableId}
        draggableId={draggableId}
        index={questionIndex}
        isDragDisabled={false}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={provided?.draggableProps?.style}
          >
            <QuestionContainer isDragging={Boolean(snapshot?.isDragging)}>
              <GripContainer isDragging={Boolean(snapshot?.isDragging)}>
                <Icons
                  name="IconGripVertical"
                  color={Boolean(snapshot?.isDragging) ? "#FFFFFF" : "#53464F"}
                />
              </GripContainer>
              <div
                style={{
                  width: "100%",
                  padding: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "24px",
                }}
              >
                <FlexContainer
                  width="100%"
                  justifyContent="center"
                  alignItems="flex-end"
                  gap={24}
                >
                  <FlexContainer
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width="100%"
                    gap={4}
                  >
                    <Typography
                      variant="body4"
                      variantColor="#6B5B66"
                      weight={600}
                    >
                      Pergunta {questionIndex + 1}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Digite sua pergunta"
                      value={question?.title}
                      error={error?.title}
                      onChange={(e) =>
                        onChange({ ...question, title: e?.target?.value || "" })
                      }
                    />
                  </FlexContainer>
                  <SelectField
                    fullWidth
                    error={error?.answerFormat}
                    style={{ maxWidth: "223px" }}
                    className="answer-format-field"
                    disabled={false}
                    label="Formato da resposta"
                    options={[
                      {
                        label: "Texto",
                        value: "text",
                      },
                      {
                        label: "Data",
                        value: "date",
                      },
                      {
                        label: "Múltipla escolha",
                        value: "multiple-choice",
                      },
                      {
                        label: "Caixa de seleção",
                        value: "select-box",
                      },
                      {
                        label: "Lista suspensa",
                        value: "dropdown",
                      },
                      {
                        label: "Escala numérica",
                        value: "scale",
                      },
                      {
                        label: "Reação (Escala emoji)",
                        value: "emoji",
                      },
                      {
                        label: "Reação (Escala coração)",
                        value: "heart",
                      },
                      {
                        label: "Reação (Escala estrela)",
                        value: "star",
                      },
                      {
                        label: "NPS",
                        value: "enps",
                      },
                    ]}
                    onSelectChange={(_: any, option) => {
                      const answerFormat = option?.value;
                      const options = [
                        "multiple-choice",
                        "select-box",
                        "dropdown",
                      ]?.includes(answerFormat)
                        ? [{ value: "" }, { value: "" }]
                        : [];

                      const scaleRange = ["emoji", "heart", "star"]?.includes(
                        answerFormat
                      )
                        ? {
                            min: 1,
                            max: 5,
                          }
                        : answerFormat === "enps"
                        ? {
                            min: 0,
                            max: 10,
                          }
                        : {
                            min: question?.scaleRange?.min,
                            max: question?.scaleRange?.max,
                          };

                      if (answerFormat)
                        onChange({
                          ...question,
                          answerFormat:
                            answerFormat === "enps" ? "scale" : answerFormat,
                          isENPS: answerFormat === "enps",
                          options,
                          scaleRange,
                          justification:
                            answerFormat === "dropdown"
                              ? false
                              : question?.justification,
                        });
                    }}
                    value={question?.isENPS ? "enps" : question?.answerFormat}
                  />
                </FlexContainer>
                {question?.options?.length ? (
                  <FlexContainer
                    width="100%"
                    flexDirection="column"
                    justifyContent="center"
                    gap={24}
                  >
                    <QuestionOptions
                      answerFormat={question?.answerFormat}
                      error={error?.options || []}
                      options={question?.options}
                      onChange={(updatedOptions) =>
                        onChange({ ...question, options: updatedOptions })
                      }
                      onJustificationDelete={() =>
                        onChange({ ...question, justification: false })
                      }
                      justification={question?.justification || false}
                    />
                    <FlexContainer
                      width="100%"
                      justifyContent="flex-start"
                      gap={16}
                    >
                      <LinkButton
                        disabled={false}
                        variant="default"
                        onClick={() =>
                          onChange({
                            ...question,
                            options: [
                              ...(question?.options || []),
                              { value: "" },
                            ],
                          })
                        }
                      >
                        Adicionar nova opção
                      </LinkButton>
                      {question?.answerFormat === "multiple-choice" ||
                      question?.answerFormat === "select-box" ? (
                        <>
                          <Typography
                            variant="body3"
                            weight={600}
                            variantColor="#53464F"
                          >
                            ou
                          </Typography>
                          <LinkButton
                            disabled={false}
                            variant="neutral"
                            onClick={() =>
                              onChange({ ...question, justification: true })
                            }
                          >
                            {`Adicionar opção de “outro”`}
                          </LinkButton>
                        </>
                      ) : null}
                    </FlexContainer>
                  </FlexContainer>
                ) : null}
                {question?.answerFormat &&
                ["scale", "emoji", "heart", "star"]?.includes(
                  question?.answerFormat
                ) ? (
                  <NumericScale
                    question={question}
                    onChange={(updatedQuestion) =>
                      onChange({ ...updatedQuestion })
                    }
                    isNPS={question?.isENPS}
                    error={{
                      lowerLabel: error?.lowerLabel || false,
                      upperLabel: error?.upperLabel || false,
                      min: error?.min || false,
                      max: error?.max || false,
                    }}
                  />
                ) : null}
                <FlexContainer
                  width="100%"
                  gap={16}
                  justifyContent="flex-start"
                  style={{ borderTop: "1px solid #EBE6E9", paddingTop: "24px" }}
                >
                  <Icons
                    name="IconCopy"
                    color="#6B5B66"
                    style={{ cursor: "pointer" }}
                    onClick={() => onCopy(question)}
                  />
                  <Icons
                    name="IconTrash"
                    color="#6B5B66"
                    style={{ cursor: "pointer" }}
                    onClick={() => onDelete(question)}
                  />
                  <div
                    style={{
                      backgroundColor: "#EBE6E9",
                      height: "24px",
                      width: "1px",
                    }}
                  />
                  <Toggle
                    disabled={false}
                    checked={question?.required || false}
                    onChange={(e) =>
                      onChange({
                        ...question,
                        required: Boolean(e?.target?.checked),
                      })
                    }
                  />
                  <Typography
                    variant="body3"
                    weight={600}
                    variantColor="#53464F"
                  >
                    Pergunta obrigatória
                  </Typography>
                </FlexContainer>
              </div>
            </QuestionContainer>
            {provided?.placeholder}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export { Question };
