const moveItem = (array, fromIndex, toIndex) => {
  if (fromIndex === toIndex) return array;

  const item = array[fromIndex];

  return array
    .filter((_, i) => i !== fromIndex)
    .reduce(
      (acc, curr, i) => (i === toIndex ? [...acc, item, curr] : [...acc, curr]),
      []
    );
};

const insertItem = (array, index, item) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index),
];

export { moveItem, insertItem };
