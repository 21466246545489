import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const MainContainer = styled.div<{ error?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border: 2px solid ${({ error }) => (error ? "#FEA034" : "transparent")};
  background-color: ${({ error }) => (error ? "#FFECD6" : "transparent")};
  border-radius: 12px;
`;

const HelperTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding: 0 0 4px 4px;
`;

const DaysContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 600;
  min-width: max-content;
  cursor: pointer;
`;

export { MainContainer, DaysContainer, Container, Label, HelperTextContainer };
