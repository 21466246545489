import { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { Section, SectionModalForm } from "./components";
import { DraftQuestion, DraftSurvey } from "../../../../screen/types";
import { DragAndDropContainer, SectionsContainer } from "./styles";
import { DragAndDropProps } from "./types";
import { insertItem, moveItem } from "./utils";

const DragAndDrop = ({ formik }: DragAndDropProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const sections: DraftSurvey["sections"] = formik?.values?.sections || [];
  const [editSectionIndex, setEditSectionIndex] = useState<number | null>(null);
  const endRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () =>
    endRef.current && endRef.current.scrollIntoView({ behavior: "smooth" });

  const handleEditSection = (index: number) => {
    setEditSectionIndex(index);
    setOpen(true);
  };

  useEffect(() => {
    scrollToBottom();
  }, [formik?.values?.selectedSectionIndex]);

  return (
    <DragAndDropContainer>
      <SectionsContainer>
        <DragDropContext
          onDragEnd={(e) => {
            const source = {
              section: Number(
                e?.source?.droppableId?.split("section-droppable-")[1]
              ),
              question: e?.source?.index,
            };

            const destination = {
              section: Number(
                e?.destination?.droppableId?.split("section-droppable-")[1]
              ),
              question: e?.destination?.index,
            };

            if (source?.section === destination?.section) {
              const result =
                sections?.map((s, i) => {
                  if (i === source?.section) {
                    const updatedQuestions = s?.questions || [];
                    const newQuestions = moveItem(
                      updatedQuestions,
                      source?.question,
                      destination?.question
                    );
                    return { ...(s || {}), questions: newQuestions || [] };
                  }
                  return s;
                }) || [];

              formik.handleChange({
                target: {
                  id: "sections",
                  value: [...result],
                },
              });
            } else {
              const newQuestion = sections
                ?.find((_, i) => i === source?.section)
                ?.questions?.find((_, i) => i === source?.question);

              const result =
                sections?.map((s, i) => {
                  if (i === source?.section) {
                    return {
                      ...(s || {}),
                      questions: s?.questions?.filter(
                        (_, questionIndex) => questionIndex !== source?.question
                      ),
                    };
                  }

                  if (i === destination?.section && newQuestion) {
                    const updatedQuestions = s?.questions || [];
                    const newQuestions = insertItem(
                      updatedQuestions,
                      destination?.question,
                      newQuestion
                    );
                    return { ...(s || {}), questions: newQuestions || [] };
                  }
                  return s;
                }) || [];

              formik.handleChange({
                target: {
                  id: "sections",
                  value: [...result],
                },
              });
            }
          }}
        >
          {sections?.map((section, sectionIndex) => (
            <Droppable droppableId={`section-droppable-${sectionIndex}`}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ width: "100%" }}
                >
                  <div style={{ width: "100%" }}>
                    {formik?.values?.selectedSectionIndex === sectionIndex ? (
                      <div ref={endRef}></div>
                    ) : null}
                    <Section
                      key={sectionIndex}
                      formik={formik}
                      section={section}
                      sectionIndex={sectionIndex}
                      onEdit={() => handleEditSection(sectionIndex)}
                      onChange={(newSection) => {
                        formik?.handleChange({
                          target: {
                            id: "sections",
                            value: sections?.map((o, i) =>
                              i === sectionIndex ? newSection : o
                            ),
                          },
                        });
                      }}
                      onDelete={(deletedSectionIndex) =>
                        formik?.handleChange({
                          target: {
                            id: "sections",
                            value: sections?.filter(
                              (_, i) => i !== deletedSectionIndex
                            ),
                          },
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </SectionsContainer>
      <LinkButton
        disabled={false}
        variant="neutral"
        onClick={() => setOpen(true)}
      >
        <Icons name="IconPlus" />
        Adicionar nova seção
      </LinkButton>
      <SectionModalForm
        open={open}
        onClose={() => {
          setOpen(false);
          setEditSectionIndex(null);
        }}
        initialData={
          editSectionIndex !== null ? sections?.[editSectionIndex] : undefined
        }
        onSubmit={(newSection) => {
          if (editSectionIndex !== null) {
            const updated = sections?.map((s, i) =>
              i === editSectionIndex ? { ...s, ...newSection } : s
            );
            formik?.handleChange({
              target: { id: "sections", value: updated },
            });
          } else {
            const question: DraftQuestion = {
              title: "",
              answerFormat: undefined,
              isENPS: false,
              justification: false,
              required: false,
            };
            formik?.handleChange({
              target: {
                id: "sections",
                value: [
                  ...(formik?.values?.sections || []),
                  { ...newSection, questions: [question] },
                ],
              },
            });
          }
          setOpen(false);
        }}
      />
    </DragAndDropContainer>
  );
};

export { DragAndDrop };
