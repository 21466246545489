import {
  BoxAdornment,
  DatePicker,
  dayjs,
  Dayjs,
  Radio,
  SelectField,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  CounterField,
  DaysPicker,
  FlexContainer,
  Typography,
} from "@components/index";
import { Row, StyleContainer } from "../Single/styles";
import { Divider } from "@components/v2";
import { useEffect, useState } from "react";

const Recurring = ({ formik }: { formik: any }) => {
  const [periodOptions, setPeriodOptions] = useState<
    { label: string; value: "morning" | "afternoon" | "night" }[]
  >([
    {
      label: "Manhã (08h-12h)",
      value: "morning",
    },
    {
      label: "Tarde (12h-18h)",
      value: "afternoon",
    },
    {
      label: "Noite (18h-22h)",
      value: "night",
    },
  ]);

  useEffect(() => {
    const referenceDate =
      formik?.values?.startDate?.date || dayjs().format("YYYY-MM-DD");

    const diff = dayjs(dayjs()?.format("YYYY-MM-DD")).diff(
      dayjs(referenceDate)?.format("YYYY-MM-DD"),
      "day"
    );

    if (diff >= 0) {
      const hour = parseInt(dayjs()?.format("HH"));

      if (hour < 12) {
        setPeriodOptions([
          {
            label: "Imediatamente",
            value: "morning",
          },
          {
            label: "Tarde (12h-18h)",
            value: "afternoon",
          },
          {
            label: "Noite (18h-22h)",
            value: "night",
          },
        ]);
        return;
      }

      if (hour < 18) {
        setPeriodOptions([
          {
            label: "Imediatamente",
            value: "afternoon",
          },
          {
            label: "Noite (18h-22h)",
            value: "night",
          },
        ]);
        return;
      }

      setPeriodOptions([
        {
          label: "Imediatamente",
          value: "night",
        },
      ]);
    } else {
      setPeriodOptions([
        {
          label: "Manhã (08h-12h)",
          value: "morning",
        },
        {
          label: "Tarde (12h-18h)",
          value: "afternoon",
        },
        {
          label: "Noite (18h-22h)",
          value: "night",
        },
      ]);
    }
  }, [formik?.values?.startDate?.date]);

  useEffect(() => {
    if (formik?.values?.startDate?.period) {
      const selectedPeriod = periodOptions.find(
        ({ value }) => value === formik.values.startDate.period
      );

      if (!selectedPeriod) {
        formik.setValues({
          ...formik.values,
          startDate: {
            ...formik.values.startDate,
            period: undefined,
          },
        });
      }
    }
  }, [periodOptions]);

  return (
    <>
      <StyleContainer>
        <Divider spacing="quark" />
        <Row>
          <Typography
            variant="body3"
            color="var(--color-neutral-30)"
            fontWeight={600}
            minWidth="105px"
          >
            Repetir a cada
          </Typography>
          <CounterField
            disabled={false}
            value={formik?.values?.recurrence?.repeat || 1}
            min={1}
            onChange={(e) => {
              formik.setValues({
                ...formik.values,
                recurrence: {
                  ...(formik?.values?.recurrence || {}),
                  repeat: e,
                },
              });
            }}
          />
          <SelectField
            className="delivery-field"
            disabled={false}
            label="Frequência"
            fullWidth
            options={[
              { label: "Dia", value: "day" },
              { label: "Semana", value: "week" },
              { label: "Mês", value: "month" },
              { label: "Ano", value: "year" },
            ]}
            onSelectChange={(_: any, option) => {
              const value = option?.value;
              if (value) {
                formik.setValues({
                  ...formik.values,
                  recurrence: {
                    ...(formik?.values?.recurrence || {}),
                    frequency: value,
                    daysOfTheWeek:
                      value !== "week"
                        ? formik?.values?.recurrence?.daysOfTheWeek?.map(
                            (d) => ({
                              ...(d || d),
                              checked: false,
                            })
                          )
                        : formik?.values?.recurrence?.daysOfTheWeek || [],
                  },
                });
              }
            }}
            value={formik?.values?.recurrence?.frequency}
            error={
              (formik.touched.recurrence || formik.submitCount > 0) &&
              Boolean(formik.errors.recurrence?.frequency)
            }
            helperText={
              formik?.touched?.recurrence?.frequency &&
              (formik?.errors?.recurrence?.frequency as string)
            }
          />
        </Row>
        {formik?.values?.recurrence?.frequency === "week" && (
          <>
            <Divider spacing="xs1" />
            <Typography
              variant="headline9"
              fontWeight={700}
              color="var(--color-neutral-30)"
            >
              Selecione os dias da semana
            </Typography>
            <DaysPicker
              errorText={
                !formik?.values?.recurrence?.daysOfTheWeek?.some(
                  (d) => d?.checked
                ) && formik.errors.recurrence?.daysOfTheWeek
                  ? "Selecione pelo menos um dia"
                  : undefined
              }
              disabled={false}
              days={formik?.values?.recurrence?.daysOfTheWeek || []}
              onChange={(days) => {
                formik.setValues({
                  ...formik.values,
                  recurrence: {
                    ...(formik?.values?.recurrence || {}),
                    daysOfTheWeek: days,
                  },
                });
              }}
            />
          </>
        )}
        <Divider spacing="xs1" />
        <Typography
          variant="headline9"
          fontWeight={700}
          color="var(--color-neutral-30)"
        >
          Início da recorrência
        </Typography>
        <Divider spacing="xs1" />
        <Row>
          <DatePicker
            className="delivery-field"
            disabled={false}
            fromDate={dayjs(new Date())}
            toDate={
              formik?.values?.recurrence?.endDate
                ? dayjs(formik?.values?.recurrence?.endDate).subtract(1, "day")
                : undefined
            }
            label="Data de envio"
            onDateChange={(date: Dayjs | undefined) => {
              if (date) {
                const value = dayjs(date)?.format("YYYY-MM-DD");
                if (value && value !== "Invalid Date")
                  formik.setValues({
                    ...formik.values,
                    startDate: {
                      date: value,
                      period: formik.values.startDate?.period,
                    },
                  });
              }
            }}
            value={
              formik?.values?.startDate?.date &&
              formik?.values?.startDate?.date !== "Invalid Date"
                ? dayjs(formik?.values?.startDate?.date)
                : undefined
            }
            error={
              (formik.touched.startDate || formik.submitCount > 0) &&
              Boolean(formik.errors.startDate?.date)
            }
            helperText={
              formik?.touched?.startDate?.date &&
              (formik?.errors?.startDate?.date as string)
            }
          />
          <SelectField
            className="delivery-field"
            disabled={false}
            label="Período"
            fullWidth
            options={periodOptions}
            onSelectChange={(_: any, option) => {
              const value = option?.value;
              if (value) {
                formik.setValues({
                  ...formik.values,
                  startDate: {
                    date: formik.values.startDate?.date || undefined,
                    period: value,
                  },
                });
              }
            }}
            value={formik?.values?.startDate?.period}
            error={
              (formik.touched.startDate || formik.submitCount > 0) &&
              Boolean(formik.errors.startDate?.period)
            }
            helperText={
              formik?.touched?.startDate?.period &&
              (formik?.errors?.startDate?.period as string)
            }
          />
        </Row>
        {/* Restante do código permanece igual */}
        <Divider spacing="xs1" />
        <Typography
          variant="headline9"
          fontWeight={700}
          color="var(--color-neutral-30)"
        >
          Fim da recorrência
        </Typography>
        <Divider spacing="xs1" />
        <BoxAdornment
          style={{ width: "100%" }}
          description={
            <Typography
              variant="body3"
              color="var(--color-neutral-30)"
              fontWeight={600}
            >
              Nunca termina
            </Typography>
          }
          leftAdornment={
            <Radio
              checked={
                formik?.values?.recurrence?.occurrenceLimit === -1 &&
                !formik?.values?.recurrence?.endDate
              }
              onChange={() => {
                formik.setValues({
                  ...formik.values,
                  recurrence: {
                    ...(formik?.values?.recurrence || {}),
                    occurrenceLimit: -1,
                    endDate: undefined,
                  },
                });
              }}
            />
          }
        />
        <Divider spacing="xs1" />
        <BoxAdornment
          style={{ width: "100%" }}
          description={
            <Row>
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
                minWidth="86px"
              >
                Termina em
              </Typography>
              <DatePicker
                className="delivery-field"
                disabled={false}
                fromDate={
                  formik?.values?.startDate?.date
                    ? dayjs(formik?.values?.startDate?.date).add(1, "day")
                    : dayjs(new Date()).add(1, "day")
                }
                label="Data"
                onDateChange={(date: Dayjs | undefined) => {
                  if (date) {
                    const value = dayjs(date)?.format("YYYY-MM-DD");
                    if (value && value !== "Invalid Date")
                      formik.setValues({
                        ...formik.values,
                        recurrence: {
                          ...(formik?.values?.recurrence || {}),
                          interactionDeadlineConfig: {
                            ...(formik?.values?.recurrence
                              ?.interactionDeadlineConfig || {}),
                            nextMode: false,
                            calculatedDate: {
                              ...(formik?.values?.recurrence
                                ?.interactionDeadlineConfig?.calculatedDate ||
                                {}),
                              period:
                                formik?.values?.recurrence
                                  ?.interactionDeadlineConfig?.calculatedDate
                                  ?.period,
                            },
                          },
                          occurrenceLimit: undefined,
                          endDate: value,
                        },
                      });
                  }
                }}
                value={
                  formik?.values?.recurrence?.endDate &&
                  formik?.values?.recurrence?.endDate !== "Invalid Date"
                    ? dayjs(formik?.values?.recurrence?.endDate)
                    : undefined
                }
                error={
                  (formik.touched.recurrence || formik.submitCount > 0) &&
                  Boolean(formik.errors.recurrence?.endDate)
                }
                helperText={
                  formik?.touched?.recurrence?.endDate &&
                  (formik?.errors?.recurrence?.endDate as string)
                }
              />
            </Row>
          }
          leftAdornment={
            <Radio
              checked={
                typeof formik?.values?.recurrence?.occurrenceLimit !== "number"
              }
              onChange={() => {
                formik.setValues({
                  ...formik.values,
                  recurrence: {
                    ...(formik?.values?.recurrence || {}),
                    interactionDeadlineConfig: {
                      ...(formik?.values?.recurrence
                        ?.interactionDeadlineConfig || {}),
                      nextMode: false,
                    },
                    occurrenceLimit: undefined,
                    endDate: undefined,
                  },
                });
              }}
            />
          }
        />
        <Divider spacing="xs1" />
        <BoxAdornment
          style={{ width: "100%" }}
          description={
            <Row>
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
              >
                Termina após
              </Typography>
              <CounterField
                disabled={false}
                value={
                  formik?.values?.recurrence?.occurrenceLimit &&
                  formik?.values?.recurrence?.occurrenceLimit > 0
                    ? formik?.values?.recurrence?.occurrenceLimit
                    : 1
                }
                min={1}
                onChange={(e) =>
                  formik.setValues({
                    ...formik.values,
                    recurrence: {
                      ...(formik?.values?.recurrence || {}),
                      interactionDeadlineConfig: {
                        ...(formik?.values?.recurrence
                          ?.interactionDeadlineConfig || {}),
                        nextMode: false,
                      },
                      endDate: undefined,
                      occurrenceLimit: e,
                    },
                  })
                }
              />
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
              >
                ocorrências
              </Typography>
            </Row>
          }
          leftAdornment={
            <Radio
              checked={
                formik?.values?.recurrence?.occurrenceLimit &&
                formik?.values?.recurrence?.occurrenceLimit > 0 &&
                !formik?.values?.recurrence?.endDate
              }
              onChange={() => {
                formik.setValues({
                  ...formik.values,
                  recurrence: {
                    ...(formik?.values?.recurrence || {}),
                    occurrenceLimit: 1,
                    endDate: undefined,
                    interactionDeadlineConfig: {
                      ...(formik?.values?.recurrence
                        ?.interactionDeadlineConfig || {}),
                      nextMode: false,
                    },
                  },
                });
              }}
            />
          }
        />
        <Divider spacing="xs1" />
        <Typography
          variant="headline9"
          fontWeight={700}
          color="var(--color-neutral-30)"
        >
          Período de coleta de respostas
        </Typography>
        <Typography
          variant="body4"
          color="var(--color-neutral-50)"
          fontWeight={400}
        >
          Configure até quando a pesquisa deve receber respostas. Após o limite,
          a pesquisa será fechada e novas respostas não serão aceitas.
        </Typography>
        <Divider spacing="xs1" />
        <BoxAdornment
          style={{ width: "100%" }}
          description={
            <>
              <Typography
                variant="body3"
                variantColor="var(--color-neutral-30)"
                weight={600}
              >
                Até o próximo envio
              </Typography>
            </>
          }
          leftAdornment={
            <Radio
              disabled={
                formik?.values?.recurrence?.occurrenceLimit === -1
                  ? false
                  : true
              }
              checked={
                formik?.values?.recurrence?.interactionDeadlineConfig
                  ?.nextMode === true
              }
              onChange={() => {
                formik.setValues({
                  ...formik.values,
                  recurrence: {
                    ...(formik?.values?.recurrence || {}),
                    interactionDeadlineConfig: {
                      ...(formik?.values?.recurrence
                        ?.interactionDeadlineConfig || {}),
                      nextMode: true,
                    },
                  },
                });
              }}
            />
          }
        />
        <Divider spacing="xs1" />
        <BoxAdornment
          style={{ width: "100%" }}
          description={
            <FlexContainer width="100%" gap={12}>
              <Typography
                variant="body3"
                variantColor="var(--color-neutral-30)"
                weight={600}
              >
                Até
              </Typography>
              <CounterField
                disabled={false}
                value={
                  formik?.values?.recurrence?.interactionDeadlineConfig
                    ?.calculatedDate?.multiplier || 1
                }
                min={1}
                onChange={(e) => {
                  formik.setValues({
                    ...formik.values,
                    recurrence: {
                      ...(formik?.values?.recurrence || {}),
                      interactionDeadlineConfig: {
                        ...(formik?.values?.recurrence
                          ?.interactionDeadlineConfig || {}),
                        nextMode: false,
                        calculatedDate: {
                          multiplier: e,
                          period:
                            formik?.values?.recurrence
                              ?.interactionDeadlineConfig?.calculatedDate
                              ?.period,
                        },
                      },
                    },
                  });
                }}
              />
              <SelectField
                fullWidth
                disabled={false}
                label="Período"
                style={{ width: "200px" }}
                options={[
                  {
                    label: "Dia",
                    value: "day",
                  },
                  {
                    label: "Semana",
                    value: "week",
                  },
                  {
                    label: "Mês",
                    value: "month",
                  },
                ]}
                onSelectChange={(_: any, option) => {
                  formik.setValues({
                    ...formik.values,
                    recurrence: {
                      ...(formik?.values?.recurrence || {}),
                      interactionDeadlineConfig: {
                        ...(formik?.values?.recurrence
                          ?.interactionDeadlineConfig || {}),
                        nextMode: false,
                        calculatedDate: {
                          multiplier:
                            formik?.values?.recurrence
                              ?.interactionDeadlineConfig?.calculatedDate
                              ?.multiplier || 1,
                          period: option?.value,
                        },
                      },
                    },
                  });
                }}
                value={
                  formik?.values?.recurrence?.interactionDeadlineConfig
                    ?.calculatedDate?.period
                }
                error={
                  (formik.touched.recurrence || formik.submitCount > 0) &&
                  Boolean(
                    formik.errors.recurrence?.interactionDeadlineConfig
                      ?.calculatedDate?.period
                  )
                }
                helperText={
                  formik?.touched?.recurrence?.interactionDeadlineConfig
                    ?.calculatedDate?.period &&
                  (formik?.errors?.recurrence?.interactionDeadlineConfig
                    ?.calculatedDate?.period as string)
                }
              />
              <Typography
                variant="body3"
                variantColor="var(--color-neutral-30)"
                weight={600}
              >
                após a data de cada envio
              </Typography>
            </FlexContainer>
          }
          leftAdornment={
            <Radio
              checked={
                formik?.values?.recurrence?.interactionDeadlineConfig
                  ?.nextMode !== true
              }
              onChange={() => {
                formik.setValues({
                  ...formik.values,
                  recurrence: {
                    ...(formik?.values?.recurrence || {}),
                    interactionDeadlineConfig: {
                      ...(formik?.values?.recurrence
                        ?.interactionDeadlineConfig || {}),
                      nextMode: false,
                      calculatedDate: {
                        multiplier: 1,
                        period: undefined,
                      },
                    },
                  },
                });
              }}
            />
          }
        />
      </StyleContainer>
    </>
  );
};

export { Recurring };
