import styled from "styled-components";

const DragAndDropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  padding-bottom: 32px;
`;

const SectionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;

export { SectionsContainer, DragAndDropContainer };
