import * as yup from "yup";

const validationSchema = {
  0: yup.object({
    modelId: yup.string().nullable().notRequired(),
  }),
  1: yup.object({
    name: yup
      .string()
      .max(50)
      .required("Insira um nome para identificar esta pesquisa."),
    title: yup
      .string()
      .required("Insira um título visível para os participantes."),
    description: yup
      .string()
      .required(" Insira uma breve explicação sobre a pesquisa."),
    termsAndConditions: yup
      .object({
        acceptedBy: yup.string().required(),
        date: yup.string().required(),
        type: yup.string().required(),
      })
      .when("modelId", {
        is: (val) => val?.includes("engaja_"),
        then: () =>
          yup
            .object({
              acceptedBy: yup.string().required(),
              date: yup.string().required(),
              type: yup.string().required(),
            })
            .required(),
        otherwise: () =>
          yup
            .object({
              acceptedBy: yup.string().nullable().optional(),
              date: yup.string().nullable().optional(),
              type: yup.string().nullable().optional(),
            })
            .nullable()
            .optional(),
      }),
    companyData: yup
      .object({
        economicSector: yup
          .string()
          .required("Favor selecionar a data de envio"),
        employees: yup.string().required("Favor selecionar o período"),
        size: yup.string().required("Favor selecionar o período"),
      })
      .when("modelId", {
        is: (val) => val?.includes("engaja_"),
        then: () =>
          yup
            .object({
              economicSector: yup.string().required(),
              employees: yup.string().required(),
              size: yup.string().required(),
            })
            .required(),
        otherwise: () =>
          yup
            .object({
              economicSector: yup.string().nullable().optional(),
              employees: yup.string().nullable().optional(),
              size: yup.string().nullable().optional(),
            })
            .nullable()
            .optional(),
      }),
  }),
  2: yup.object({
    delivery: yup.string().required("Favor selecionar modo de envio"),
    startDate: yup
      .object({
        date: yup.string().required("Favor selecionar a data de envio"),
        period: yup.string().required("Favor selecionar o período"),
      })
      .when("delivery", {
        is: "continuous",
        then: () =>
          yup
            .object({
              date: yup.string().nullable().notRequired(),
              period: yup.string().nullable().notRequired(),
            })
            .nullable()
            .notRequired(),
        otherwise: (schema) => schema.required(),
      }),
    interactionDeadline: yup.string().nullable().notRequired(),
    interactionDeadlineDays: yup.number().nullable().notRequired(),
    recurrence: yup
      .object({
        repeat: yup.number().required("Favor selecionar o número de repetição"),
        frequency: yup.string().required("Favor selecionar a frequência"),
        daysOfTheWeek: yup
          .array()
          .of(
            yup
              .object({
                label: yup.string(),
                value: yup.string(),
                checked: yup.boolean(),
              })
              .required()
          )
          .when("frequency", {
            is: (val) => val === "week",
            then: (schema) =>
              schema
                .test(
                  "at-least-one-checked",
                  "Pelo menos um dia da semana precisa estar selecionado",
                  (days) =>
                    Array.isArray(days) &&
                    days.some((day) => day.checked === true)
                )
                .required(),
            otherwise: (schema) => schema.min(0),
          }),
        occurrenceLimit: yup.number(),
        endDate: yup.string().when("occurrenceLimit", {
          is: (val) => typeof val !== "number",
          then: (schema) =>
            schema.required("Favor selecionar uma data de término válida"),
          otherwise: (schema) => schema.notRequired(),
        }),
        interactionDeadlineConfig: yup
          .object({
            nextMode: yup.boolean().required(),
            calculatedDate: yup.object().when("nextMode", {
              is: false,
              then: (schema) =>
                schema
                  .shape({
                    multiplier: yup.number().required(),
                    period: yup.string().required(),
                  })
                  .required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          })
          .required(),
      })
      .when("delivery", {
        is: "recurring",
        then: (schema) =>
          schema.required(
            "Favor configurar a recorrência para envios recorrentes"
          ),
        otherwise: () => yup.mixed().nullable().notRequired(),
      }),
  }),
  3: yup.object({
    notifications: yup
      .object({
        push: yup.boolean().required(),
        email: yup.boolean().required(),
        reminder: yup.object({
          enabled: yup.boolean().required(),
          minRate: yup.number().required(),
        }),
      })
      .test(
        "notification test",
        "Favor selecionar ao menos um modo de envio",
        (val) => Boolean(val.push || val.email)
      )
      .required("Favor selecionar ao menos um modo de envio"),
    everyoneWithAccess: yup.boolean().required(),
    owners: yup
      .array()
      .min(1)
      .of(
        yup.object({
          id: yup.string().required(),
          name: yup.string().required(),
        })
      )
      .when("everyoneWithAccess", {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.min(0).nullable().optional(),
      }),
  }),
  4: yup.object({
    sections: yup
      .array()
      .of(
        yup
          .object({
            referenceId: yup.string().nullable().optional(),
            title: yup.string().required(),
            description: yup.string().nullable().optional(),
            questions: yup
              .array()
              .of(
                yup
                  .object({
                    // Sempre obrigatório
                    required: yup.boolean().required(),
                    isENPS: yup.boolean().required(),
                    title: yup.string().required(),
                    answerFormat: yup.string().required(),

                    // opcional depdendendo do contexto
                    referenceId: yup.string().nullable().optional(),
                    justification: yup.boolean().nullable().optional(),
                    options: yup
                      .array()
                      .of(
                        yup.object({
                          referenceId: yup.string().nullable().optional(),
                          value: yup.string().nullable().optional(),
                        })
                      )
                      .when("answerFormat", {
                        is: (val) =>
                          [
                            "multiple-choice",
                            "select-box",
                            "dropdown",
                          ]?.includes(val || ""),
                        then: () =>
                          yup
                            .array()
                            .of(
                              yup.object({
                                referenceId: yup.string().nullable().optional(),
                                value: yup.string().required(),
                              })
                            )
                            .min(2)
                            .required(),
                        otherwise: (schema) =>
                          schema.min(0).nullable().optional(),
                      }),
                    upperLabel: yup.string().when("answerFormat", {
                      is: (val) =>
                        ["scale", "emoji", "heart", "star"]?.includes(
                          val || ""
                        ),
                      then: () => yup.string().required(),
                      otherwise: () => yup.string().nullable().optional(),
                    }),
                    lowerLabel: yup.string().when("answerFormat", {
                      is: (val) =>
                        ["scale", "emoji", "heart", "star"]?.includes(
                          val || ""
                        ),
                      then: () => yup.string().required(),
                      otherwise: () => yup.string().nullable().optional(),
                    }),
                    scaleRange: yup
                      .object({
                        min: yup.number().nullable().optional(),
                        max: yup.number().nullable().optional(),
                      })
                      .when("answerFormat", {
                        is: (val) =>
                          ["scale", "emoji", "heart", "star"]?.includes(
                            val || ""
                          ),
                        then: () =>
                          yup
                            .object({
                              min: yup.number().required(),
                              max: yup.number().required(),
                            })
                            .required(),
                        otherwise: () =>
                          yup
                            .object({
                              min: yup.number().nullable().optional(),
                              max: yup.number().nullable().optional(),
                            })
                            .nullable()
                            .optional(),
                      }),
                  })
                  .required()
              )
              .min(1)
              .required(),
          })
          .required()
      )
      .min(1),
  }),
  5: yup.object({
    employees: yup
      .array()
      .of(yup.string())
      .required()
      .when("delivery", {
        is: "continuous",
        then: (schema) => schema.max(0).required(),
        otherwise: () =>
          yup
            .array()
            .of(yup.string())
            .required()
            .when("anonymous", {
              is: true,
              then: (schema) => schema.min(3).required(),
              otherwise: (schema) => schema.min(1).required(),
            }),
      }),
  }),
};

export { validationSchema };
