import { GenericBox } from "@components/GenericBox";
import { GraphTwoLinesOneBaseLine } from "@components/Graphs";
import { GraphEmptyState } from "@components/Graphs/GraphEmptyState";
import { GraphHeader } from "@components/Graphs/GraphHeader";
import { LineChartProps } from "./types";

export const LineChart = ({ info, settings, data }: LineChartProps) => {
  const isEmpty = data?.length == 0;

  return (
    <GenericBox size={"medium"}>
      <GraphHeader info={info} disabled={isEmpty} />
      {isEmpty ? (
        <GraphEmptyState />
      ) : (
        <GraphTwoLinesOneBaseLine data={data} settings={settings} />
      )}
    </GenericBox>
  );
};
