import React from "react";
import LockedContent from "./components/LockedSessions";
import { DragAndDrop } from "./components/DragAndDrop";
import { StepContainer } from "../../components/StepContainer";
import { Box as BoxComponent } from "../../components/Box";
import { SurveyModel } from "../../screen/types";

interface StepContentTestProps {
  formik: any;
  model?: SurveyModel;
  selectedSection?: string;
  onSectionSelect?: (id: string) => void;
}

const Content: React.FC<StepContentTestProps> = ({
  formik,
  model,
  selectedSection,
}) => {
  if (model?._id)
    return (
      <StepContainer>
        <BoxComponent>
          <LockedContent
            sections={
              model?.sections?.map((section) => ({
                id: section?.id,
                title: section?.title?.value,
                description: section?.description?.value,
                questions: section?.questions?.map((question) => ({
                  id: question?.id,
                  title: question?.title?.value,
                  answerFormat: question?.answerFormat?.value,
                  isENPS: question?.isENPS || false,
                })),
              })) || []
            }
            selectedSection={selectedSection || model?.sections[0]?.id}
          />
        </BoxComponent>
      </StepContainer>
    );

  return <DragAndDrop formik={formik} />;
};

export { Content };
