import {
  Accordion,
  BoxAdornment,
  Checkbox,
  Radio,
  Toggle,
} from "@flash-tecnologia/hros-web-ui-v2";
import { CounterField, StepContainer, Typography } from "@components/index";
import { OwnersMultiSelect } from "@components/OwnersMultiSelect";
import { NotificationCardV2 } from "@components/NotificationCardV2";
import { SurveyModel } from "../../screen/types";
import { Divider } from "@components/v2";
import { Container, Row, Column } from "./styles";

const Config = ({ formik, model }: { formik: any; model?: SurveyModel }) => {
  const isExitSurvey = (modelId?: string) => {
    if (!modelId) return false;
    return (
      modelId.includes("involuntary_exit") || modelId.includes("voluntary_exit")
    );
  };

  const renderAnonymousOptions = () => {
    if (
      model?.anonymous?.userCanEdit === false &&
      model?.anonymous?.value === false
    ) {
      return (
        <NotificationCardV2
          variant="outlined"
          type="info"
          title="As pessoas serão identificadas"
          subtitle="As respostas desta pesquisa serão vinculadas à identidade dos participantes, o que garante maior precisão na análise dos dados."
          iconName="IconAlertTriangle"
        />
      );
    }

    if (
      model?.anonymous?.userCanEdit === false &&
      model?.anonymous?.value === true
    ) {
      return (
        <NotificationCardV2
          variant="outlined"
          type="info"
          title="Essa pesquisa é anônima: As pessoas não serão identificadas"
          subtitle="Por padrão, as respostas desta pesquisa não serão vinculadas à identidade dos participantes, o que garante privacidade nas respostas."
          iconName="IconAlertTriangle"
        />
      );
    }

    return (
      <Accordion
        className="step-config-accordion"
        style={{ borderColor: "#EBE6E9" }}
        customHeader={
          <>
            <Typography
              variant="headline8"
              color="var(--color-neutral-30)"
              fontWeight={700}
            >
              Anonimato
            </Typography>
            <Typography
              variant="body4"
              color="var(--color-neutral-50)"
              fontWeight={400}
            >
              Habilite o botão abaixo caso deseje que a pesquisa seja anônima.
            </Typography>
          </>
        }
        children={
          <BoxAdornment
            className="step-config-box-adornment"
            description={
              <>
                <Typography
                  variant="body3"
                  color="var(--color-neutral-30)"
                  fontWeight={700}
                >
                  Resposta anônima
                </Typography>
                <Typography
                  variant="body4"
                  color="var(--color-neutral-50)"
                  fontWeight={400}
                >
                  Habilite esta opção para que as pessoas participantes não
                  tenham suas respostas identificadas.
                </Typography>
              </>
            }
            leftAdornment={
              <Toggle
                checked={formik?.values?.anonymous || false}
                disabled={model?.anonymous?.userCanEdit === false}
                onChange={(e) =>
                  formik.handleChange({
                    target: {
                      id: "anonymous",
                      value: e.target.checked,
                    },
                  })
                }
              />
            }
          />
        }
        variant={"default"}
        defaultExpanded
      />
    );
  };

  return (
    <StepContainer>
      <Container>
        {renderAnonymousOptions()}
        <Accordion
          className="step-config-accordion"
          style={{ borderColor: "#EBE6E9" }}
          customHeader={
            <>
              <Typography
                variant="headline8"
                color="var(--color-neutral-30)"
                fontWeight={700}
              >
                Notificações
              </Typography>
              <Typography
                variant="body4"
                color="var(--color-neutral-50)"
                fontWeight={400}
              >
                Selecione pelo menos uma opção de como deseja notificar os
                destinatários.
              </Typography>
            </>
          }
          children={
            <>
              <BoxAdornment
                style={
                  formik?.touched?.notifications &&
                  formik?.errors?.notifications
                    ? {
                        width: "100%",
                        backgroundColor: "#FFECD6",
                        borderColor: "#FEA034",
                        boxShadow: "0px 0px 0px 1px #FEA034",
                      }
                    : { width: "100%" }
                }
                className="step-config-box-adornment"
                description={
                  <>
                    <Typography
                      variant="body3"
                      color="var(--color-neutral-30)"
                      fontWeight={700}
                    >
                      Push do aplicativo
                    </Typography>
                    <Typography
                      variant="body4"
                      color="var(--color-neutral-50)"
                      fontWeight={400}
                    >
                      O destinatário receberá uma notificação de push do
                      aplicativo em suas notificações do celular.
                    </Typography>
                  </>
                }
                leftAdornment={
                  <Checkbox
                    disabled={model?.notification?.push?.userCanEdit === false}
                    checked={formik?.values?.notifications?.push || false}
                    onChange={(e) => {
                      formik.setValues({
                        ...formik.values,
                        notifications: {
                          ...(formik?.values?.notifications || {}),
                          push: e?.target?.checked || false,
                        },
                      });
                    }}
                  />
                }
              />
              <Divider spacing="xs2" />
              <BoxAdornment
                className="step-config-box-adornment"
                style={
                  formik?.touched?.notifications &&
                  formik?.errors?.notifications
                    ? {
                        width: "100%",
                        backgroundColor: "#FFECD6",
                        borderColor: "#FEA034",
                        boxShadow: "0px 0px 0px 1px #FEA034",
                      }
                    : { width: "100%" }
                }
                description={
                  <>
                    <Typography
                      variant="body3"
                      color="var(--color-neutral-30)"
                      fontWeight={700}
                    >
                      {isExitSurvey(formik?.values?.modelId)
                        ? "E-mail pessoal"
                        : "E-mail corporativo"}
                    </Typography>
                    <Typography
                      variant="body4"
                      color="var(--color-neutral-50)"
                      fontWeight={400}
                    >
                      {isExitSurvey(formik.values.modelId)
                        ? "O destinatário receberá um e-mail em seu e-mail pessoal cadastrado informando que possui uma nova pesquisa."
                        : "O destinatário receberá um e-mail em seu e-mail corporativo cadastrado informando que possui uma nova pesquisa."}
                    </Typography>
                  </>
                }
                leftAdornment={
                  <Checkbox
                    disabled={model?.notification?.email?.userCanEdit === false}
                    checked={formik?.values?.notifications?.email || false}
                    onChange={(e) => {
                      formik.setValues({
                        ...formik.values,
                        notifications: {
                          ...(formik?.values?.notifications || {}),
                          email: e?.target?.checked || false,
                        },
                      });
                    }}
                  />
                }
              />
              <Divider spacing="xs2" />
              <BoxAdornment
                className="step-config-box-adornment"
                description={
                  <>
                    <Typography
                      variant="body3"
                      color="var(--color-neutral-30)"
                      fontWeight={700}
                    >
                      Envio de notificações automáticas
                    </Typography>
                    <Typography
                      variant="body4"
                      color="var(--color-neutral-50)"
                      fontWeight={400}
                    >
                      Selecione esta opção para habilitar o envio de
                      notificações automáticas.
                    </Typography>
                    {formik?.values?.notifications?.reminder?.enabled && (
                      <>
                        <Divider spacing="xs2" />
                        <Typography
                          variant="body4"
                          color="var(--color-neutral-50)"
                          fontWeight={400}
                        >
                          Notificar a cada 3 dias até atingir a taxa de resposta
                          de:
                        </Typography>
                        <CounterField
                          disabled={
                            model?.notification?.reminder?.userCanEdit === false
                          }
                          value={
                            formik?.values?.notifications?.reminder?.minRate
                          }
                          min={1}
                          onChange={(e) =>
                            formik.setValues({
                              ...formik.values,
                              notifications: {
                                ...(formik?.values?.notifications || {}),
                                reminder: {
                                  enabled:
                                    formik?.values?.notifications?.reminder
                                      ?.enabled || false,
                                  minRate: e || 0,
                                },
                              },
                            })
                          }
                        />
                      </>
                    )}
                  </>
                }
                leftAdornment={
                  <Toggle
                    checked={
                      formik?.values?.notifications?.reminder?.enabled || false
                    }
                    disabled={
                      model?.notification?.reminder?.userCanEdit === false
                    }
                    onChange={(e) => {
                      const checked = e?.target?.checked || false;

                      formik.setValues({
                        ...formik.values,
                        notifications: {
                          ...(formik?.values?.notifications || {}),
                          reminder: {
                            enabled: checked,
                            minRate: checked
                              ? formik?.values?.notifications?.reminder
                                  ?.minRate || 70
                              : 0,
                          },
                        },
                      });
                    }}
                  />
                }
              />
            </>
          }
          variant={"default"}
          defaultExpanded
        />

        <Accordion
          className="step-config-accordion"
          style={{ borderColor: "#EBE6E9" }}
          customHeader={
            <>
              <Typography
                variant="headline8"
                color="var(--color-neutral-30)"
                fontWeight={700}
              >
                Responsáveis
              </Typography>
              <Typography
                variant="body4"
                color="var(--color-neutral-50)"
                fontWeight={400}
              >
                Escolha quem terá permissão para acessar e visualizar os dados
                desta pesquisa.
              </Typography>
            </>
          }
          children={
            <Column>
              <Row>
                <BoxAdornment
                  style={{ width: "100%" }}
                  description={
                    <>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                      >
                        Todos com acesso ao módulo
                      </Typography>
                    </>
                  }
                  leftAdornment={
                    <Radio
                      disabled={false}
                      checked={formik?.values?.everyoneWithAccess === true}
                      onChange={() => {
                        formik.setValues({
                          ...formik.values,
                          everyoneWithAccess: true,
                          owners: [],
                        });
                      }}
                    />
                  }
                />
                <Divider spacing="xs1" type="horizontal" />
                <BoxAdornment
                  style={{ width: "100%" }}
                  description={
                    <>
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-30)"
                        fontWeight={600}
                      >
                        Selecionar pessoas
                      </Typography>
                    </>
                  }
                  leftAdornment={
                    <Radio
                      disabled={false}
                      checked={formik?.values?.everyoneWithAccess === false}
                      onChange={() => {
                        formik.setValues({
                          ...formik.values,
                          everyoneWithAccess: false,
                          owners: [],
                        });
                      }}
                    />
                  }
                />
              </Row>
              {!formik?.values?.everyoneWithAccess && (
                <OwnersMultiSelect
                  owners={
                    formik?.values?.owners?.map((o) => ({
                      label: o?.name || "",
                      value: o?.id || "",
                    })) || []
                  }
                  onChange={(values) => {
                    formik.setValues({
                      ...formik.values,
                      everyoneWithAccess: false,
                      owners:
                        values?.map((value) => ({
                          name: value?.label,
                          id: value?.value,
                        })) || [],
                    });
                  }}
                  error={formik?.touched?.owners && formik?.errors?.owners}
                />
              )}
            </Column>
          }
          variant={"default"}
          defaultExpanded
        />
      </Container>
    </StepContainer>
  );
};

export { Config };
