import {
  Checkbox,
  Icons,
  Radio,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { FlexContainer } from "@components/FlexContainer";
import { QuestionOptionsProps } from "./types";

const QuestionOptions = ({
  options,
  error,
  justification,
  answerFormat,
  onJustificationDelete,
  onChange,
}: QuestionOptionsProps) => {
  const disabled = options?.length && options?.length > 2 ? false : true;
  const field =
    answerFormat === "multiple-choice" ? (
      <Checkbox disabled />
    ) : answerFormat === "select-box" ? (
      <Radio disabled />
    ) : null;

  return (
    <FlexContainer width="100%" gap={24} flexDirection="column">
      {options?.map((o, optionIndex) => (
        <FlexContainer key={optionIndex} width="100%" gap={8}>
          {field}
          <TextField
            label={`Opção ${optionIndex + 1}`}
            fullWidth
            value={o?.value}
            onChange={(e) =>
              onChange(
                options?.map((o, i) =>
                  i === optionIndex
                    ? { ...o, value: e?.target?.value || "" }
                    : o
                )
              )
            }
            error={Boolean(
              error && error[optionIndex] && error[optionIndex]?.value
            )}
          />
          <Icons
            style={{ cursor: disabled ? "not-allowed" : "pointer" }}
            name="IconTrash"
            color={disabled ? "#ccc" : "#6B5B66"}
            onClick={() =>
              disabled
                ? null
                : onChange(options?.filter((_, i) => i !== optionIndex))
            }
          />
        </FlexContainer>
      ))}
      {justification ? (
        <FlexContainer width="100%">
          {field}
          <TextField disabled fullWidth value="Outro" />
          <Icons
            style={{ cursor: "pointer" }}
            name="IconTrash"
            color="#6B5B66"
            onClick={() => onJustificationDelete()}
          />
        </FlexContainer>
      ) : null}
    </FlexContainer>
  );
};

export { QuestionOptions };
