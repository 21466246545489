import { NewModal } from "@components/NewModal";
import { useTheme } from "styled-components";
import dayjs from "dayjs";
import { Typography } from "@components/Typography";
import { Box } from "@mui/material";

interface SuccessModalProps {
  open: boolean;
  onClose: () => void;
  isDraft: boolean | undefined;
  formik: any;
  navigate: () => void;
}

export const SuccessModal = ({
  open,
  onClose,
  isDraft,
  formik,
  navigate,
}: SuccessModalProps) => {
  const theme = useTheme() as any;

  const { values } = formik;
  const interactionDeadline =
    values.interactionDeadline ||
    (values.delivery === "recurring"
      ? values.recurrence?.interactionDeadlineConfig?.calculatedDate
      : undefined);

  const model = values.modelId?.includes("engaja_")
    ? "engaja"
    : values.modelId?.includes("enps_")
    ? "enps"
    : values.modelId?.includes("feeling_")
    ? "feeling"
    : "custom";

  return (
    <NewModal
      id="modal_create_survey"
      title={
        isDraft
          ? "Rascunho foi criado com sucesso"
          : "Sua pesquisa foi criada com sucesso"
      }
      description={
        isDraft ? (
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            textAlign={"justify"}
            width={500}
          >
            <Typography
              variant="body4"
              variantColor={theme?.colors?.neutral[40]}
            >
              Ela estará <strong>disponível na tabela de comunicações, </strong>
              acesse através da opção de editar nas ações.
            </Typography>
          </Box>
        ) : model === "engaja" && interactionDeadline ? (
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            gap="24px"
            textAlign={"justify"}
            width={500}
          >
            <Typography
              variant="body4"
              variantColor={theme?.colors?.neutral[40]}
            >
              Ela estará{" "}
              <strong>
                disponível para os colaboradores até{" "}
                {dayjs(interactionDeadline)?.format("DD/MM/YYYY")}
              </strong>
              , é possível acompanhar as respostas nos detalhes da pesquisa.
            </Typography>
          </Box>
        ) : interactionDeadline ? (
          `Ela estará disponível para os colaboradores até ${dayjs(
            interactionDeadline
          )?.format(
            "DD/MM/YYYY"
          )}, é possível acompanhar as respostas nos detalhes da pesquisa.`
        ) : (
          "É possível acompanhar as respostas nos detalhes da pesquisa"
        )
      }
      open={open}
      onClose={onClose}
      footer={{
        submitButton: {
          label: "Voltar para Pesquisas",
          variant: "primary",
          onClick: navigate,
        },
      }}
      status={{
        icon: "IconCircleCheck",
        color: "green",
        message: "Tudo certo!",
      }}
    />
  );
};
