import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  dayjs,
  Table,
  tableControllers,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { Avatar, MenuIcon } from "../../../../../../../components/index";
import { EmployeeTabInterface, TableDataType } from "./types";
import { trpc } from "@api/client";
import { alert } from "@utils";

export default ({ data }: EmployeeTabInterface) => {
  const [removed, setRemoved] = useState<string[]>([]);
  data = data?.filter((e) => !removed?.includes(e?.id));

  const { isLoading: loading, mutate: removeEmployeeFromEngagement } =
    trpc?.removeEmployeeFromEngagement?.useMutation({
      onSuccess: (e) => {
        if (e?.value?.removedEmployeeId) {
          setRemoved([...removed, e?.value?.removedEmployeeId]);
          alert("Agendamento cancelado com sucesso!", "success");
        }
      },
      onError: (e: any) => {
        alert(e?.message, "error");
        console.log(e);
      },
    });

  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 5,
  });

  const { pageNumber, pageSize } = pagination;

  const count = data.length;

  const finalData = data?.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize
  );

  const table = tableControllers.useTableColumns<TableDataType>({
    defaultColumn: {
      minSize: 200,
    },
    total: count,
    columns: [
      {
        header: "Nome",
        accessorKey: "name",
        sticky: "left",
        minSize: 300,
        cell: (e) => (
          <Avatar
            name={e?.row?.original?.name || "Anônimo"}
            email={""}
            image={""}
          />
        ),
      },
      {
        header: "Data de envio",
        accessorKey: "date",
        minSize: 200,
        cell: (e) => (
          <div>
            {e?.row?.original?.date
              ? dayjs(e?.row?.original?.date)?.format("DD/MM/YYYY")
              : ""}
          </div>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        minSize: 200,
        cell: (e) => {
          const status = e?.row?.original?.status;

          if (status === "scheduled") return <Tag variant="info">Agendado</Tag>;
          if (status === "not-started")
            return <Tag variant="gray">Não iniciado</Tag>;
          if (status === "done") return <Tag variant="green">Concluído</Tag>;
          if (status === "started")
            return <Tag variant="yellow">Não concluído</Tag>;
          return <></>;
        },
      },
      {
        header: "Visualização",
        accessorKey: "visualized",
        minSize: 200,
        cell: (e) => {
          return e?.row?.original?.visualized ? (
            <Tag variant="gray">Sim</Tag>
          ) : (
            <Tag variant="yellow">Não</Tag>
          );
        },
      },
      {
        header: "Ações",
        accessorKey: "id",
        sticky: "right",
        size: 70,
        cell: (e) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MenuIcon
                options={[
                  {
                    icon: "IconUser",
                    label: "Ver detalhes da pessoa",
                    disabled: false,
                    onClick: () =>
                      navigate(
                        `/engagement/employee/survey/${
                          e?.row?.original?.id || ""
                        }`
                      ),
                  },
                  {
                    icon: "IconX",
                    label: "Cancelar envio",
                    disabled:
                      e?.row?.original?.status === "scheduled" ? false : true,
                    onClick: () => {
                      const surveyId = e?.row?.original?.surveyId;
                      const employeeId = e?.row?.original?.id;

                      if (surveyId && employeeId) {
                        removeEmployeeFromEngagement({
                          engagementId: e?.row?.original?.surveyId || "",
                          employeeId: e?.row?.original?.id || "",
                        });
                      }
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ],
    data: finalData,
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  return (
    <Table.Root>
      <Table.Grid.Root loading={false}>
        <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
        {table.rows.map((row, index) => (
          <Table.Grid.Row key={index + row.id} row={row} />
        ))}
      </Table.Grid.Root>
      <Table.Pagination
        count={count}
        onPaginationChange={({ pageSize, pageNumber }) =>
          setPagination({
            ...pagination,
            pageSize,
            pageNumber: pagination?.pageSize !== pageSize ? 1 : pageNumber,
          })
        }
        pagination={pagination}
        pageSizeOptions={[
          {
            label: "5 itens",
            value: 5,
          },
          {
            label: "10 itens",
            value: 10,
          },
          {
            label: "25 itens",
            value: 25,
          },
        ]}
      />
    </Table.Root>
  );
};
