import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 6px;
`;

export const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const DeliveryBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 34px;
  border-radius: 12px;
  border: 1px solid var(--color-neutral-90);
`;
