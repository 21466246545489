import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  EmptyState,
  FlexContainer,
  HorizontalBarChart,
  VerticalBarChart,
} from "@components/index";
import { ResultsInterface } from "./types";
import { Feed } from "./styles";
import { Comment } from "./Comment";

const Results = ({ questions }: ResultsInterface) => {
  if (!questions?.length)
    return (
      <FlexContainer width="100%" style={{ marginBottom: "26px" }}>
        <EmptyState
          title="Nenhum dado encontrado
para exibir esse gráfico."
          subTitle=""
        />
      </FlexContainer>
    );

  return (
    <FlexContainer flexDirection="column" gap={24} width="100%">
      {questions?.map((q) => {
        if (q?.answerFormat === "text" || q?.answerFormat === "date")
          return (
            <FlexContainer
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <Typography
                variant="headline8"
                variantColor="var(--color-neutral-30)"
                weight={700}
              >
                {q?.title}
              </Typography>
              <Feed key={q?.id}>
                {q?.answers?.map((c, i) => (
                  <Comment
                    key={i}
                    value={typeof c?.value === "string" ? c?.value : ""}
                    name={c?.employee?.name || "Anônimo"}
                    date={c?.date}
                    hideBorder={i === q?.answers?.length - 1}
                  />
                ))}
              </Feed>
            </FlexContainer>
          );
        if (
          ["emoji", "heart", "star", "scale"]?.includes(q?.answerFormat || "")
        )
          return (
            <VerticalBarChart
              title={q?.title || ""}
              data={
                q?.options
                  ?.filter((o) => o?.count)
                  ?.map((o) => ({
                    ...o,
                    name: o?.value || "",
                    value: o?.count,
                    label: o?.value || "",
                  })) || []
              }
            />
          );
        return (
          <HorizontalBarChart
            title={q?.title || ""}
            data={
              q?.options
                ?.filter((o) => o?.count)
                ?.map((o) => ({
                  ...o,
                  name: o?.value || "",
                  value: o?.count,
                  label: o?.value || "",
                })) || []
            }
          />
        );
      })}
    </FlexContainer>
  );
};

export default Results;
