import styled from "styled-components";

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const SkeletonHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const SkeletonCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;

export const SkeletonCard = styled.div`
  width: 280px;
  height: 340px;
  border-radius: 12px;
  overflow: hidden;
`;
