import {
  dayjs,
  Icons,
  Tag,
  Typography,
  TagProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import { TagsProps, TagModelProps, TagStatusProps } from "./types";
import { FlexContainer } from "@components/FlexContainer";

export default ({ model, status, recurrence }: TagsProps) => {
  const isRecurrence = !!!recurrence;
  const singleOrRecurring = isRecurrence ? "Envio recorrente" : "Envio único";

  const tagModelData: TagModelProps = {
    voluntaryExit: {
      label: "Envio contínuo",
      variant: "gray",
    },
    involuntaryExit: {
      label: "Envio contínuo",
      variant: "gray",
    },
    feeling: {
      label: "Envio recorrente",
      variant: "gray",
    },
    engaja: {
      label: "Envio único",
      variant: "gray",
    },
    climate: {
      label: singleOrRecurring,
      variant: "gray",
    },
    enps: {
      label: singleOrRecurring,
      variant: "gray",
    },
    custom: {
      label: singleOrRecurring,
      variant: "gray",
    },
  };

  const tagStatusData: {
    send: {
      label: string;
      variant: TagProps["variant"];
    };
    draft: {
      label: string;
      variant: TagProps["variant"];
    };
    scheduled: {
      label: string;
      variant: TagProps["variant"];
    };
  } = {
    send: {
      label: ["voluntaryExit", "involuntaryExit"]?.includes(model || "")
        ? "Ativo"
        : "Enviado",
      variant: "success",
    },
    draft: {
      label: "Rascunho",
      variant: "yellow",
    },
    scheduled: {
      label: "Agendado",
      variant: "blue",
    },
  };

  const tagStatus = status ? tagStatusData[status] : undefined;
  const tagModel = model ? tagModelData[model] : undefined;

  return (
    <FlexContainer gap={8}>
      {tagModel ? (
        <Tag variant={tagModel?.variant}>{tagModel?.label}</Tag>
      ) : null}
      {tagStatus ? (
        <Tag variant={tagStatus?.variant}>{tagStatus?.label}</Tag>
      ) : null}
    </FlexContainer>
  );
};
