import { TextEditor } from "@components/TextEditor";
import { Typography } from "@components/Typography";
import { Divider } from "@components/v2";
import { TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Box } from "../../../../common/components";
import {
  ErrorAsterisk,
  FlexBetween,
  SectionContainer,
  SectionSubContainer,
  TextAreaContainer,
} from "../styles";
import { StepInfoInterface } from "../types";
import { StyledText } from "@utils";

const SectionName = ({ disabled, formik }: StepInfoInterface) => {
  const { name, message, title } = formik?.values;

  return (
    <Box>
      <SectionContainer>
        <SectionSubContainer>
          <Typography
            variant="headline7"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            Nome e mensagem
          </Typography>
          <Divider spacing="quark" />
          <Typography
            variant="body4"
            color="var(--color-neutral-50)"
            fontWeight={400}
          >
            Dê um nome para identificar a comunicação, um título e escreva a
            mensagem.
          </Typography>
        </SectionSubContainer>
        <div style={{ display: "flex" }}>
          <Typography
            variant="body4"
            color="var(--color-neutral-50)"
            fontWeight={400}
          >
            <ErrorAsterisk>*</ErrorAsterisk> campo obrigatório
          </Typography>
        </div>
      </SectionContainer>
      <Divider spacing="s1" />

      <div>
        <TextField
          disabled={disabled}
          label="Nome de identificação da comunicação *"
          value={name || ""}
          fullWidth
          onChange={(e) =>
            e?.target?.value?.length < 51
              ? formik.handleChange({
                  target: {
                    id: "name",
                    value: e?.target?.value,
                  },
                })
              : null
          }
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && (formik.errors.name as string)}
        />
        <FlexBetween>
          <StyledText
            variant="caption"
            setColor="neutral50"
            children={`O nome ficará visível apenas para você na listagem da tabela. `}
            style={{ fontWeight: 600 }}
          />
          <StyledText
            variant="caption"
            setColor="neutral50"
            children={`${name?.length || 0}/50`}
            style={{ fontWeight: 600 }}
          />
        </FlexBetween>
      </div>

      <Divider spacing="xs2" />

      <div>
        <TextField
          disabled={disabled}
          label="Título da mensagem *"
          value={title || ""}
          fullWidth
          onChange={(e) =>
            formik.handleChange({
              target: {
                id: "title",
                value: e?.target?.value,
              },
            })
          }
          inputProps={{
            maxlength: 30,
          }}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && (formik.errors.title as string)}
        />
        <FlexBetween>
          <StyledText
            variant="caption"
            setColor="neutral50"
            children={`Exemplo: Papo com o CEO às 19h!`}
            style={{ fontWeight: 600 }}
          />
          <StyledText
            variant="caption"
            setColor="neutral50"
            children={`${title?.length}/30`}
            style={{ fontWeight: 600 }}
          />
        </FlexBetween>
      </div>

      <Divider spacing="xs2" />
      <TextAreaContainer disabled={disabled}>
        <TextEditor
          styles={{
            container: { width: "100%" },
            editor: { height: "200px" },
          }}
          value={message}
          placeholder="Mensagem *"
          onChange={(value) =>
            formik.handleChange({
              target: {
                id: "message",
                value: value,
              },
            })
          }
          maxLength={10000}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={
            formik.touched.message && (formik.errors.message as string)
          }
        />
      </TextAreaContainer>
    </Box>
  );
};

export { SectionName };
