import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

import { ChildrenContainer, BoxContainer, LabelStyle } from "./styled";
import { useEffect, useMemo, useState } from "react";

export type TGraphStackedBar = {
  data: { name: string; Total: number; monitoringCount: number }[];
  settings: {
    dataKey: string;
    amt: string;
    name: string;
    color: string;
  }[];
};

export const GraphStackedBar = ({ data, settings }: TGraphStackedBar) => {
  data = data?.filter((d) => d?.Total);

  const [width, setWidth] = useState(0);

  const renderCustomStyle = (props: any) => {
    const { x, y, width, value } = props;

    // 7.2 is average character length for this label in px
    const charactersLength = String(value).length * 7.2;

    return (
      <g>
        <foreignObject
          x={x + width / 2 - charactersLength / 2}
          y={y - 25}
          width={100}
          height={20}
        >
          <LabelStyle variant="caption">{value}</LabelStyle>
        </foreignObject>
      </g>
    );
  };

  const renderStyledLengend = (value: any) => (
    <LabelStyle
      tag="span"
      variant="body3"
      style={{ color: "#6B5B66", fontWeight: 600 }}
    >
      {value}
    </LabelStyle>
  );

  const CustomTooltip = ({ payload, active }: any) => {
    const columnName = payload?.[0]?.payload?.name;
    const payloadName = columnName;
    if (!["Não informado", "Não declarado"].includes(payloadName)) {
      payloadName;
    }

    const totalValue = data?.find(
      (item) => item.name === columnName
    )?.monitoringCount;

    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#1D161B",
            padding: " 12px 16px",
            borderRadius: "8px",
          }}
        >
          <p className="label" style={{ color: "#fff", fontWeight: 700 }}>
            {payloadName}
          </p>
          {payload?.map((pld: any, i: any) => {
            return (
              <p className="label" style={{ color: "#fff" }} key={i}>
                {`${pld?.name}: ${pld?.value}`} seleções
              </p>
            );
          })}
          <p className="label" style={{ color: "#fff" }}>
            {`Total: ${totalValue}`} respondente{totalValue === 1 ? "" : "s"}
          </p>
        </div>
      );
    }
    return null;
  };

  const height = useMemo(() => {
    const dataAmplitude = [...data].sort((a, b) => a.Total - b.Total)?.[
      data.length - 1
    ]?.Total;

    return dataAmplitude;
  }, [data]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = () => {
      setWidth(window.innerWidth * 0.6);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ChildrenContainer>
      <BoxContainer height={height + 350}>
        <BarChart
          data={data}
          height={height + 350}
          width={width}
          barSize={100}
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="name"
            {...(window?.innerWidth < 1280
              ? { angle: 30, textAnchor: "start" }
              : {})}
          />
          <YAxis hide={false} domain={[0, Math.ceil(height / 10) * 10]} />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            align="right"
            verticalAlign="middle"
            layout="vertical"
            iconType={"circle"}
            formatter={renderStyledLengend}
          />
          {settings?.map((s, i) => {
            return (
              <Bar key={i} dataKey={s?.name} stackId="a" fill={s?.color}>
                {i === settings.length - 1 && (
                  <LabelList
                    position="bottom"
                    valueAccessor={(entry: any) => entry.Total}
                    content={renderCustomStyle}
                  />
                )}
              </Bar>
            );
          })}
        </BarChart>
      </BoxContainer>
    </ChildrenContainer>
  );
};
