import { Icons, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, LabelContainer, QuestionContainer } from "./styles";
import { ModelQuestionsInterface } from "./types";

const ModelQuestions = ({ title, questions }: ModelQuestionsInterface) => {
  const formats = {
    text: {
      label: "Texto",
      icon: "IconCursorText",
    },
    date: {
      label: "Data",
      icon: "IconCalendar",
    },
    "multiple-choice": {
      label: "Múltipla escolha",
      icon: "IconCircleDot",
    },
    "select-box": {
      label: "Caixa de seleção",
      icon: "IconSquareCheck",
    },
    "multi-select": {
      label: "Seleção Multi-Tags",
      icon: "IconSquareCheck",
    },
    dropdown: {
      label: "Lista suspensa",
      icon: "IconArrowDownCircle",
    },
    emoji: {
      label: "Reação (Emoji)",
      icon: "IconMoodHappy",
    },
    heart: {
      label: "Reação (Corações)",
      icon: "IconHeart",
    },
    star: {
      label: "Reação (Estrelas)",
      icon: "IconStar",
    },
    scale: {
      label: "Escala numérica",
      icon: "IconNumbers",
    },
  };

  return (
    <Container>
      <Tag variant="gray">
        <Typography
          variant="caption"
          variantColor="var(--color-neutral-10)"
          weight={600}
        >
          {questions?.length} perguntas
        </Typography>
      </Tag>
      <Typography
        variant="headline8"
        variantColor="var(--color-neutral-30)"
        weight={700}
        style={{ marginBottom: "8px", border: "none" }}
      >
        {title}
      </Typography>
      <QuestionContainer>
        <Typography
          variant="headline9"
          variantColor="var(--color-neutral-30)"
          weight={700}
        >
          Perguntas
        </Typography>
        <Typography
          variant="headline9"
          variantColor="var(--color-neutral-30)"
          weight={700}
        >
          Respostas
        </Typography>
      </QuestionContainer>
      {questions?.map((q, i) => {
        const f = q?.answerFormat || "text";
        const icon = formats[f]?.icon;
        const label = formats[f]?.label;

        return (
          <QuestionContainer key={q?.id || i}>
            <Typography
              variant="body4"
              variantColor="var(--color-neutral-30)"
              weight={600}
            >
              {i + 1}. {q?.title}
            </Typography>
            <Tag variant="gray">
              <LabelContainer>
                <Icons size={16} name={icon as any} />
                <Typography
                  variant="caption"
                  variantColor="var(--color-neutral-10)"
                  weight={600}
                >
                  {label}
                </Typography>
              </LabelContainer>
            </Tag>
          </QuestionContainer>
        );
      })}
    </Container>
  );
};

export { ModelQuestions };
