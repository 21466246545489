import {
  Accordion,
  Button,
  Icons,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { SectionProps } from "./types";
import { DraftQuestion } from "src/Pages/v2/SurveyForm/screen/types";
import { Question } from "../index";
import {
  AbsoluteContainer,
  AccordionContainer,
  AccordionHeader,
  AccordionHeaderTitleContainer,
} from "./styles";
import { MenuIcon } from "@components/MenuIcon";
import { FlexContainer } from "@components/index";

const Section = ({
  formik,
  sectionIndex,
  section,
  onChange,
  onDelete,
  onEdit,
}: SectionProps) => {
  const getError = ({ questionIndex }: { questionIndex: number }) => {
    const sections = formik?.errors?.sections?.length
      ? formik?.errors?.sections
      : [];

    const questions =
      sections[sectionIndex] &&
      sections[sectionIndex]?.questions &&
      sections[sectionIndex]?.questions?.length
        ? sections[sectionIndex]?.questions
        : [];

    const error = questions[questionIndex];
    return error;
  };

  const addQuestion = () => {
    const newQuestion: DraftQuestion = {
      title: "",
      answerFormat: undefined,
      isENPS: false,
      justification: false,
      required: false,
    };

    onChange({
      ...section,
      questions: [...(section?.questions || []), newQuestion],
    });
  };

  return (
    <AccordionContainer>
      <Accordion
        variant="default"
        defaultExpanded
        style={{ borderColor: "#EBE6E9" }}
        customHeader={
          <AccordionHeader>
            <Tag variant="gray">
              <Typography variant="caption" variantColor="#1D161B" weight={600}>
                {section?.questions?.length || 0}{" "}
                {`pergunta${section?.questions?.length === 1 ? "" : "s"}`}
              </Typography>
            </Tag>
            <AbsoluteContainer>
              <MenuIcon
                options={[
                  {
                    icon: "IconPencil",
                    label: "Editar seção",
                    onClick: onEdit,
                  },
                  {
                    icon: "IconTrash",
                    label: "Excluir seção",
                    onClick: () => onDelete(sectionIndex),
                  },
                  {
                    icon: "IconPlus",
                    label: "Adicionar pergunta",
                    onClick: () => addQuestion(),
                  },
                ]}
              />
            </AbsoluteContainer>
            <AccordionHeaderTitleContainer>
              <Typography
                variant="headline8"
                variantColor="#53464F"
                weight={700}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "400px",
                }}
              >
                {section?.title}
              </Typography>
              <Typography
                variant="body4"
                variantColor="#83727D"
                weight={400}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "400px",
                }}
              >
                {section?.description}
              </Typography>
            </AccordionHeaderTitleContainer>
          </AccordionHeader>
        }
        children={
          <FlexContainer width="100%" gap={24} flexDirection="column">
            {section?.questions?.map((q, questionIndex) => (
              <Question
                draggableId={`s-${sectionIndex}-q-${questionIndex}`}
                questionIndex={questionIndex}
                key={questionIndex}
                question={q}
                onCopy={(newQuestion) =>
                  onChange({
                    ...section,
                    questions: [...(section?.questions || []), newQuestion],
                  })
                }
                onDelete={() =>
                  onChange({
                    ...section,
                    questions: section?.questions?.filter(
                      (_, i) => i !== questionIndex
                    ),
                  })
                }
                onChange={(updatedQuestion) =>
                  onChange({
                    ...section,
                    questions: section?.questions?.map((q, i) => {
                      if (i === questionIndex) return updatedQuestion;
                      return q;
                    }),
                  })
                }
                error={{
                  title: Boolean(getError({ questionIndex })?.title),
                  answerFormat: Boolean(
                    getError({
                      questionIndex,
                    })?.answerFormat
                  ),
                  options: getError({ questionIndex })?.options || [],
                  lowerLabel: Boolean(getError({ questionIndex })?.lowerLabel),
                  upperLabel: Boolean(getError({ questionIndex })?.upperLabel),
                  min: Boolean(getError({ questionIndex })?.scaleRange?.min),
                  max: Boolean(getError({ questionIndex })?.scaleRange?.max),
                }}
              />
            ))}
            <Button
              style={{ width: "100%" }}
              disabled={false}
              variant="secondary"
              size="medium"
              onClick={addQuestion}
            >
              <Icons name="IconPlus" color={"var(--color-primary)"} />
              Adicionar nova pergunta
            </Button>
          </FlexContainer>
        }
      />
    </AccordionContainer>
  );
};

export { Section };
