import { useEffect, useState } from "react";
import {
  dayjs,
  PageContainer,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { alert } from "@utils";
import { Footer, Header, StepInfo } from "../components";
import {
  Model,
  Info,
  Delivery,
  Config,
  Content,
  Employee,
  Review,
} from "../steps";
import { useViewModel } from "./useSurveyFormViewModel";
import { validationSchema } from "./validationSchema";
import { initialValues } from "./initialValues";
import { Container, FormContainer, PageTitleContainer } from "./styles";
import { convertSections, DraftSurvey, SurveyModel } from "./types";
import { SuccessModal } from "../components/SuccessModal";

const SurveyForm = () => {
  const { id = "" } = useParams();
  const {
    models,
    engagement,
    loading,
    employees,
    organizationalData,
    filters,
    engajaPDFUrl,
    setFilters,
    upsertSurvey,
    isModalOpen,
    successData,
  } = useViewModel(id);

  const [selectedModel, setSelectedModel] = useState<SurveyModel>();
  const [selectedSection, setSelectedSection] = useState<string>(
    selectedModel?.sections?.[0]?.id || ""
  );
  const [step, setStep] = useState<number>(0);
  const [selectedReviewStep, setSelectedReviewStep] = useState(0);
  const formik = useFormik<DraftSurvey>({
    initialValues: initialValues,
    validationSchema: validationSchema[step],
    onSubmit: (values) => upsertSurvey({ values }),
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (id?.length && step !== 1) setStep(1);
  }, [id]);

  useEffect(() => {
    if (selectedModel?.sections?.length) {
      setSelectedSection(selectedModel.sections[0]?.id || "");
    }
  }, [selectedModel]);

  useEffect(() => {
    if (engagement?._id && !formik?.values?._id) {
      let delivery = engagement?.recurrence ? "recurring" : "single";
      let everyoneWithAccess = true;

      if (engagement?.modelId) {
        const currentModel = models?.find(
          (m) => m?._id === engagement?.modelId
        );

        if (currentModel?.delivery?.length) {
          if (currentModel?.delivery?.includes("continuous")) {
            delivery = "continuous";
          } else if (
            currentModel?.delivery?.includes("recurring") &&
            engagement?.recurrence
          ) {
            delivery = "recurring";
          }
        }

        if (currentModel?._id) setSelectedModel(currentModel);
      }

      if (Array.isArray(engagement?.owners) && engagement?.owners?.length)
        if (engagement?.owners?.[0]?.id !== "*") everyoneWithAccess = false;

      formik?.setValues({
        ...engagement,
        termsAndConditions:
          engagement?.termsAndConditions || initialValues?.termsAndConditions,
        isDraft: true,
        delivery,
        everyoneWithAccess,
        selectedSectionIndex: 0,
        startDate: {
          date: engagement?.startDate?.date || initialValues?.startDate?.date,
          period:
            engagement?.startDate?.period || initialValues?.startDate?.period,
        },
        companyData: engagement?.companyData
          ? {
              economicSector: engagement?.companyData?.economicSector,
              employees: engagement?.companyData?.employees,
              size: engagement?.companyData?.size,
            }
          : initialValues?.companyData,
        recurrence: engagement?.recurrence
          ? {
              repeat:
                engagement?.recurrence?.repeat ||
                initialValues?.recurrence?.repeat,
              daysOfTheWeek: initialValues?.recurrence?.daysOfTheWeek?.map(
                ({ label, value }) => ({
                  label,
                  value,
                  checked:
                    engagement?.recurrence?.daysOfTheWeek?.includes(value),
                })
              ),
              employees:
                engagement?.recurrence?.employees ||
                initialValues?.recurrence?.employees,
              frequency:
                engagement?.recurrence?.frequency ||
                initialValues?.recurrence?.frequency,
              occurrenceLimit:
                typeof engagement?.recurrence?.occurrenceLimit === "number" &&
                !engagement?.recurrence?.endDate?.length
                  ? engagement?.recurrence?.occurrenceLimit
                  : engagement?.recurrence?.endDate?.length
                  ? undefined
                  : initialValues?.recurrence?.occurrenceLimit,
              endDate:
                engagement?.recurrence?.endDate ||
                initialValues?.recurrence?.endDate,
              interactionDeadlineConfig: {
                nextMode:
                  typeof engagement?.recurrence?.interactionDeadlineConfig
                    ?.nextMode === "boolean"
                    ? engagement?.recurrence?.interactionDeadlineConfig
                        ?.nextMode
                    : initialValues?.recurrence?.interactionDeadlineConfig
                        ?.nextMode,
                calculatedDate: {
                  multiplier:
                    engagement?.recurrence?.interactionDeadlineConfig
                      ?.calculatedDate?.multiplier ||
                    initialValues?.recurrence?.interactionDeadlineConfig
                      ?.calculatedDate?.multiplier,
                  period:
                    engagement?.recurrence?.interactionDeadlineConfig
                      ?.calculatedDate?.period ||
                    initialValues?.recurrence?.interactionDeadlineConfig
                      ?.calculatedDate?.period,
                },
              },
            }
          : initialValues?.recurrence,
      });
    }
  }, [engagement, models]);

  const updateFormWithModel = (model?: SurveyModel) => {
    const modelId = model?._id || null;

    if (modelId !== formik?.values?.modelId)
      formik?.setValues({
        ...(formik?.values || {}),
        modelId,
        delivery: model?.delivery?.includes("continuous")
          ? "continuous"
          : model?.delivery?.includes("recurring") &&
            !model?.delivery?.includes("single")
          ? "recurring"
          : "single",
        selectedSectionIndex: 0,
        anonymous: model?.anonymous?.value || initialValues?.anonymous,
        notifications: {
          email:
            model?.notification?.email?.value ||
            initialValues?.notifications?.email,
          push:
            model?.notification?.push?.value ||
            initialValues?.notifications?.push,
          reminder: {
            enabled:
              model?.notification?.reminder?.value ||
              initialValues?.notifications?.reminder?.enabled,
            minRate:
              model?.notification?.reminder?.minRate ||
              initialValues?.notifications?.reminder?.minRate,
          },
        },
        sections: modelId
          ? model?.sections?.map((s) => ({
              referenceId: s?.id,
              title: s?.title?.value,
              description: s?.description?.value,
              questions: s?.questions?.map((q) => ({
                referenceId: q?.id,
                required: q?.required?.value,
                isENPS: q?.isENPS,
                title: q?.title?.value,
                answerFormat: q?.answerFormat?.value,
                justification: q?.justification?.value,
                options: q?.options?.map((o) => ({
                  referenceId: o?.id,
                  value: o?.label?.value,
                })),
                upperLabel: q?.upperLabel?.value,
                lowerLabel: q?.lowerLabel?.value,
                scaleRange: {
                  min: q?.scaleRange?.value?.min,
                  max: q?.scaleRange?.value?.max,
                },
              })),
            }))
          : initialValues?.sections,
      });
  };

  const steps = {
    0: (
      <Model
        surveyId={id}
        loading={loading?.getSurveyModels}
        models={models}
        onSelect={(m) => {
          updateFormWithModel(m);
          setSelectedModel(m);
          setStep(1);
        }}
      />
    ),
    1: (
      <Info formik={formik} model={selectedModel} engajaPDFUrl={engajaPDFUrl} />
    ),
    2: <Delivery formik={formik} model={selectedModel} />,
    3: <Config formik={formik} model={selectedModel} />,
    4: (
      <Content
        formik={formik}
        model={selectedModel}
        selectedSection={selectedSection}
        onSectionSelect={setSelectedSection}
      />
    ),
    5: (
      <Employee
        departments={organizationalData?.departments}
        groups={organizationalData?.groups}
        roles={organizationalData?.roles}
        formik={formik}
        employees={
          employees?.map((e) => ({
            id: e?.id,
            name: e?.name,
            corporateEmail: e?.corporateEmail || "",
          })) || []
        }
        filters={filters}
        setFilters={setFilters}
      />
    ),
    6: (
      <Review
        formik={formik}
        onStepClick={(step) => setStep(step)}
        selectedReviewStep={selectedReviewStep}
        onReviewStepSelect={setSelectedReviewStep}
        employees={employees}
        selectedModel={selectedModel}
      />
    ),
  };

  return (
    <>
      <Header
        step={step}
        type="survey"
        hiddenSteps={
          selectedModel?.delivery?.includes("continuous")
            ? ["Destinatários"]
            : []
        }
      />
      <Container id="survey-steps-container">
        <PageContainer>
          <PageTitleContainer>
            <Typography variant="headline6" weight={700} variantColor="#3B2E37">
              Criar Pesquisa
            </Typography>
          </PageTitleContainer>
          <FormContainer>
            {step === 0 ? null : (
              <StepInfo
                model={selectedModel?.name}
                anonymous={formik.values.anonymous}
                step={step}
                simplifiedSection={convertSections(selectedModel?.sections)}
                selectedSection={selectedSection}
                onSectionSelect={setSelectedSection}
                isReview={step === 6}
                selectedReviewStep={selectedReviewStep}
                onReviewStepSelect={setSelectedReviewStep}
                formik={formik}
              />
            )}
            <form style={{ width: "100%" }}>{steps[step]}</form>
          </FormContainer>
        </PageContainer>
      </Container>
      <SuccessModal
        open={isModalOpen}
        onClose={() => navigate("/engagement/survey")}
        isDraft={formik.values.isDraft}
        formik={formik}
        navigate={() => navigate("/engagement/survey")}
      />
      <Footer
        showNextButton={Boolean(engagement?._id)}
        loading={loading?.upsertSurvey}
        step={step}
        onDraftButtonClick={() => formik?.handleSubmit()}
        onNextButtonClick={async () => {
          const errors = await formik.validateForm();
          formik.setTouched(
            Object.keys(errors).reduce((acc, key) => {
              acc[key] = true;
              return acc;
            }, {} as any)
          );

          if (Object.keys(errors).length) {
            console.log("Existem erros no formulário:", errors);
            if (step === 4 && errors?.sections) {
              if (
                !Boolean(formik?.values?.sections?.length) ||
                formik?.values?.sections?.some(
                  (s) => !Boolean(s?.questions?.length)
                )
              )
                alert(
                  "Para continuar, adicione pelo menos uma seção e certifique-se de que cada seção tenha pelo menos uma questão."
                );
            }

            if (step === 3) {
              if (errors?.notifications)
                alert(
                  "Selecione pelo menos um tipo de notificação para avisar os destinatários sobre a pesquisa."
                );
              if (errors?.owners)
                alert(
                  "Escolha pelo menos um responsável para acessar e visualizar os dados da pesquisa."
                );
            }

            if (step === 5) {
              if (errors?.employees)
                alert(
                  formik?.values?.anonymous
                    ? "Selecione pelo menos 3 pessoas para continuar"
                    : "Selecione pelo menos uma pessoa para continuar"
                );
            }

            return;
          }

          if (selectedModel?.delivery?.includes("continuous")) {
            setStep(step === 4 ? step + 2 : step + 1);
          } else {
            setStep(step + 1);
          }
        }}
        onSubmitButtonClick={() => {
          formik.handleChange({
            target: {
              id: "isDraft",
              value: false,
            },
          });
          formik?.handleSubmit();
        }}
        onPreviousButtonClick={() => {
          if (selectedModel?.delivery?.includes("continuous")) {
            setStep(step === 6 ? step - 2 : step - 1);
          } else {
            setStep(step - 1);
          }
        }}
      />
    </>
  );
};

export default SurveyForm;
