import { DatesProps } from "./types";
import { Continous, Recurring, Single } from "./components";

export default ({
  delivery,
  createdAt,
  sendDate,
  lastSendDate,
  interactionDeadline,
  endDate,
  occurrenceLimit,
  id,
  status,
}: DatesProps) => {
  if (delivery === "continuous")
    return <Continous createdAt={createdAt} lastSendDate={lastSendDate} />;

  if (delivery === "recurring")
    return (
      <Recurring
        id={id}
        status={status}
        createdAt={createdAt}
        sendDate={sendDate}
        endDate={endDate}
        occurrenceLimit={occurrenceLimit}
      />
    );

  return (
    <Single
      createdAt={createdAt}
      sendDate={sendDate}
      interactionDeadline={interactionDeadline}
    />
  );
};
