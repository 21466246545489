import { dayjs, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { alert } from "@utils";
import { Container, DateContainer } from "../styles";
import { ButtonWithIcon } from "@components/index";
import { useEffect, useState } from "react";

export default ({
  createdAt,
  sendDate,
  endDate,
  occurrenceLimit,
  status,
  id,
}: {
  createdAt: string;
  sendDate?: string;
  endDate?: string;
  occurrenceLimit?: number;
  status: string;
  id: string;
}) => {
  const [currentStatus, setCurrentStatus] = useState(status);

  useEffect(() => {
    if (status) setCurrentStatus(status);
  }, [status]);

  const updateRecurrenceStatus = trpc?.updateRecurrenceStatus.useMutation({
    onSuccess: (e) => {
      if (e?.recurrence?.status) setCurrentStatus(e?.recurrence?.status);
      alert("Recorrência atualizada com sucesso.", "success");
    },
    onError: (e) => {
      console.log(e);
      alert("Erro ao atualizar recorrência, tente novamente mais tarde.");
    },
  });

  const loading = updateRecurrenceStatus?.isLoading;
  return (
    <Container>
      <DateContainer>
        <Icons name="IconCalendar" color="var(--color-secondary-50)" />
        <Typography
          variant="body4"
          weight={600}
          variantColor="var(--color-neutral-50)"
        >
          Criado em
        </Typography>
        <Typography
          variant="headline9"
          weight={700}
          variantColor="var(--color-neutral-30)"
        >
          {createdAt ? dayjs(createdAt)?.format("DD/MM/YYYY") : ""}
        </Typography>
      </DateContainer>
      {sendDate ? (
        <DateContainer>
          <Icons name="IconCalendar" color="var(--color-secondary-50)" />
          <Typography
            variant="body4"
            weight={600}
            variantColor="var(--color-neutral-50)"
          >
            Início em
          </Typography>
          <Typography
            variant="headline9"
            weight={700}
            variantColor="var(--color-neutral-30)"
          >
            {dayjs(sendDate)?.format("DD/MM/YYYY")}
          </Typography>
        </DateContainer>
      ) : null}
      <DateContainer>
        <Icons name="IconCalendar" color="var(--color-secondary-50)" />
        <Typography
          variant="body4"
          weight={600}
          variantColor="var(--color-neutral-50)"
        >
          Término em
        </Typography>
        <Typography
          variant="headline9"
          weight={700}
          variantColor="var(--color-neutral-30)"
        >
          {(() => {
            if (endDate) return dayjs(endDate)?.format("DD/MM/YYYY");
            if (occurrenceLimit === -1) return "Nunca";
            if (occurrenceLimit && occurrenceLimit > 0)
              return `Depois de ${occurrenceLimit} envios`;

            return "-";
          })()}
        </Typography>
      </DateContainer>
      <DateContainer>
        <Icons name="IconRefresh" color="var(--color-secondary-50)" />
        <Typography
          variant="body4"
          weight={600}
          variantColor="var(--color-neutral-50)"
        >
          Dados atualizados a cada hora
        </Typography>
      </DateContainer>
      {id &&
      currentStatus &&
      ["paused", "progress"]?.includes(currentStatus) ? (
        currentStatus !== "paused" ? (
          <DateContainer>
            <ButtonWithIcon
              loading={loading}
              icon={{
                name: "IconPlayerPause",
                position: "right",
                style: { fill: "transparent" },
              }}
              variant="secondary"
              variantType="error"
              size="medium"
              onClick={() => {
                updateRecurrenceStatus.mutate({ id, status: "paused" });
              }}
            >
              Pausar recorrência
            </ButtonWithIcon>
          </DateContainer>
        ) : (
          <DateContainer>
            <ButtonWithIcon
              loading={loading}
              icon={{
                name: "IconPlayerPlay",
                position: "right",
                style: { fill: "transparent" },
              }}
              variant="secondary"
              size="medium"
              onClick={() => {
                updateRecurrenceStatus.mutate({ id, status: "progress" });
              }}
            >
              Retomar recorrência
            </ButtonWithIcon>
          </DateContainer>
        )
      ) : null}
    </Container>
  );
};
