import { PageContainer, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { FlexContainer } from "@components/index";

const Loading = () => (
  <PageContainer style={{ marginTop: "24px" }}>
    <FlexContainer flexDirection="column" width="100%" gap={24}>
      <FlexContainer width="100%" gap={24}>
        <Skeleton variant="rounded" width="100%" height={130} />
        <Skeleton variant="rounded" width="100%" height={130} />
        <Skeleton variant="rounded" width="100%" height={130} />
        <Skeleton variant="rounded" width="100%" height={130} />
      </FlexContainer>
      <FlexContainer width="100%">
        <Skeleton variant="rounded" width="100%" height={100} />
      </FlexContainer>
      <FlexContainer width="100%">
        <Skeleton variant="rounded" width="100%" height={500} />
      </FlexContainer>
    </FlexContainer>
  </PageContainer>
);

export default Loading;
