import { InsightsProps } from "./types";
import { Single, Recurring, Continous } from "./components";

export default ({
  delivery,
  employeeCount = 0,
  recurringEmployeeCount = 0,
  questionCount = 0,
  viewRate = 0,
  answerRate = 0,
  sendCount = 0,
}: InsightsProps) => {
  if (delivery === "recurring")
    return (
      <Recurring
        recurringEmployeeCount={recurringEmployeeCount}
        questionCount={questionCount}
        viewRate={viewRate}
        answerRate={answerRate}
        sendCount={sendCount}
      />
    );
  if (delivery === "continuous")
    return (
      <Continous
        employeeCount={employeeCount}
        questionCount={questionCount}
        viewRate={viewRate}
        answerRate={answerRate}
      />
    );

  return (
    <Single
      employeeCount={employeeCount}
      questionCount={questionCount}
      viewRate={viewRate}
      answerRate={answerRate}
    />
  );
};
