import styled from "styled-components";

const AccordionContainer = styled.div`
  width: 100%;
`;

const AccordionHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
`;

const AccordionHeaderTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

const AbsoluteContainer = styled.div`
  height: 87px;
  position: absolute;
  top: 0px;
  right: -25px;
  display: flex;
  align-items: center;
  justify-content: right;
`;

export {
  AccordionContainer,
  AccordionHeader,
  AccordionHeaderTitleContainer,
  AbsoluteContainer,
};
