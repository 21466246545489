import styled from "styled-components";

const Feed = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 400px;
  width: 100%;
  overflow: scroll;
`;

export { Feed };
