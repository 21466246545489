import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const DataText = styled(Typography)`
  color: var(--color-neutral-dark5);
  font-weight: 600;
  margin: 5px 0;
`;

const DateContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > :first-child {
    max-width: 400px;
  }

  > div {
    margin: 0 0 0 10px;
  }
`;

const TableContainer = styled.div`
  .data-grid-table-header-container:last-child {
    min-width: 200px !important;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 100%;
  gap: 24px;
  padding-bottom: 24px;
`;

const PreviewAside = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;

  width: 50%;

  > :not(:last-child) {
    margin-bottom: 20px !important;
  }
`;

export {
  DateContainer,
  DataText,
  StatusContainer,
  TableContainer,
  PreviewContainer,
  PreviewAside,
};
