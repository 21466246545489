import { BoxAdornment, Radio } from "@flash-tecnologia/hros-web-ui-v2";
import { CounterField, FlexContainer, Typography } from "@components/index";
import { Row, StyleContainer, DeliveryBox } from "./styles";
import { Divider } from "@components/v2";
import { NotificationCardV2 } from "@components/NotificationCardV2";

const DeadLineSettings = ({ formik }: { formik: any }) => (
  <FlexContainer
    flexDirection="column"
    width="100%"
    gap={24}
    alignItems="flex-start"
  >
    <NotificationCardV2
      variant="outlined"
      type="info"
      title="Envio da pesquisa através da seleção dos participantes"
      subtitle="Para enviar esta pesquisa aos colaboradores vá em Detalhe da pesquisa > Adicionar participante."
      iconName="IconAlertTriangle"
    />
    <StyleContainer>
      <DeliveryBox>
        <Typography
          variant="headline8"
          color="var(--color-neutral-30)"
          fontWeight={700}
        >
          Período de prazo de respostas
        </Typography>

        <Divider spacing="quark" />

        <Typography
          variant="body4"
          color="var(--color-neutral-50)"
          fontWeight={400}
        >
          Configure até quando a pesquisa deve receber respostas. Após o limite,
          a pesquisa será fechada e novas respostas não serão aceitas.
        </Typography>

        <Divider spacing="s1" />

        <BoxAdornment
          style={{ width: "100%" }}
          description={
            <Typography
              variant="body3"
              color="var(--color-neutral-30)"
              fontWeight={600}
            >
              Sempre disponível.
            </Typography>
          }
          leftAdornment={
            <Radio
              disabled={false}
              checked={!Boolean(formik?.values?.interactionDeadlineDays)}
              onChange={() => {
                formik.handleChange({
                  target: {
                    id: "interactionDeadlineDays",
                    value: 0,
                  },
                });
              }}
            />
          }
        />

        <Divider spacing="xs1" />

        <BoxAdornment
          style={{ width: "100%" }}
          description={
            <Row>
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
              >
                Até
              </Typography>
              <CounterField
                disabled={false}
                value={formik?.values?.interactionDeadlineDays || 1}
                min={1}
                onChange={(e) => {
                  formik.handleChange({
                    target: {
                      id: "interactionDeadlineDays",
                      value: e,
                    },
                  });
                }}
              />
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
              >
                dias após a data de cada envio.
              </Typography>
            </Row>
          }
          leftAdornment={
            <Radio
              checked={Boolean(formik?.values?.interactionDeadlineDays)}
              onChange={() => {
                formik.handleChange({
                  target: {
                    id: "interactionDeadlineDays",
                    value: 1,
                  },
                });
              }}
            />
          }
        />
      </DeliveryBox>
    </StyleContainer>
  </FlexContainer>
);

export { DeadLineSettings };
