import React from "react";
import { Divider } from "@components/v2";
import { Icons, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { LockedContentProps } from "./types";
import {
  Container,
  LabelContainer,
  QuestionContainer,
  QuestionsHeader,
  SectionContainer,
} from "./styles";

const answerFormats = {
  text: {
    label: "Texto",
    icon: "IconCursorText",
  },
  date: {
    label: "Data",
    icon: "IconCalendar",
  },
  "multiple-choice": {
    label: "Múltipla escolha",
    icon: "IconCircleDot",
  },
  "select-box": {
    label: "Caixa de seleção",
    icon: "IconSquareCheck",
  },
  "multi-select": {
    label: "Seleção Multi-Tags",
    icon: "IconSquareCheck",
  },
  dropdown: {
    label: "Lista suspensa",
    icon: "IconArrowDownCircle",
  },
  emoji: {
    label: "Reação (Emoji)",
    icon: "IconMoodHappy",
  },
  heart: {
    label: "Reação (Corações)",
    icon: "IconHeart",
  },
  star: {
    label: "Reação (Estrelas)",
    icon: "IconStar",
  },
  scale: {
    label: "Escala numérica",
    icon: "IconNumbers",
  },
};

const LockedContent: React.FC<LockedContentProps> = ({
  sections,
  selectedSection,
}) => {
  const displaySections = selectedSection
    ? sections?.filter((s) => s.id === selectedSection)
    : sections;

  return (
    <Container>
      {displaySections?.map((section) => (
        <SectionContainer key={section?.id}>
          <Tag variant="gray" style={{ marginBottom: "8px" }}>
            <Typography
              variant="caption"
              variantColor="var(--color-neutral-10)"
              weight={600}
            >
              {section?.questions?.length} perguntas
            </Typography>
          </Tag>

          <Typography
            variant="headline8"
            variantColor="var(--color-neutral-30)"
            weight={700}
            style={{ marginBottom: "4px" }}
          >
            {section?.title}
          </Typography>

          <Typography
            variant="body4"
            variantColor="var(--color-neutral-50)"
            weight={400}
            style={{ marginBottom: "12px" }}
          >
            {section?.description}
          </Typography>

          <QuestionsHeader>
            <Typography
              variant="headline9"
              variantColor="var(--color-neutral-30)"
              weight={700}
            >
              Perguntas
            </Typography>
            <Typography
              variant="headline9"
              variantColor="var(--color-neutral-30)"
              weight={700}
            >
              Respostas
            </Typography>
          </QuestionsHeader>
          {section?.questions?.map((question, index) => {
            const format =
              answerFormats[question.answerFormat] || answerFormats.text;

            return (
              <React.Fragment key={question.id || index}>
                <QuestionContainer>
                  <Typography
                    variant="body4"
                    variantColor="var(--color-neutral-30)"
                    weight={600}
                  >
                    {index + 1}. {question?.title}
                  </Typography>
                  <Tag variant="gray">
                    <LabelContainer>
                      <Icons size={16} name={format?.icon as any} />
                      <Typography
                        variant="caption"
                        variantColor="var(--color-neutral-10)"
                        weight={600}
                      >
                        {question?.isENPS ? "NPS" : format?.label}
                      </Typography>
                    </LabelContainer>
                  </Tag>
                </QuestionContainer>
                {index < section?.questions?.length - 1 && (
                  <Divider spacing="xxxs" />
                )}
              </React.Fragment>
            );
          })}
        </SectionContainer>
      ))}
    </Container>
  );
};

export default LockedContent;
