import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { TagsProps, TagModelProps, TagStatusProps } from "./types";
import { FlexContainer } from "@components/FlexContainer";

export default ({ status, delivery }: TagsProps) => {
  const tagModelData: TagModelProps = {
    continuous: {
      label: "Envio contínuo",
      variant: "gray",
    },
    single: {
      label: "Envio único",
      variant: "gray",
    },
    recurring: {
      label: "Envio recorrente",
      variant: "gray",
    },
  };

  const tagStatusData: TagStatusProps = {
    send: {
      label: delivery === "continuous" ? "Ativo" : "Enviado",
      variant: "success",
    },
    draft: {
      label: "Rascunho",
      variant: "yellow",
    },
    scheduled: {
      label: "Agendado",
      variant: "blue",
    },
    progress: {
      label: "Em andamento",
      variant: "success",
    },
    closed: {
      label: "Encerrado",
      variant: "gray",
    },
    paused: {
      label: "Pausado",
      variant: "yellow",
    },
  };

  const tagStatus = status ? tagStatusData[status] : undefined;
  const tagModel = delivery ? tagModelData[delivery] : undefined;

  return (
    <FlexContainer gap={8}>
      {tagModel ? (
        <Tag variant={tagModel?.variant}>{tagModel?.label}</Tag>
      ) : null}
      {tagStatus ? (
        <Tag variant={tagStatus?.variant}>{tagStatus?.label}</Tag>
      ) : null}
    </FlexContainer>
  );
};
