import { useState } from "react";
import { SurveyModel } from "../../screen/types";
import { BlankCard } from "../../components/BlankCard";
import { Card } from "../../components/Card";

import { StepContainer } from "../../components/StepContainer";
import { StepDescription } from "../../components/StepDescription";
import { CardsContainer } from "../../components/Card/styles";
import { ModelSkeleton } from "../../components/Skeleton";
import { useNavigate } from "react-router-dom";
import { CardInterface } from "../../components/Card/types";

interface ModelProps {
  models: SurveyModel[];
  onSelect: (model?: SurveyModel) => void;
  loading?: boolean;
  surveyId?: string;
}

const Model = ({ surveyId, models, onSelect, loading }: ModelProps) => {
  const navigate = useNavigate();
  const [openModels, setOpenModels] = useState<Record<string, boolean>>({});

  if (loading) return <ModelSkeleton />;

  return (
    <StepContainer direction="column">
      <StepDescription
        title="Galeria de Modelos de pesquisa"
        description="Crie uma pesquisa em branco ou utilize um de nossos modelos padrões baseados em metodologias do mercado."
        width="100%"
      />

      <CardsContainer>
        <BlankCard
          icon="IconPlus"
          model="custom"
          title="Criar pesquisa do zero"
          onUse={() => onSelect()}
        />

        {models?.map((model, i) => {
          const cardData: CardInterface = {
            loading: false,
            icon: (model?.icon || "IconPlus") as any,
            label:
              model?.link && !model?.link?.includes(`/form/${surveyId}`)
                ? "Acessar"
                : "Usar",
            title: model.name,
            modelTitle: model.name,
            subtitle: model.caption || "Nenhuma descrição disponível",
            showNewTag: model?.news || false,
            questionsCount: model.sections.reduce(
              (acc, section) => acc + section.questions.length,
              0
            ),
            duration: model.estimatedTimeInMinutes,
            modelDescription: model?.description,
            open: openModels[model._id] || false,
            onUse: () => {
              model?.link
                ? surveyId && model?.link?.includes(`/form/${surveyId}`)
                  ? onSelect(model)
                  : navigate(model?.link)
                : onSelect(model);
            },
            onDetail: () =>
              setOpenModels((prev) => ({
                ...prev,
                [model._id]: !prev[model._id],
              })),
          };

          return (
            <Card
              key={`card-${i}`}
              {...cardData}
              link={model?.link}
              modelDescription={model?.description}
            />
          );
        })}
      </CardsContainer>
    </StepContainer>
  );
};

export { Model };
