import { FeatureNotification } from "./types";
import surveyNotificationGif from "./files/gif.survey.gif";
import communicationNotificationGif from "./files/gif.communication.gif";
import surveyPermissionNotificationGif from "./files/gif_survey_permission_notification.gif";
import communicationPermissionNotificationGif from "./files/gif_communication_permission_notification.gif";
import surveySurveyEngajaGif from "./files/gif_survey_engaja.gif";
import surveySurveyFeelingGif from "./files/gif_survey_feeling.gif";
import surveySurveyExitImg from "./files/img_survey_exit.png";

const features: FeatureNotification[] = [
  {
    id: "survey-notification",
    title: "Aumente a taxa de resposta em suas pesquisas.",
    description:
      "Configure o envio de lembretes automáticos para os seus colaboradores e aumente a taxa de engajamento nas suas pesquisas e comunicados!",
    gifPath: surveyNotificationGif,
  },
  {
    id: "communication-notification",
    title: "Aumente o engajamento em seus comunicados.",
    description:
      "Configure lembretes automáticos para os seus colaboradores e adicione PDFs para aumentar o engajamento nos seus comunicados!",
    gifPath: communicationNotificationGif,
  },
  {
    id: "survey-permission-notification",
    title:
      "Personalize o nível de acesso dos administradores aos resultados das pesquisas",
    description:
      "Defina quem pode acessar os detalhes e as respostas das pesquisas internas e garanta que as informações sejam compartilhadas com as pessoas certas. Além disso, aproveite a facilidade de responder às pesquisas diretamente pelo aplicativo, permitindo que os colaboradores participem de maneira prática e rápida.",
    gifPath: surveyPermissionNotificationGif,
  },
  {
    id: "communication-permission-notification",
    title:
      "Personalize o nível de acesso dos administradores em seus comunicados",
    description:
      "Defina quem pode acessar os detalhes dos comunicados e garanta que as informações sejam compartilhadas com as pessoas certas. Além disso, aproveite a facilidade de responder pesquisas e visualizar comunicados diretamente pelo aplicativo.",
    gifPath: communicationPermissionNotificationGif,
  },
  {
    id: "survey-engaja-notification",
    title:
      "Descubra o nível de engajamento dos seus colaboradores e compare com a média do Brasil!",
    description:
      "Com o nosso novo modelo de pesquisa Engaja S/A, você mede o engajamento e eNPS da sua empresa, além de descobrir quais ações são valorizadas pelos colaboradores. Compare seus resultados com nosso índice nacional de engajamento 2024 e saiba como seus colaboradores estão se sentindo.",
    gifPath: surveySurveyEngajaGif,
  },
  {
    id: "survey-feeling-notification",
    title: "Descubra como seus colaboradores estão se sentindo em minutos!",
    description:
      "Com a Pesquisa de Sentimentos, você acompanha como foi a semana da sua equipe, o que está funcionando bem e o que pode melhorar de forma rápida e prática!",
    gifPath: surveySurveyFeelingGif,
  },
  {
    id: "survey-exit-notification",
    title:
      "Agilize a aplicação de pesquisas internas com nossos modelos prontos!",
    description:
      "Conheça os nossos novos modelos prontos de pesquisa eNPS, engajamento, desligamento e mais! Ganhe mais agilidade na aplicação, centralize suas análises e seja mais estratégico na gestão do clima organizacional da sua empresa.",
    gifPath: surveySurveyExitImg,
  },
];

export default features;
