import { BoxAdornment, Radio } from "@flash-tecnologia/hros-web-ui-v2";
import { Single, Recurring, Continuous } from "./components";
import { initialValues } from "../../screen/initialValues";
import { StyleContainer, DeliveryBox, Row } from "./styles";
import { Typography } from "@components/Typography";
import { Divider } from "@components/v2";
import { LineSeparator } from "@components/LineSeparator";
import { SurveyModel } from "../../screen/types";

interface DeliveryProps {
  formik: any;
  model?: SurveyModel;
}

const Delivery = ({ formik, model }: DeliveryProps) => {
  if (formik?.values?.delivery === "continuous")
    return <Continuous formik={formik} />;

  const modelDeliveryOptions: string[] = model?.delivery || [];

  const hasSingleOption = modelDeliveryOptions.length === 1;

  const hasMultipleOptions = modelDeliveryOptions.length > 1;

  return (
    <StyleContainer>
      <DeliveryBox>
        <Typography
          variant="headline7"
          color="var(--color-neutral-30)"
          fontWeight={700}
        >
          Tipo de Envio
        </Typography>
        <Divider spacing="quark" />
        <Typography
          variant="body4"
          color="var(--color-neutral-50)"
          fontWeight={400}
        >
          Escolha se o envio será realizado uma única vez ou configure a
          recorrência.
        </Typography>
        <Divider spacing="s1" />
        <Row>
          <BoxAdornment
            style={{ width: "100%" }}
            description={
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
              >
                Envio único
              </Typography>
            }
            leftAdornment={
              <Radio
                checked={
                  formik?.values?.delivery === "single" ||
                  (hasMultipleOptions &&
                    !formik?.values?.delivery &&
                    modelDeliveryOptions.includes("single"))
                }
                disabled={
                  hasSingleOption && !modelDeliveryOptions.includes("single")
                }
                onChange={() =>
                  formik.handleChange({
                    target: {
                      id: "delivery",
                      value: "single",
                    },
                  })
                }
              />
            }
          />

          <BoxAdornment
            style={{ width: "100%" }}
            description={
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
              >
                Envio recorrente
              </Typography>
            }
            leftAdornment={
              <Radio
                checked={
                  formik?.values?.delivery === "recurring" ||
                  (hasMultipleOptions &&
                    !formik?.values?.delivery &&
                    modelDeliveryOptions.includes("recurring"))
                }
                disabled={
                  hasSingleOption && !modelDeliveryOptions.includes("recurring")
                }
                onChange={() =>
                  formik.setValues({
                    ...formik.values,
                    delivery: "recurring",
                    recurrence: formik?.values?.recurrence
                      ? formik?.values?.recurrence
                      : initialValues["recurrence"],
                  })
                }
              />
            }
          />
        </Row>
        <Divider spacing="xs1" />
        <LineSeparator />
        <Divider spacing="xs1" />
        {formik?.values?.delivery === "single" ||
        (!formik?.values?.delivery &&
          modelDeliveryOptions.includes("single")) ? (
          <Single formik={formik} />
        ) : (
          <Recurring formik={formik} />
        )}
      </DeliveryBox>
    </StyleContainer>
  );
};

export { Delivery };
