import styled from "styled-components";

const QuestionContainer = styled.div<{ isDragging?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row;
  border: 1px solid ${({ isDragging }) => (isDragging ? "#FE67AF" : "#EBE6E9")};
  border-radius: 12px;
  background-color: #ffffff;
`;

const GripContainer = styled.div<{ isDragging?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  background-color: ${({ isDragging }) => (isDragging ? "#FE67AF" : "#f8f6f8")};
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-right: 1px solid
    ${({ isDragging }) => (isDragging ? "#FE67AF" : "#ebe6e9")};
`;

export { QuestionContainer, GripContainer };
