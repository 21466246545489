import { CardInterface } from "./types";
import {
  Container,
  ButtonsContainer,
  ContentContainer,
  SubtitleContainer,
  IconsWrapper,
  CardButton,
} from "./styles";
import {
  Icons,
  ShapeIcon,
  Spinner,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/Typography";
import { Divider } from "@components/v2";
import { FloatContent } from "./components";

const Card = ({
  loading,
  icon,
  title,
  subtitle,
  open,
  onUse,
  onDetail,
  disabled,
  modelTitle,
  modelDescription,
  showNewTag,
  innerCardProps,
  questionsCount,
  duration,
  link,
  label,
}: CardInterface) => (
  <Container>
    <FloatContent
      modelTitle={modelTitle}
      open={open}
      onClose={(e) => onDetail(e)}
      onClick={() => (disabled ? null : onUse(modelTitle))}
      modelDescription={modelDescription}
      innerCardProps={innerCardProps}
      disabled={disabled}
    />
    <ContentContainer>
      <IconsWrapper>
        <ShapeIcon
          name={icon}
          variant="default"
          size={48}
          color="var(--color-secondary-50)"
        />
        {showNewTag && (
          <Tag variant="primary" as="span">
            Novidade
          </Tag>
        )}
        {link?.includes("/form") && (
          <Tag variant="yellow" as="span">
            Rascunho
          </Tag>
        )}
        {link?.includes("/continuous") && (
          <Tag variant="green" as="span">
            Ativo
          </Tag>
        )}
      </IconsWrapper>
      <Divider spacing="xxs3" />
      <Typography
        variant="headline7"
        fontWeight={700}
        color="var(--color-neutral-30)"
      >
        {title}
      </Typography>
      <SubtitleContainer>
        <Typography
          variant="body3"
          fontWeight={400}
          color="var(--color-neutral-40)"
        >
          {subtitle}
        </Typography>
      </SubtitleContainer>
      {duration && questionsCount && (
        <Typography
          variant="body4"
          fontWeight={600}
          color="var(--color-neutral-40)"
          style={{ marginTop: "8px" }}
        >
          {questionsCount} pergunta{questionsCount > 1 ? "s" : ""} • Duração:{" "}
          {duration} min
        </Typography>
      )}
    </ContentContainer>
    <ButtonsContainer>
      <CardButton
        size="medium"
        variant="secondary"
        onClick={() => onDetail(!open)}
      >
        Detalhes
      </CardButton>
      <CardButton
        size="medium"
        variant="primary"
        disabled={disabled}
        onClick={() => (disabled ? null : onUse(modelTitle))}
      >
        {loading ? <Spinner variant="primary" size={24} /> : label || "Usar"}
      </CardButton>
    </ButtonsContainer>
  </Container>
);

export { Card };
