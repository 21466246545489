import { generateId } from "./generateId";
import { SectionInterface } from "../../survey/types";

const generateSurveyDataByModel = (
  model:
    | "feeling"
    | "engaja"
    | "climate"
    | "enps"
    | "custom"
    | "climate-with-enps"
) => {
  if (model === "custom") {
    const data: SectionInterface[] = [];
    return data;
  }

  if (model === "feeling") {
    const data: SectionInterface[] = [
      {
        id: generateId("section"),
        referenceId: "feeling_survey",
        title: "Pesquisa de sentimento",
        description: "",
        questions: [
          {
            id: generateId("question"),
            referenceId: "feeling_survey_feeling_this_week",
            answerFormat: "emoji",
            justification: false,
            lowerLabel: "Péssimo",
            upperLabel: "Ótimo",
            title: "Como você se sentiu essa semana?",
            required: true,
            scaleRange: {
              min: 1,
              max: 5,
            },
          },
          {
            id: generateId("question"),
            referenceId: "feeling_survey_best_description",
            answerFormat: "multiple-choice",
            justification: false,
            title:
              "Qual das opções abaixo melhor descreve como você se sentiu nesta semana?",
            required: true,
            options: [
              {
                id: generateId("option"),
                referenceId: "feeling_survey_best_description_confident",
                value: "Confiante",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_best_description_energized",
                value: "Energizado",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_best_description_happy",
                value: "Feliz",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_best_description_grateful",
                value: "Grato",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_best_description_anxious",
                value: "Ansioso",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_best_description_pressured",
                value: "Pressionado",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_best_description_stressed",
                value: "Estressado",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_best_description_exhausted",
                value: "Exausto",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_best_description_sad",
                value: "Triste",
              },
            ],
          },
          {
            id: generateId("question"),
            referenceId: "feeling_survey_what_was_good",
            answerFormat: "multi-select",
            justification: false,
            title: "O que foi bom?",
            required: false,
            options: [
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_was_good_issues_resolved",
                value: "Problemas resolvidos",
              },
              {
                id: generateId("option"),
                referenceId:
                  "feeling_survey_what_was_good_expectations_aligned",
                value: "Expectativas alinhadas",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_was_good_team_relationship",
                value: "Bom relacionamento com a equipe",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_was_good_leader_support",
                value: "Apoio do líder",
              },
              {
                id: generateId("option"),
                referenceId:
                  "feeling_survey_what_was_good_personal_satisfaction",
                value: "Satisfação na vida pessoal",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_was_good_goals_achieved",
                value: "Metas alcançadas",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_was_good_work_recognized",
                value: "Trabalho reconhecido",
              },
              {
                id: generateId("option"),
                referenceId:
                  "feeling_survey_what_was_good_development_opportunity",
                value: "Oportunidade de desenvolvimento",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_was_good_work_life_balance",
                value: "Equilíbrio entre trabalho e vida pessoal",
              },
            ],
          },
          {
            id: generateId("question"),
            referenceId: "feeling_survey_what_could_improve",
            answerFormat: "multi-select",
            justification: false,
            title: "O que poderia melhorar?",
            required: false,
            options: [
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_could_improve_productivity",
                value: "Produtividade",
              },
              {
                id: generateId("option"),
                referenceId:
                  "feeling_survey_what_could_improve_team_relationship",
                value: "Relacionamento com a equipe",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_could_improve_leader_support",
                value: "Apoio do líder",
              },
              {
                id: generateId("option"),
                referenceId:
                  "feeling_survey_what_could_improve_personal_issues",
                value: "Problemas pessoais",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_could_improve_task_overload",
                value: "Sobrecarga de tarefas",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_could_improve_deadlines",
                value: "Dificuldade com prazos",
              },

              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_could_improve_communication",
                value: "Problemas de comunicação",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_could_improve_autonomy",
                value: "Falta de autonomia",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_could_improve_recognition",
                value: "Falta de reconhecimento",
              },
              {
                id: generateId("option"),
                referenceId:
                  "feeling_survey_what_could_improve_priority_misalignment",
                value: "Desalinhamento de prioridades",
              },
              {
                id: generateId("option"),
                referenceId: "feeling_survey_what_could_improve_feedback",
                value: "Feedback do meu trabalho",
              },
              {
                id: generateId("option"),
                referenceId:
                  "feeling_survey_what_could_improve_planning_organization",
                value: "Falta de planejamento e/ou organização",
              },
            ],
          },
          {
            id: generateId("question"),
            referenceId: "feeling_survey_additional_comments",
            answerFormat: "text",
            justification: false,
            title: "Gostaria de deixar um comentário?",
            required: false,
          },
        ],
      },
    ];

    return data;
  }

  if (model === "enps") {
    const data: SectionInterface[] = [
      {
        id: generateId("section"),
        title: "Pesquisa eNPS",
        description: "",
        questions: [
          {
            id: generateId("question"),
            answerFormat: "scale",
            justification: false,
            lowerLabel: "Pouco provável",
            upperLabel: "Muito provável",
            title: "O quanto você recomendaria nossa empresa para trabalhar?",
            required: true,
            scaleRange: {
              min: 0,
              max: 10,
            },
          },
          {
            id: generateId("question"),
            answerFormat: "text",
            justification: false,
            title: "Justifique sua resposta",
            required: false,
          },
        ],
      },
    ];

    return data;
  }

  if (model === "climate-with-enps") {
    const data: SectionInterface[] = [
      {
        id: generateId("section"),
        title: "Pesquisa eNPS",
        description: "",
        questions: [
          {
            id: generateId("question"),
            answerFormat: "scale",
            justification: false,
            lowerLabel: "Pouco provável",
            upperLabel: "Muito provável",
            title:
              "Em uma escala de 0 a 10, qual é a probabilidade de você recomendar a empresa onde você trabalha para um amigo ou colega aplicar a uma vaga?",
            required: true,
            scaleRange: {
              min: 0,
              max: 10,
            },
          },
          {
            id: generateId("question"),
            answerFormat: "text",
            justification: false,
            title:
              "Sinta-se à vontade para trazer feedbacks adicionais no campo abaixo",
            required: false,
          },
        ],
      },
    ];

    return data;
  }

  if (model === "climate") {
    const data: SectionInterface[] = [
      {
        id: generateId("section"),
        title: "Ambiente de trabalho positivo",
        description: "",
        questions: [
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Pode-se contar com a colaboração das pessoas por aqui",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "A empresa proporciona um ambiente de troca de conhecimentos, melhores práticas e experiências entre os colaboradores e as áreas",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Os processos de trabalho que temos (por exemplo, definição de metas e prioridades, planejamento de projetos e verificações de qualidade) permitem que eu seja o mais produtivo possível",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Meus colegas de trabalho me tratam com respeito e parceria",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Meu relacionamento com colegas de trabalho aumenta minha probabilidade de permanecer nesta organização",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Temos um ambiente de trabalho aberto e que aceita as diferenças individuais (raça, gênero, orientação sexual, neurodiversidade, PCD, etc)",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "No trabalho, minhas opiniões e ideias são consideradas",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Recebo reconhecimento apropriado por minhas contribuições e realizações",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "O equilíbrio entre o meu trabalho e os compromissos pessoais está adequado para mim",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Sinto que minha carga de trabalho não me sobrecarrega",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        title: "Comunicação",
        description: "",
        questions: [
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "A empresa mantém os colaboradores informados sobre a situação dos negócios (planos, objetivos, resultados dos negócios, etc)",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "A empresa comunica de forma eficaz e frequente sua estratégia, suas prioridades atuais e seus objetivos",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Sabemos o que está acontecendo nas diferentes áreas aqui da empresa",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Tenho conhecimento sobre o planejamento da minha área e o considero eficaz",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Tenho visibilidade do que está acontecendo nas outras áreas da empresa que afeta diretamente o meu dia a dia de trabalho",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        title: "Desenvolvimento Profissional",
        description: "",
        questions: [
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Consigo me desenvolver e aprender na minha função atual",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Meu gestor me fornece feedback contínuo que me ajuda a melhorar meu desempenho",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Sei o que preciso fazer para crescer na companhia",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Acredito que minha última avaliação de desempenho reflete com precisão meu desempenho no trabalho",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        title: "Ferramentas de Trabalho",
        description: "",
        questions: [
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Os equipamentos de trabalho fornecidos são adequados ao nosso trabalho e à realidade da empresa",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Os sistemas de trabalho fornecidos são adequados ao nosso trabalho e à realidade da empresa",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        title: "Integração e potência de grupo",
        description: "",
        questions: [
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Existe colaboração para fazer um bom trabalho dentro do meu time",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Trocamos feedbacks de trabalho frequentes e construtivos dentro da minha equipe",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Trabalhamos como um time na minha equipe de trabalho",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        title: "Liderança",
        description: "",
        questions: [
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Acredito que meu líder coordena o time de forma adequada",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Considero que meu líder reconhece o bom trabalho e esforço adicional",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Me sinto confortável para expor minha opinião ao meu líder e sinto que minhas ideias são ouvidas e consideradas",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Me sinto respeitado pelo meu líder e confortável para ser quem eu sou",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Meu líder me dá autonomia para realizar minha função",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Sinto que meu líder me envolve na decisões que impactam minhas atividades e ambiente de trabalho",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Meu líder me dá clareza sobre o que é esperado de mim no trabalho",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Tenho suporte estrutural e operacional do meu líder no dia a dia",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Meu líder se preocupa com como pode ser um líder melhor para seus liderados",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Meu líder nos comunica uma visão clara de para onde devemos ir e como chegaremos lá",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        title: "Segurança psicológica",
        description: "",
        questions: [
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Considero este um lugar psicologicamente saudável para se trabalhar",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Na minha equipe, nos sentimos à vontade para expressar ideias e opiniões de forma construtiva",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        title: "Trabalho com significado",
        description: "",
        questions: [
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Minhas habilidades e competências se encaixam bem com as atividades que executo em meu dia a dia de trabalho",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Sinto orgulho quando vejo o que fazemos por aqui",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Sinto que nossos valores são vivenciados na prática",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Percebo o impacto do meu trabalho nos resultados da companhia",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
    ];

    return data;
  }

  if (model === "engaja") {
    const data: SectionInterface[] = [
      {
        id: generateId("section"),
        referenceId: "engaja_personal_information",
        title: "Informações Pessoais",
        description: `Informe seus dados pessoais abaixo para que possamos entender melhor o perfil dos participantes da pesquisa. Isso nos ajuda a fazer uma análise mais completa e útil para todos. Suas informações serão confidenciais e a pesquisa é anônima, garantindo que suas respostas não serão ligadas a você.`,
        questions: [
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_age",
            answerFormat: "text",
            title: "Idade",
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_gender",
            answerFormat: "multiple-choice",
            title: "Gênero",
            options: [
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_gender_female",
                value: "Feminino",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_gender_male",
                value: "Masculino",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_gender_other",
                value: "Eu não me identifico com os gêneros acima",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_race_identity",
            answerFormat: "multiple-choice",
            title: "Indique com qual raça você se identifica",
            options: [
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_race_identity_white",
                value: "Branco",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_race_identity_black",
                value: "Preto",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_race_identity_brown",
                value: "Pardo",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_race_identity_indigenous",
                value: "Indígena",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_race_identity_yellow",
                value: "Asiático/Amarelo",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_race_identity_prefer_not_to_say",
                value: "Prefiro não informar",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_education_level",
            answerFormat: "multiple-choice",
            title: "Selecione seu grau de escolaridade",
            options: [
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_education_level_no_education",
                value: "Sem estudos",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_education_level_incomplete_elementary",
                value: "Ensino Fundamental incompleto",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_education_level_complete_elementary",
                value: "Ensino Fundamental completo",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_education_level_incomplete_high_school",
                value: "Ensino Médio incompleto",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_education_level_complete_high_school",
                value: "Ensino Médio completo",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_education_level_incomplete_undergraduate",
                value: "Ensino superior incompleto (Graduação)",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_education_level_complete_undergraduate",
                value: "Ensino superior completo (Graduação)",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_education_level_specialization_mba_postgraduate",
                value:
                  "Especialização, MBA, Pós-Graduação, Mestrados (profissionais ou acadêmicos) e Doutorado (profissionais ou acadêmicos, Pós-doutorado)",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_monthly_income",
            answerFormat: "multiple-choice",
            title:
              "Qual a renda mensal bruta do seu domicílio? (Somando todas as rendas das pessoas que moram com você)",
            options: [
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_monthly_income_below_2_4k",
                value: "Menos que de R$ 2,4 mil",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_monthly_income_between2_41k_and_3_9k",
                value: "Entre R$ 2,41 mil e R$ 3,9 mil",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_monthly_income_between3_91k_and_7k",
                value: "Entre R$ 3,91 mil e R$ 7 mil",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_monthly_income_between7_01k_and_12k",
                value: "Entre R$ 7,01 mil e R$ 12 mil",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_monthly_income_between12_01k_and_26k",
                value: "Entre R$ 12,01 mil e R$ 26 mil",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_monthly_income_between26_1k_and_50k",
                value: "Entre R$ 26,1 mil e R$ 50 mil",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_monthly_income_between50,01k_and_100k",
                value: "Entre R$ 50,01 mil e R$ 100 mil",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_monthly_income_above_100k",
                value: "Superior a R$ 100 mil",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_monthly_income_prefer_not_to_answer",
                value: "Prefiro não responder",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_current_region",
            answerFormat: "multiple-choice",
            title: "Em qual região você mora atualmente?",
            options: [
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_current_region_north_region",
                value: "Norte",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_current_region_northeast_region",
                value: "Nordeste",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_current_region_midwest_region",
                value: "Centro-Oeste",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_current_region_southeast_region",
                value: "Sudeste",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_current_region_south_region",
                value: "Sul",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_time_in_current_company",
            answerFormat: "multiple-choice",
            title: "Há quanto tempo você trabalha na sua empresa atual?",
            options: [
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_time_in_current_company_less_than_1_year",
                value: "Menos que 1 ano",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_time_in_current_company_1_to_3_years",
                value: "Entre 1 e 3 anos",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_time_in_current_company_3_to_5_years",
                value: "Entre 3 e 5 anos",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_time_in_current_company_5_to_7_years",
                value: "Entre 5 e 7 anos",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_time_in_current_company_7_to_8_years",
                value: "Entre 7 e 8 anos",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_time_in_current_company_more_than_8_years",
                value: "Mais de 8 anos",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_state_of_residence",
            answerFormat: "dropdown",
            title: "Em qual estado você mora?",
            options: [
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_acre",
                value: "Acre - AC",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_alagoas",
                value: "Alagoas - AL",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_amapa",
                value: "Amapá - AP",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_amazonas",
                value: "Amazonas - AM",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_bahia",
                value: "Bahia - BA",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_ceara",
                value: "Ceará - CE",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_district_federal",
                value: "Distrito Federal - DF",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_espirito_santo",
                value: "Espírito Santo - ES",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_goias",
                value: "Goiás - GO",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_maranhao",
                value: "Maranhão - MA",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_mato_grosso",
                value: "Mato Grosso - MT",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_mato_grosso_do_sul",
                value: "Mato Grosso do Sul - MS",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_minas_gerais",
                value: "Minas Gerais - MG",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_para",
                value: "Pará - PA",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_paraiba",
                value: "Paraíba - PB",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_parana",
                value: "Paraná - PR",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_pernambuco",
                value: "Pernambuco - PE",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_piaui",
                value: "Piauí - PI",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_rio_de_janeiro",
                value: "Rio de Janeiro - RJ",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_rio_grande_do_norte",
                value: "Rio Grande do Norte - RN",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_rio_grande_do_sul",
                value: "Rio Grande do Sul - RS",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_rondonia",
                value: "Rondônia - RO",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_roraima",
                value: "Roraima - RR",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_santa_catarina",
                value: "Santa Catarina - SC",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_sao_paulo",
                value: "São Paulo - SP",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_sergipe",
                value: "Sergipe - SE",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_tocantins",
                value: "Tocantins - TO",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_state_of_residence_outside_brazil",
                value: "Fora do Brasil",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_disability",
            answerFormat: "multiple-choice",
            title:
              "Você possui algum tipo de deficiência (Ex.: deficiências física, visual, auditiva, intelectual, psicossocial etc.)?",
            options: [
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_disability_yes",
                value: "Sim",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_disability_no",
                value: "Não",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_business_owner",
            answerFormat: "multiple-choice",
            title:
              "Você é dono/proprietário da empresa? (Exceto Stock Options)",
            options: [
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_business_owner_yes",
                value: "Sim",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_business_owner_no",
                value: "Não",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_current_position",
            answerFormat: "multiple-choice",
            title: "Indique a posição que você ocupa na sua empresa atual.",
            options: [
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_current_position_trainee",
                value: "Estagiário, trainee, aprendiz.",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_current_position_collaborator",
                value:
                  "Colaborador: profissional ocupando as demais funções (Ex. assistente, analista, etc)",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_current_position_middle_management",
                value:
                  "Média gerência (gerenciam equipes): supervisor, gerente e coordenador",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_current_position_executive",
                value:
                  "Executivo: profissional que ocupa uma posição de alta liderança tais como C-Level e ou nível semeplante (CEO, presidente, Diretor Executivo, Sócio administrador, etc)",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_current_position_others",
                value: "Outros: consultor, freelancer, etc",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_work_model",
            answerFormat: "multiple-choice",
            title: "Indique seu modelo de trabalho.",
            options: [
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_work_model_on_site",
                value: "Presencial (vou ao escritório todos os dias úteis)",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_work_model_hybrid",
                value:
                  "Híbrido (vou ao escritório algumas vezes por semana ou mês)",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_work_model_remote",
                value:
                  "Remoto (trabalho de casa ou de qualquer lugar remotamente)",
              },
            ],
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_personal_information_contract_type",
            answerFormat: "multiple-choice",
            title: "Qual o seu modelo de contrato de trabalho?",
            options: [
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_contract_type_clt",
                value: "CLT",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_contract_type_pj_or_mei",
                value: "PJ ou MEI",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_contract_type_statutory",
                value: "Estatutário",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_contract_type_cooperative",
                value: "Cooperado",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_contract_type_intern",
                value: "Estagiário",
              },
              {
                id: generateId("option"),
                referenceId:
                  "engaja_personal_information_contract_type_freelancer",
                value: "Autônomo",
              },
              {
                id: generateId("option"),
                referenceId: "engaja_personal_information_contract_type_other",
                value: "Outro",
              },
            ],
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_job_meaning_pillars",
        title: "Atributos: Significado do Trabalho",
        description:
          "O trabalho tem significado, as pessoas têm as ferramentas e a autonomia para obter sucesso e a empresa e seus líderes selecionam as pessoas certas para o trabalho certo. Avalie seu nível de concordância com essas características na empresa em que você está trabalhando atualmente, considerando como você se sente no seu dia a dia.",
        questions: [
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_pillars_autonomy",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Eu tenho liberdade para realizar meu trabalho que tem a minha marca/do meu jeito.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_pillars_fit_with_profile",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Minhas habilidades e competências se encaixam bem com meu trabalho OU Eu me identifico com o meu trabalho.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_pillars_empowered_small_teams",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Atuo em equipes pequenas e empoderadas (com poder de decisão).",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_pillars_creative_free_time",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Tenho tempo suficiente para me dedicar a projetos pessoais, mantendo um bom equilíbrio entre trabalho e vida pessoal.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_pillars_impact",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Estou contribuindo para algo que impacta a minha empresa ou a sociedade.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_good_management_practices_pillars",
        title: "Atributos: Boas Práticas de Gestão",
        description:
          "Diz respeito às atividades diárias, semanais e mensais que os gestores usam para orientar, apoiar, desenvolver e alinhar suas equipes. Avalie seu nível de concordância com essas características na empresa em que você está trabalhando atualmente, considerando como você se sente no seu dia a dia.",
        questions: [
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_pillars_clear_transparent_goals",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Meus objetivos estão claros, simples e transparentes.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_pillars_work_processes",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Os processos de trabalho que temos (por exemplo, definição de metas e prioridades, planejamento de projetos e verificações de qualidade) permitem que eu seja o mais produtivo possível.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_pillars_coaching_feedback_culture",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Meu gestor me fornece feedback contínuo que me ajuda a melhorar meu desempenho.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_pillars_feeling_valued",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "A gestão investe no meu desenvolvimento profissional.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_pillars_objective_performance_evaluation",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Acredito que minha última avaliação de desempenho reflete com precisão meu desempenho no trabalho.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_positive_work_environment_pillars",
        title: "Atributos: Ambiente de trabalho positivo",
        description:
          "A necessidade de construir condições de trabalho flexíveis, solidárias (colaborativas), humanas e inclusivas. Avalie seu nível de concordância com essas características da empresa em que você está trabalhando atualmente, considerando como você se sente no seu dia a dia.",
        questions: [
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_pillars_work_flexibility",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Tenho flexibilidade geográfica (de local) e temporal (de horário) para a minha modalidade de trabalho.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_pillars_work_life_balance",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "O equilíbrio entre o meu trabalho e os compromissos pessoais é adequado para mim.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_positive_work_environment_pillars_recognition",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Recebo reconhecimento apropriado ou elogio (além de meu salário e benefícios) por minhas contribuições e realizações.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_pillars_opinions_considered",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "No trabalho, minhas opiniões e ideias parecem ser levadas em consideração.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_pillars_diversity_equity_inclusion",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Temos um ambiente de trabalho aberto e que aceita as diferenças individuais (raça, gênero, orientação sexual, neurodiversidade, PCD, etc).",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_pillars_relationships",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Meu relacionamento com colegas de trabalho aumenta minha probabilidade de permanecer nesta organização.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_pillars_colleagues_respect",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Meus colegas de trabalho me tratam com respeito e camaradagem.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_pillars_neurodiversity_accommodation",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Eu sinto que meu trabalho acomoda minhas necessidades relacionadas as minhas condições neurológicas.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_pillars_mental_health",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Sinto que meu trabalho promove e apoia minha saúde mental.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_growth_opportunity_pillars",
        title: "Atributos: Oportunidade de crescimento",
        description:
          "Ter a sensação de que vamos progredir em nossa função ou carreira escolhida. Avalie seu nível de concordância com essas características da empresa em que você está trabalhando atualmente, considerando como você se sente no seu dia a dia.",
        questions: [
          {
            id: generateId("question"),
            referenceId: "engaja_growth_opportunity_pillars_training_support",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Estou realizando meu potencial no trabalho.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_growth_opportunity_pillars_internal_mobility",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Existe um plano de carreira para mim e está claro.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_growth_opportunity_pillars_training_development",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "A empresa investe no meu desenvolvimento.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_growth_opportunity_pillars_learning_culture",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "A empresa tem uma postura que apoia a aprendizagem contínua.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_trust_in_top_leadership_pillars",
        title: "Atributos: Confiança na Alta Liderança",
        description:
          "Práticas de liderança capazes de estabelecer e comunicar visão, propósito e transparência dentro e fora da organização. Avalie seu nível de concordância com essas características da empresa em que você está trabalhando atualmente, considerando como você se sente no seu dia a dia.",
        questions: [
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_pillars_sense_of_purpose",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "A missão ou propósito da minha empresa me faz sentir que meu trabalho é importante.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_pillars_continuous_investment",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "A empresa pratica investimento contínuo nos colaboradores.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_pillars_leadership_transparency",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "A liderança faz o que prega e é transparente e coerente.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_pillars_sense_of_inspiration",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Eu admiro a liderança da empresa.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_pillars_trust_in_leadership",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "Eu confio nas decisões tomadas pela liderança.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_compensation_and_benefits_pillars",
        title: "Atributos: Remuneração e benefícios",
        description:
          "Prestações recebidas em decorrência da prestação de serviços, sendo esta remuneração paga em utilidades ou dinheiro. Avalie seu nível de concordância com essas características da empresa em que você está trabalhando atualmente, considerando como você se sente no seu dia a dia.",
        questions: [
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_pillars_fair_compensation",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "O meu salário é adequado à minha posição.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_pillars_market_compensation",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title: "O meu salário é adequado à média do mercado.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_pillars_performance_based_compensation",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Acredito que os valores pagos em bônus e participação nos lucros são justos e os critérios para concedê-los são claros.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_pillars_health_benefits",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Estou satisfeito com meus benefícios ligados à saúde, como plano de saúde, plano odontológico, auxílio academia, auxílio à saúde mental, etc.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_pillars_financial_benefits",
            answerFormat: "scale",
            lowerLabel: "Discordo totalmente",
            upperLabel: "Concordo totalmente",
            title:
              "Estou satisfeito com meus benefícios financeiros, como auxílio combustível, vale refeição e alimentação, auxílio creche/babá, etc.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_job_meaning_actions",
        title: "Ações: Significado do Trabalho",
        description: `Avalie de 1 a 5 o quão importante você considera que sua empresa implemente as seguintes ações para aumentar seu engajamento em relação ao 'significado do trabalho'. Se sua empresa já realiza alguma dessas ações, avalie o quanto elas impactam seu engajamento atualmente. Dentro dos exemplos abaixo, indique qual deles tem o maior impacto na sua experiência.`,
        questions: [
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_actions_autonomy_projects",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Projetos de Autonomia relacionados à Empresa: iniciativas além do cargo que oferecem liderança em projetos para que os colaboradores exerçam autonomia e tomem decisões significativas.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_actions_inspiring_stories",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "A empresa regularmente compartilha histórias inspiradoras e conquistas dos colaboradores através da intranet ou e-mails internos, aumentando meu engajamento e senso de pertencimento.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_actions_self_managed_teams",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Equipes autogerenciadas: projetos em que os colaboradores podem exercer autonomia e tomar decisões significativas.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_actions_social_impact_programs",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Programas de Impacto Social/ Voluntariado: implementação de programas que permitam trabalhar em iniciativas que tenham um impacto social positivo.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_actions_idea_programs",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Programas de ideias: contribuições individuais ou coletivas para melhorias na empresa.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_job_meaning_actions_personal_projects_time",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Tempo para Projetos Pessoais: iniciativas de redução de jornada sem diminuição do salário.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_good_management_practices_actions",
        title: "Ações: Boas Práticas de Gestão",
        description: `Avalie de 1 a 5 o quão importante você considera que sua empresa implemente as seguintes ações para aumentar seu engajamento em relação ao 'boas práticas de gestão'. Se sua empresa já realiza alguma dessas ações, avalie o quanto elas impactam seu engajamento atualmente. Dentro dos exemplos abaixo, indique qual deles tem o maior impacto na sua experiência.`,
        questions: [
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_actions_continuous_feedback_program",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Programa de Feedback Contínuo: sistema de feedback estruturado, regular e bidirecional entre gestores e equipes, como cultura da organização.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_actions_coaching_program",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Programa de Coaching: sistema que permita apoio, troca de experiências e conhecimentos entre diferentes níveis hierárquicos, como cultura da organização.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_actions_psychologically_prepared_leaders",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Líderes preparados psicologicamente, com tempo e vontade de auxiliar e desenvolver a equipe.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_actions_personal_professional_development",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Desenvolvimento pessoal e profissional: oferecer capacitação contínua para os profissionais.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_actions_performance_evaluations",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Avaliações de Desempenho: avaliações de desempenho focadas em desenvolvimento contínuo, não apenas no atingimento de metas e resultados.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_good_management_practices_actions_improvement_suggestion_system",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Sistema de Sugestões de Melhoria: criação de um sistema em que os colaboradores podem propor melhorias e são reconhecidos por suas contribuições.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_positive_work_environment_actions",
        title: "Ações: Ambiente de trabalho positivo",
        description: `Avalie de 1 a 5 o quão importante você considera que sua empresa implemente as seguintes ações para aumentar seu engajamento em relação ao 'ambiente de trabalho positivo'. Se sua empresa já realiza alguma dessas ações, avalie o quanto elas impactam seu engajamento atualmente. Dentro dos exemplos abaixo, indique qual deles tem o maior impacto na sua experiência.`,
        questions: [
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_actions_team_building_activities",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Atividades de Team Building: organização de atividades em grupo, com viés de conexão, para aumentar a conexão e os laços entre times.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_actions_in_person_events",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Eventos presenciais: realização de eventos presenciais (show de calouros, festa junina, festa de Halloween, entre outros) para incentivar o fortalecimento de relacionamento entre os colaboradores.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_actions_flexible_hours",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Horário flexível: incentivar a autonomia dos funcionários, permitindo que eles definam os horários de início e fim do seu expediente, baseando o seu desempenho em entregas e não horas trabalhadas.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_actions_short_friday",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Short Friday: incentivar o equilíbrio entre vida pessoal e profissional, permitindo que o colaborador possa encerrar o expediente mais cedo às sextas-feiras.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_actions_remote_hybrid_work_model",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Modelo remoto ou híbrido de trabalho: oferta do modelo de trabalho remoto para os colaboradores, que podem trabalhar de onde quiserem.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_actions_birthday_day_off",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Day off de aniversário: conceder um dia de folga para o funcionário em ocasião do seu aniversário.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_positive_work_environment_actions_birthday_achievement_celebrations",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Atividades de Celebração de Conquistas: organização de momentos em grupo, com viés de reconhecimento, para aumentar a valorização dos profissionais (individualmente ou em grupo) que atingem metas significativas.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_growth_opportunity_actions",
        title: "Ações: Oportunidade de Crescimento",
        description: `Avalie de 1 a 5 o quão importante você considera que sua empresa implemente as seguintes ações para aumentar seu engajamento em relação ao 'oportunidade de crescimento'. Se sua empresa já realiza alguma dessas ações, avalie o quanto elas impactam seu engajamento atualmente. Dentro dos exemplos abaixo, indique qual deles tem o maior impacto na sua experiência.`,
        questions: [
          {
            id: generateId("question"),
            referenceId:
              "engaja_growth_opportunity_actions_internal_recruitment",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Recrutamento interno: identificação e seleção de colaboradores com as habilidades necessárias para ocupar vagas abertas na empresa, com a criação de canais internos para divulgação de vagas e priorização do recrutamento interno antes de abertura para o mercado.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_growth_opportunity_actions_career_plan",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Plano de Carreira: desenvolvimento de um plano de carreira que alinha as aspirações dos colaboradores com os objetivos da empresa.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_growth_opportunity_actions_education_scholarships",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Bolsas de Estudo e Subsídios Educacionais: suporte financeiro para cursos e formações que contribuam para o crescimento profissional dos colaboradores.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_growth_opportunity_actions_sabbatical_program",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Programa de sábatico com licença não remunerada ou remunerada, mantendo plano de saúde.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_growth_opportunity_actions_training_development",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Treinamentos e capacitações: realização de treinamentos periódicos com consultores internos ou externos para o desenvolvimento de competências emocionais ou técnicas.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_growth_opportunity_actions_corporate_university",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Universidade corporativa: criação de estruturas ou plataformas com trilhas de aprendizagem com conteúdos relacionados ao desenvolvimento de competências emocionais e/ou técnicas visando a formação contínua dos colaboradores.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_trust_in_top_leadership_actions",
        title: "Ações: Confiança na Alta Liderança",
        description: `Avalie de 1 a 5 o quão importante você considera que sua empresa implemente as seguintes ações para aumentar seu engajamento em relação ao 'confiança na alta liderança'. Se sua empresa já realiza alguma dessas ações, avalie o quanto elas impactam seu engajamento atualmente. Dentro dos exemplos abaixo, indique qual deles tem o maior impacto na sua experiência.`,
        questions: [
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_actions_coffee_with_executives",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Cafés com a diretoria: organização de reuniões entre pequenos grupos de colaboradores com o C-Level da empresa em que há a possibilidade de fazer perguntas e ter uma proximidade com a alta liderança.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId: "engaja_trust_in_top_leadership_actions_pulse_surveys",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Pesquisas pulse: realização de pesquisas periódicas, em momentos estratégicos, para calibrar a percepção dos colaboradores (sobre modelos de trabalho, benefícios, implementação de novas práticas, processos de mudança).",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_actions_company_purpose_awareness",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Conscientização sobre o propósito da companhia: ações de definição de um propósito claro para a companhia e cascateamento de como ele se conecta com todas as áreas e cargos da organização.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_actions_reverse_mentorship",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Mentoria Reversa: implementação de um programa em que os colaboradores mais jovens ou menos experientes aconselham os líderes mais experientes.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_actions_strategic_committee_participation",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Participação em comitês estratégicos: permitir que colaboradores de diferentes níveis participem de comitês ou grupos para tomada de decisões, como comitê de PLR, comitê para revisão de código de ética, entre outros.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_actions_results_meetings",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Reuniões de resultado: realizar periodicamente encontros abertos para todos os colaboradores com a oportunidade de realizar questionamentos e tirar dúvidas.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_trust_in_top_leadership_actions_mentorship_program",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Mentoria: implementação de um programa em que os colaboradores mais jovens ou menos experientes são mentorados pelos líderes mais experientes.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_compensation_and_benefits_actions",
        title: "Ações: Remuneração e benefícios",
        description: `Avalie de 1 a 5 o quão importante você considera que sua empresa implemente as seguintes ações para aumentar seu engajamento em relação ao 'remuneração e benefícios'. Se sua empresa já realiza alguma dessas ações, avalie o quanto elas impactam seu engajamento atualmente. Dentro dos exemplos abaixo, indique qual deles tem o maior impacto na sua experiência.`,
        questions: [
          {
            id: generateId("question"),
            referenceId: "engaja_compensation_and_benefits_actions_gym_subsidy",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Subsídio para academia: oferecer descontos em aplicativos ou redes de academias para incentivar a prática de esportes e atividades físicas entre os colaboradores.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_actions_parental_benefits",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Benefícios parentais: oferta de benefícios como licença-paternidade e maternidade estendida, auxílio-creche ou babá, jornadas reduzidas entre outros benefícios para colaboradores com filhos.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_actions_pharmacy_assistance",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Auxílio-farmácia: descontos ou subsídios para a compra de medicamentos.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_actions_flexible_benefits",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Benefícios Flexíveis: criar pacotes de benefícios personalizados ou conceder um cartão com múltiplas categorias de benefícios para que os colaboradores possam utilizar de acordo com as suas necessidades.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_actions_assisted_reproduction_benefits",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Benefícios de reprodução assistida (para mulheres e casais homoafetivos).",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_actions_private_pension",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Previdência privada: acesso a planos para o investimento de previdência privada, com taxas e condições diferenciadas. Ainda é possível que a empresa realize contribuições ou complemente os planos dos colaboradores.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_actions_discounted_products_services",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "Acesso a produtos e/ou serviços da empresa com desconto e/ou forma de pagamento diferenciada.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
          {
            id: generateId("question"),
            referenceId:
              "engaja_compensation_and_benefits_actions_profit_sharing",
            answerFormat: "scale",
            lowerLabel: "Nenhum impacto",
            upperLabel: "Impacto muito alto",
            title:
              "PLR (Planos de Participação nos Lucros): oferecer um plano em que os colaboradores recebam uma parte dos lucros, com base no desempenho da empresa, com critérios claros para essa remuneração.",
            scaleRange: {
              min: 1,
              max: 5,
            },
            required: true,
            justification: false,
          },
        ],
      },
      {
        id: generateId("section"),
        referenceId: "engaja_enps",
        title: "eNPS",
        description: "",
        questions: [
          {
            id: generateId("question"),
            referenceId: "engaja_enps_recommendation_probability_scale",
            answerFormat: "scale",
            justification: false,
            lowerLabel: "Pouco provável",
            upperLabel: "Muito provável",
            title:
              "Em uma escala de 0 a 10, qual é a probabilidade de você recomendar a empresa onde você trabalha para um amigo ou colega aplicar a uma vaga?",
            required: true,
            scaleRange: {
              min: 0,
              max: 10,
            },
          },
        ],
      },
    ];

    return data;
  }

  return [];
};

export { generateSurveyDataByModel };
