import { LineChart, StackedChart } from "./components";
import { RecurrenceAnalysisTabInterface } from "./types";
import { Container } from "./styles";

const RecurrenceAnalysis = ({
  chart1,
  chart2,
  chart3,
  chart4,
}: RecurrenceAnalysisTabInterface) => (
  <Container>
    <LineChart {...chart1} />
    <StackedChart {...chart2} />
    <StackedChart {...chart3} />
    <StackedChart {...chart4} />
  </Container>
);

export { RecurrenceAnalysis };
