import { SelectField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { FlexContainer } from "@components/index";
import { ResultsInterface } from "./types";
import { Performance, Results, ENPS } from "./components";

const ResultsTab = ({
  data,
  analysisConfig,
  resultType,
  onChangeResultType,
}: ResultsInterface) => {
  const options: {
    label: string;
    value: "performance" | "questions" | "enps";
  }[] = analysisConfig
    ? []
    : [
        {
          label: "Perguntas",
          value: "questions",
        },
      ];

  if (analysisConfig?.performance || analysisConfig?.extremeAverages)
    options?.push({
      label: "Desempenho",
      value: "performance",
    });

  if (analysisConfig?.averagePerQuestion)
    options?.push({
      label: "Perguntas",
      value: "questions",
    });

  if (analysisConfig?.ENPS)
    options?.push({
      label: "eNPS",
      value: "enps",
    });

  resultType = resultType || options[0]?.value;

  return (
    <FlexContainer flexDirection="column">
      {options?.length > 1 ? (
        <FlexContainer
          width="100%"
          style={{ marginBottom: "24px" }}
          border="1px solid var(--color-neutral-90)"
          padding={16}
          radius={12}
        >
          <FlexContainer justifyContent="flex-start" gap={8} width="100%">
            <SelectField
              label="Selecionar tipo de resultado"
              fullWidth
              style={{ maxWidth: "320px" }}
              options={options}
              onSelectChange={(_: any, option) => {
                const value = option?.value;
                if (value) onChangeResultType(value);
              }}
              value={resultType}
            />
          </FlexContainer>

          {/* <FlexContainer justifyContent="flex-end" gap={12}>
                  <Typography
                    variant="body4"
                    weight={600}
                    variantColor="var(--color-neutral-40)"
                  >
                    Exibir
                  </Typography>
                  <SelectField
                    label="Selecionar tipo de resultado"
                    style={{ maxWidth: "280px" }}
                    fullWidth
                    options={[
                      {
                        label: "Desempenho",
                        value: "performance",
                      },
                      {
                        label: "Perguntas",
                        value: "questions",
                      },
                      {
                        label: "eNPS",
                        value: "enps",
                      },
                    ]}
                    onSelectChange={(_: any, option) => {
                      console.log(option);
                    }}
                    value={"performance"}
                  />
                </FlexContainer> */}
        </FlexContainer>
      ) : null}

      <FlexContainer width="100%">
        {resultType === "questions" ? (
          <Results questions={data?.filter((q) => !Boolean(q?.enps)) || []} />
        ) : resultType === "enps" ? (
          <ENPS questions={data?.filter((q) => Boolean(q?.enps)) || []} />
        ) : (
          <Performance
            questions={data?.filter((q) => !Boolean(q?.enps)) || []}
          />
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default ResultsTab;
