import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
`;

export const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 16px;
  padding-bottom: 32px;

  .delivery-field {
    width: 100% !important;
  }
`;

export const DeliveryBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
`;
