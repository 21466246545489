import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import { formatNumberToTwoDecimals } from "@utils";
import { BorderIcon, FlexContainer } from "@components/index";
import { EmployeesModal, EmptyState } from "./components";
import { PerformanceInterface } from "./types";
import Table from "@components/v2/Table";

const Performance = ({ questions }: PerformanceInterface) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [employees, setEmployees] = useState<
    {
      name: string;
      answer: string;
      date: string;
    }[]
  >([]);
  const selectedQuestions: any = questions
    ?.filter((q) =>
      ["emoji", "heart", "star", "scale"]?.includes(q?.answerFormat || "")
    )
    ?.map((q) => ({
      ...q,
      rate:
        q?.answers?.reduce((a, c) => a + (c?.value ? Number(c?.value) : 0), 0) /
          q?.answers?.length || 0,
    }));

  const data1 = selectedQuestions?.filter((q) => q?.rate <= 2 && q?.rate) || [];
  const data2 = selectedQuestions?.filter((q) => q?.rate >= 4) || [];

  useEffect(() => {
    if (employees?.length) setIsOpen(true);
  }, [employees]);

  return (
    <FlexContainer width="100%" gap={24}>
      <EmployeesModal
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        employees={employees}
      />
      <FlexContainer
        width="100%"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap={24}
      >
        <FlexContainer
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
          width="50%"
          gap={8}
        >
          <Typography
            variant="body3"
            weight={700}
            variantColor="var(--color-feedback-error-40)"
          >
            Foco e atenção (média ≤ 2)
          </Typography>
          <Typography
            variant="body4"
            weight={400}
            variantColor="var(--color-neutral-50)"
          >
            Identifica as perguntas com as médias mais baixas que precisam de
            atenção, acionamento das áreas responsáveis e, possivelmente,
            medidas corretivas.
          </Typography>
          <div style={{ maxWidth: "100%", width: "100%" }}>
            {data1?.length ? (
              <Table
                columns={[
                  {
                    header: "Pergunta",
                    accessorKey: "title",
                    sticky: "left",
                    cell: (e) => <div>{e?.row?.original?.title}</div>,
                  },
                  {
                    header: "Média",
                    accessorKey: "answers",
                    minSize: 100,
                    cell: (e) => (
                      <div>
                        {`${formatNumberToTwoDecimals(
                          e?.row?.original?.answers?.reduce(
                            (a, c) => a + (c?.value ? Number(c?.value) : 0),
                            0
                          ) / e?.row?.original?.answers?.length || 0
                        )}`}
                      </div>
                    ),
                  },
                  {
                    header: "Dimensão",
                    accessorKey: "answers",
                    minSize: 100,
                    cell: (e) => (
                      <div>{e?.row?.original?.section?.title || ""}</div>
                    ),
                  },
                  {
                    header: "Detalhe",
                    accessorKey: "id",
                    minSize: 100,
                    cell: ({ row }) => (
                      <div>
                        <BorderIcon
                          name="IconArrowRight"
                          onClick={() =>
                            setEmployees(
                              row?.original?.answers?.map((answer) => ({
                                name: answer?.employee?.name || "Anônimo",
                                answer: answer?.value,
                                date: answer?.date,
                              })) || []
                            )
                          }
                        />
                      </div>
                    ),
                  },
                ]}
                data={selectedQuestions?.filter((q) => q?.rate <= 2) || []}
              />
            ) : (
              <EmptyState />
            )}
          </div>
        </FlexContainer>
        <FlexContainer
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
          width="50%"
          gap={8}
        >
          <Typography
            variant="body3"
            weight={700}
            variantColor="var(--color-feedback-success-40)"
          >
            Reconhecimento (média ≥ 4)
          </Typography>
          <Typography
            variant="body4"
            weight={400}
            variantColor="var(--color-neutral-50)"
          >
            Identifica as perguntas com médias acima de 4 que merecem
            reconhecimento, para celebrar os resultados e promover boas
            práticas.
          </Typography>
          <div style={{ maxWidth: "100%", width: "100%" }}>
            {data2?.length ? (
              <Table
                columns={[
                  {
                    header: "Pergunta",
                    accessorKey: "title",
                    sticky: "left",
                    cell: (e) => <div>{e?.row?.original?.title}</div>,
                  },
                  {
                    header: "Média",
                    accessorKey: "answers",
                    minSize: 100,
                    cell: (e) => (
                      <div>
                        {`${formatNumberToTwoDecimals(
                          e?.row?.original?.answers?.reduce(
                            (a, c) => a + (c?.value ? Number(c?.value) : 0),
                            0
                          ) / e?.row?.original?.answers?.length || 0
                        )}`}
                      </div>
                    ),
                  },
                  {
                    header: "Dimensão",
                    accessorKey: "answers",
                    minSize: 100,
                    cell: (e) => (
                      <div>{e?.row?.original?.section?.title || ""}</div>
                    ),
                  },
                  {
                    header: "Detalhe",
                    accessorKey: "id",
                    minSize: 100,
                    cell: ({ row }) => (
                      <div>
                        <BorderIcon
                          name="IconArrowRight"
                          onClick={() =>
                            setEmployees(
                              row?.original?.answers?.map((answer) => ({
                                name: answer?.employee?.name || "Anônimo",
                                answer: answer?.value,
                                date: answer?.date,
                              })) || []
                            )
                          }
                        />
                      </div>
                    ),
                  },
                ]}
                data={selectedQuestions?.filter((q) => q?.rate >= 4) || []}
              />
            ) : (
              <EmptyState />
            )}
          </div>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Performance;
