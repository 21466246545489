import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { ButtonsContainer, Container } from "./styles";
import { FooterProps } from "./types";

const Footer = ({
  exitWithoutSaveButton,
  goBackButton,
  continueButton,
}: FooterProps) => (
  <Container>
    <ButtonsContainer>
      <LinkButton
        variant="neutral"
        disabled={exitWithoutSaveButton?.disabled || false}
        onClick={exitWithoutSaveButton?.onClick}
      >
        {exitWithoutSaveButton?.label || "Sair sem salvar"}
      </LinkButton>
      {/* <LinkButton
        variant="neutral"
        disabled={saveDraftAndExitButton?.disabled || false}
        onClick={saveDraftAndExitButton?.onClick}
      >
        {saveDraftAndExitButton?.label || "Sair e salvar rascunho"}
      </LinkButton> */}
    </ButtonsContainer>
    <ButtonsContainer>
      {goBackButton?.disabled ? null : (
        <Button
          variant="secondary"
          size="large"
          minWidth="200px"
          loading={goBackButton?.loading || false}
          disabled={goBackButton?.disabled || false}
          onClick={goBackButton?.onClick}
        >
          {goBackButton?.label || "Voltar"}
        </Button>
      )}
      <Button
        variant="primary"
        size="large"
        minWidth="200px"
        loading={continueButton?.loading || false}
        disabled={continueButton?.disabled || false}
        onClick={continueButton?.onClick}
      >
        {continueButton?.label || "Continuar"}
      </Button>
    </ButtonsContainer>
  </Container>
);

export { Footer };
