import { TextField, SelectField } from "@flash-tecnologia/hros-web-ui-v2";
import { LineSeparator, Typography } from "@components/index";
import { Divider } from "@components/v2";
import { Container, FieldRow, SelectContainer } from "./styles";
import { DraftQuestion } from "src/Pages/v2/SurveyForm/screen/types";

interface NumericScaleProps {
  question: DraftQuestion;
  onChange: (question: DraftQuestion) => void;
  error: {
    lowerLabel?: boolean;
    upperLabel?: boolean;
    min?: boolean;
    max?: boolean;
  };
  isNPS?: boolean;
}

const NumericScale = ({
  question,
  onChange,
  error,
  isNPS = false,
}: NumericScaleProps) => {
  const disabledOption = (
    type: "min" | "max",
    optionValue: number | undefined
  ) => {
    if (typeof optionValue !== "number") return false;

    if (type === "min" && typeof question?.scaleRange?.max === "number") {
      if (optionValue >= question?.scaleRange?.max) return true;
      return false;
    }

    if (type === "max" && typeof question?.scaleRange?.min === "number") {
      if (optionValue <= question?.scaleRange?.min) return true;
      return false;
    }

    return false;
  };

  const selectOptions = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
  ];

  return (
    <Container>
      {question?.answerFormat === "scale" ? (
        <>
          <FieldRow>
            <Typography
              variant="body3"
              variantColor="var(--color-neutral-30)"
              weight={600}
            >
              Escala entre
            </Typography>
            <Divider spacing="xs1" type="horizontal" />
            <SelectContainer>
              <SelectField
                error={error?.min}
                label="Mínimo"
                fullWidth
                disabled={isNPS}
                options={selectOptions.map((option) => ({
                  ...option,
                  disabled: isNPS
                    ? option.value !== 0
                    : disabledOption("min", option.value),
                }))}
                onSelectChange={
                  isNPS
                    ? undefined
                    : (_: any, option) => {
                        onChange({
                          ...question,
                          scaleRange: {
                            ...(question?.scaleRange || {}),
                            min: option?.value,
                          },
                        });
                      }
                }
                value={isNPS ? 0 : question?.scaleRange?.min}
              />
            </SelectContainer>
            <>
              <Divider spacing="xs1" type="horizontal" />
              <Typography
                variant="body3"
                variantColor="var(--color-neutral-30)"
                weight={600}
              >
                e
              </Typography>
              <Divider spacing="xs1" type="horizontal" />
            </>
            <SelectContainer>
              <SelectField
                error={error?.max}
                label="Máximo"
                fullWidth
                disabled={isNPS}
                options={selectOptions.map((option) => ({
                  ...option,
                  disabled: isNPS
                    ? option.value !== 10
                    : disabledOption("max", option.value),
                }))}
                onSelectChange={
                  isNPS
                    ? undefined
                    : (_: any, option) => {
                        onChange({
                          ...question,
                          scaleRange: {
                            ...(question?.scaleRange || {}),
                            max: option?.value,
                          },
                        });
                      }
                }
                value={isNPS ? 10 : question?.scaleRange?.max}
              />
            </SelectContainer>
          </FieldRow>
          <Divider spacing="xs1" />
          <LineSeparator />
          <Divider spacing="xs1" />
        </>
      ) : null}
      <FieldRow>
        <Typography
          variant="headline9"
          variantColor="var(--color-neutral-30)"
          weight={700}
        >
          Configurar legendas
        </Typography>
      </FieldRow>
      <Divider spacing="xs1" />
      <FieldRow>
        <div style={{ width: "100%" }}>
          <TextField
            error={error?.lowerLabel}
            disabled={false}
            value={question?.lowerLabel || ""}
            className="scale-label"
            label="Legenda de menor valor"
            helperText="Escreva o título com a nota mais negativa. Exemplo: Péssimo"
            type="text"
            onChange={(e) => {
              onChange({
                ...question,
                lowerLabel: e?.target?.value || "",
              });
            }}
          />
        </div>
        <Divider spacing="xs1" type="horizontal" />
        <div style={{ width: "100%" }}>
          <TextField
            error={error?.upperLabel}
            disabled={false}
            value={question?.upperLabel || ""}
            className="scale-label"
            label="Legenda de maior valor"
            helperText="Escreva o título com a nota mais positiva. Exemplo: Ótimo"
            type="text"
            onChange={(e) => {
              onChange({
                ...question,
                upperLabel: e?.target?.value || "",
              });
            }}
          />
        </div>
      </FieldRow>
    </Container>
  );
};

export { NumericScale };
