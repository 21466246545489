import { useParams } from "react-router-dom";
import { trpc } from "@api/client";
import { SurveyDetailViewModelProps } from "./types";

const useViewModel = ({ start, end }: SurveyDetailViewModelProps) => {
  const { id = "" } = useParams();

  const { data: getSurveyAnalysisResult, isLoading: getSurveyAnalysisLoading } =
    trpc?.getSurveyAnalysis?.useQuery(
      {
        // surveyId: "cWfGngs27uOiY04ZxLImq",
        // surveyId: "5b_q8ql4OKlUGlu1iimPw",
        // surveyId: "z2GrJIC8z4MOTT4dj3aBI",
        surveyId: id,
        filters: {
          dateRange: {
            start,
            end,
          },
        },
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: unknown) => {
          console.log(error);
        },
        enabled: true,
      }
    );

  const countInitialValues = {
    recipientCount: 0,
    answerCount: 0,
    viewCount: 0,
    shipmentCount: getSurveyAnalysisResult?.surveysAnalysis?.length,
  };

  const count =
    getSurveyAnalysisResult?.surveysAnalysis
      ?.filter((s) => s?.sendAt)
      ?.reduce(
        (a, c) => ({
          ...a,
          recipientCount: a?.recipientCount + c?.recipientCount || 0,
          answerCount: a?.answerCount + c?.answerCount || 0,
          viewCount: a?.viewCount + c?.viewCount || 0,
        }),
        countInitialValues
      ) || countInitialValues;

  const generalAnalysis = {
    ...count,
    answerRate: (count?.answerCount / (count?.recipientCount || 0)) * 100,
    viewRate: (count?.viewCount / (count?.recipientCount || 0)) * 100,
    lastShipment: getSurveyAnalysisResult?.surveysAnalysis?.length
      ? getSurveyAnalysisResult?.surveysAnalysis[
          getSurveyAnalysisResult?.surveysAnalysis?.length - 1
        ]
      : null,
  };

  return {
    isLoading: getSurveyAnalysisLoading,
    selectedSurvey: getSurveyAnalysisResult?.selectedSurvey,
    surveyAnalyses: getSurveyAnalysisResult?.surveysAnalysis || [],
    mergedQuestions: getSurveyAnalysisResult?.mergedQuestions || [],
    generalAnalysis,
  };
};

export { useViewModel };
