import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  dayjs,
  Icons,
  LinkButton,
  PageContainer,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { FlexContainer } from "@components/index";
import { Insights, Dates, Tags, Tabs, Loading } from "../components";
import { HeaderContainer } from "./styles";
import { useViewModel } from "./useSurveyDetailViewModel";

const SurveyDetailView = () => {
  const navigate = useNavigate();
  const today = dayjs()?.add(1, "days");
  const [period, setPeriod] = useState<number>(0);
  const [startDateFilter, setStartDateFilter] = useState<string>("");
  const [resultType, setResultType] = useState<
    "performance" | "questions" | "enps"
  >("performance");

  const {
    selectedSurvey,
    surveyAnalyses,
    generalAnalysis,
    mergedQuestions,
    isLoading,
  } = useViewModel({
    start: startDateFilter,
    end: today?.format("YYYY-MM-DD"),
  });

  const answers: any = surveyAnalyses?.reduce(
    (a, c): any => [...a, ...(c?.answers || [])],
    []
  );

  const employees: any = surveyAnalyses?.reduce(
    (a, c): any => [...a, ...(c?.employees || [])],
    []
  );

  if (isLoading) return <Loading />;

  return (
    <PageContainer style={{ marginTop: "24px" }}>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" />}
        breadcrumbs={[
          <LinkButton variant="neutral" href="/engagement/survey">
            Gerenciar pesquisas
          </LinkButton>,
          <Link to={`/engagement/v2/survey/${selectedSurvey?.engagement_id}`}>
            Detalhe da pesquisa
          </Link>,
        ]}
      />
      <HeaderContainer>
        <FlexContainer justifyContent="flex-start" gap={8}>
          <Typography
            variant="headline6"
            variantColor="var(--color-neutral-20)"
            weight={700}
          >
            {selectedSurvey?.engagement_name}
          </Typography>
          <Tags model={selectedSurvey?.model} status={selectedSurvey?.status} />
        </FlexContainer>
        <FlexContainer justifyContent="flex-end" gap={24}>
          <SelectField
            style={{ minWidth: "210px" }}
            fullWidth
            disabled={false}
            label="Selecione o período"
            options={[
              {
                label: "Últimas 24 horas",
                value: 1,
              },
              {
                label: "Últimos 7 dias",
                value: 7,
              },
              {
                label: "Últimos 15 dias",
                value: 15,
              },
              {
                label: "Últimos 30 dias",
                value: 30,
              },
              {
                label: "Últimos 3 meses",
                value: 90,
              },
              {
                label: "Últimos 6 meses",
                value: 182,
              },
              {
                label: "Últimos 12 meses",
                value: 365,
              },
              {
                label: "Todas as datas",
                value: 0,
              },
            ]}
            onSelectChange={(_: any, option) => {
              const value = option?.value;
              if (typeof value === "number") {
                setStartDateFilter(
                  value
                    ? today?.subtract(value, "day")?.format("YYYY-MM-DD")
                    : ""
                );
                setPeriod(value);
              }
            }}
            value={period}
          />
          {/* <MenuIcon
            options={[
              {
                icon: "IconX",
                label: "",
                disabled: false,
                onClick: (e) => console.log(e),
              },
              {
                icon: "IconX",
                label: "",
                disabled: false,
                onClick: (e) => console.log(e),
              },
            ]}
          /> */}
          <div>
            <Button
              variant="primary"
              size="large"
              minWidth="252px"
              onClick={() =>
                navigate(
                  `/engagement/survey/${selectedSurvey?.model}/${selectedSurvey?.engagement_id}/recipients`
                )
              }
            >
              Adicionar destinatários
            </Button>
          </div>
        </FlexContainer>
      </HeaderContainer>
      <Insights
        recipients={generalAnalysis?.recipientCount}
        answers={generalAnalysis?.answerCount}
        viewRate={generalAnalysis?.viewRate}
        answerRate={generalAnalysis?.answerRate}
      />
      <Dates
        createdAt={selectedSurvey?.created_at || ""}
        // sendDate={survey?.sendDate}
        // interactionDeadline={survey?.interactionDeadline}
        lastSendDate={generalAnalysis?.lastShipment?.sendAt}
      />
      <Tabs
        answerTab={{
          questions: mergedQuestions,
          sections: selectedSurvey?.sections || [],
          data: answers?.map((a) => ({
            name: a?.employee_name,
            date: a?.updated_at,
            answer: a?.sections?.map((s) => ({
              id: s?.id,
              questions: s?.questions?.map((q) => ({
                label: q?.label,
                title: q?.title,
                id: q?.id,
                value: q?.value || "",
                justification: q?.justification,
              })),
            })),
          })),
        }}
        resultTab={{
          data: mergedQuestions,
          resultType: resultType,
          onChangeResultType: (type) => setResultType(type),
        }}
        employeeTab={{
          data:
            employees?.map((e) => ({
              id: e?.id,
              surveyId: e?.surveyId,
              name: e?.name,
              date: e?.date,
              status: e?.status,
              visualized: e?.visualized,
            })) || [],
        }}
        detailTab={{
          data: {
            name: selectedSurvey?.engagement_name || "",
            title: selectedSurvey?.title || "",
            createdAt: new Date(selectedSurvey?.created_at || ""),
            type: selectedSurvey?.type || "research",
            status: selectedSurvey?.status || "draft",
            owners: [],
            createdBy: selectedSurvey?.created_by || "",
            sendDate: selectedSurvey?.send_at
              ? new Date(selectedSurvey?.send_at)
              : undefined,
            description: "",
            notifications: {
              email: selectedSurvey?.has_email_notification || false,
              push: selectedSurvey?.has_push_notification || false,
              reminder: {
                enabled: selectedSurvey?.has_notification_reminder || false,
              },
            },
          },
        }}
      />
    </PageContainer>
  );
};
export default SurveyDetailView;
