import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BigNumberContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 118px;
  min-width: 291px;
  gap: 16px;
  border-radius: 12px;
  padding: 24px;
  border: 1px solid var(--color-neutral-90);
`;

const BigNumberContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const TooltipContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CarouselContainer = styled.div`
  > div > h6 {
    font-weight: 700 !important;
    color: var(--color-neutral-30) !important;
  }
  > div > section > ul {
    justify-content: space-between;

    > li {
      min-width: 291px;
      width: 23%;
    }
  }
`;

export {
  Container,
  BigNumberContainer,
  BigNumberContentContainer,
  TooltipContainer,
  CarouselContainer,
};
