import { useState } from "react";
import { Table, tableControllers, Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { PeopleTabInterface } from "./types";

export default ({ columns, data }: PeopleTabInterface) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 5,
  });

  const { pageNumber, pageSize } = pagination;

  const count = data.length;

  const finalData = data.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize
  );

  const table = tableControllers.useTableColumns<any>({
    defaultColumn: {
      minSize: 200,
    },
    total: count,
    columns: columns,
    data: finalData,
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  return (
    <Table.Root>
      <Table.Grid.Root loading={false}>
        <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
        {table.rows.map((row, index) => (
          <Table.Grid.Row key={index + row.id} row={row} />
        ))}
      </Table.Grid.Root>
      <Table.Pagination
        count={count}
        onPaginationChange={({ pageSize, pageNumber }) =>
          setPagination({
            ...pagination,
            pageSize,
            pageNumber: pagination?.pageSize !== pageSize ? 1 : pageNumber,
          })
        }
        pagination={pagination}
        pageSizeOptions={[
          {
            label: "5 itens",
            value: 5,
          },
          {
            label: "10 itens",
            value: 10,
          },
          {
            label: "25 itens",
            value: 25,
          },
        ]}
      />
    </Table.Root>
  );
};
