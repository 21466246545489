import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { NotificationCardV2 } from "@components/NotificationCardV2";
import { StepInfoProps } from "./types";
import { SectionNavigate } from "../../steps/content/components/SectionNavigate";
import { InfoContainer, ReviewStepsContainer, Step } from "./styles";
import { DragAndDrop } from "./DragAndDrop";

const StepInfo = ({
  model,
  anonymous,
  step,
  simplifiedSection,
  sections,
  selectedSection,
  onSectionSelect,
  isReview = false,
  selectedReviewStep,
  onReviewStepSelect,
  formik,
}: StepInfoProps) => {
  const stepData = {
    0: {
      title: "Galeria de Modelos de pesquisa",
      description:
        "Crie uma pesquisa em branco ou utilize um de nossos modelos padrões baseados em metodologias do mercado.",
    },
    1: {
      title: "Informações básicas",
      description:
        "Adicione as informações básicas da sua pesquisa, como nome de identificação, nome de exibição e descrição.",
    },
    2: {
      title: "Envio",
      description:
        formik?.values?.delivery === "continuous"
          ? "A pesquisa será enviada automaticamente para colaboradores ao adicionar destinatários nos detalhes da pesquisa. A pesquisa ficará aberta até o prazo final de resposta."
          : "Configure como deseja enviar.",
    },
    3: {
      title: "Configurações",
      description:
        "Adicione as configurações da pesquisa, como anonimato e notificações.",
    },
    4: {
      title: "Conteúdo",
      description: model
        ? "Visualize as perguntas que fazem parte da pesquisa."
        : "Crie as seções e as perguntas da pesquisa. Navegue entre as seções e reordene utilizando o menu abaixo.",
    },
    5: {
      title: "Destinatários",
      description: "Selecione os participantes que responderão a pesquisa.",
    },
    6: {
      title: "Revisão",
      description:
        "Confira a prévia da sua pesquisa e revise antes de enviá-la.",
    },
  };

  const title = stepData[step]?.title;
  const description = stepData[step]?.description;
  const reviewSteps =
    formik.values.delivery === "continuous"
      ? ["Detalhes", "Envio", "Configurações", "Conteúdo"]
      : ["Detalhes", "Envio", "Configurações", "Conteúdo", "Destinatários"];

  return (
    <InfoContainer>
      {title && (
        <Typography
          variant="headline7"
          weight={700}
          variantColor="#F20D7A"
          style={{ paddingRight: "10px" }}
        >
          {title}
        </Typography>
      )}
      {description && (
        <Typography
          variant="body3"
          weight={400}
          variantColor="#83727D"
          style={{ paddingRight: "10px" }}
        >
          {description}
        </Typography>
      )}
      {model && (
        <NotificationCardV2
          variant="outlined"
          type="info"
          title={`Pesquisa a partir do modelo "${model}".`}
          iconName="IconAlertTriangle"
        />
      )}
      {anonymous && step === 5 && (
        <NotificationCardV2
          variant="outlined"
          type="info"
          title="O anonimato está habilitado"
          subtitle="Para garantir a segurança da informação a pesquisa deve ser enviada para pelo menos 3 pessoas."
          iconName="IconAlertTriangle"
        />
      )}
      {!model && step === 4 && formik?.values?.sections?.length ? (
        <DragAndDrop formik={formik} />
      ) : null}
      {model &&
        step === 4 &&
        simplifiedSection &&
        simplifiedSection.length > 1 && (
          <div style={{ width: "100%", marginTop: "16px" }}>
            <SectionNavigate
              sections={simplifiedSection}
              selectedSection={selectedSection}
              onClick={(id) => onSectionSelect?.(id)}
            />
          </div>
        )}
      {isReview ? (
        <ReviewStepsContainer>
          {reviewSteps?.map((s, i) => (
            <Step
              key={i}
              selected={selectedReviewStep === i}
              onClick={() => onReviewStepSelect?.(i)}
            >
              {s}
            </Step>
          ))}
        </ReviewStepsContainer>
      ) : (
        <></>
      )}

      {step === 4 && sections && sections.length > 1 && (
        <div style={{ width: "100%", marginTop: "16px" }}>
          <SectionNavigate
            sections={sections}
            selectedSection={selectedSection}
            onClick={(id) => onSectionSelect?.(id)}
          />
        </div>
      )}
    </InfoContainer>
  );
};

export { StepInfo };
