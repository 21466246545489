import { DraftSurvey } from "./types";

const initialValues: DraftSurvey = {
  delivery: "single",
  termsAndConditions: {
    acceptedBy: undefined,
    date: undefined,
    type: "engaja",
  },
  everyoneWithAccess: true,
  selectedSectionIndex: 0,
  _id: "",
  // type: "research",
  modelId: "",
  // status: "send",
  isDraft: true,
  startDate: {
    date: undefined,
    period: undefined,
  },
  name: "",
  title: "",
  description: "",
  interactionDeadline: "",
  interactionDeadlineDays: 0,
  anonymous: false,
  notifications: {
    email: false,
    push: false,
    reminder: {
      enabled: false,
      minRate: 0,
    },
  },
  owners: [],
  employees: [],
  sections: [],
  recurrence: {
    repeat: 1,
    daysOfTheWeek: [
      {
        label: "Segunda-feira",
        value: "monday",
        checked: false,
      },
      {
        label: "Terça-feira",
        value: "tuesday",
        checked: false,
      },
      {
        label: "Quarta-feira",
        value: "wednesday",
        checked: false,
      },
      {
        label: "Quinta-feira",
        value: "thursday",
        checked: false,
      },
      {
        label: "Sexta-feira",
        value: "friday",
        checked: false,
      },
      {
        label: "Sábado",
        value: "saturday",
        checked: false,
      },
      {
        label: "Domingo",
        value: "sunday",
        checked: false,
      },
    ],
    employees: [],
    frequency: undefined,
    // status: "closed",
    occurrenceLimit: -1,
    endDate: undefined,
    interactionDeadlineConfig: {
      nextMode: true,
      calculatedDate: {
        multiplier: 1,
        period: undefined,
      },
    },
  },
  companyData: {
    economicSector: undefined,
    employees: undefined,
    size: undefined,
  },
};

export { initialValues };
