import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useRegisterRoutes } from "@flash-tecnologia/hros-web-utility";
import {
  EngagementForm,
  Communication,
  CommunicationDetail,
  CommunicationInternal,
  Survey,
  SurveyArea,
  SurveyDetail,
  SurveyAnswer,
  RecurrenceDetail,
  AddRecipients,
  EmployeeDetail,
  EmployeeSurveyDetail,
  EmployeeSurveyAnswerDetail,
  EngajaDetail,
  FeelingDetail,
  SurveyDetailV2,
  SurveyForm,
} from "../Pages";

import { UserProvider } from "../context/UserProvider";

import { RoutesGuard } from "./routesGuard";
import { ContractProvider } from "src/context/ContractProvider";
import PageOccurenceDetail from "src/Pages/ModelDetails/FeelingDetail/PageOccurenceDetail";

export const routes = {
  pageCommunication: "/engagement/communication",
  pageCommunicationAdd: "/engagement/communication/add",
  pageCommunicationEdit: "/engagement/communication/edit/:id",
  pageCommunicationDetail: "/engagement/communication/:id",
  pageCommunicationInternal: "/engagement/communication/internal/:id",
  pageSurvey: "/engagement/survey",
  pageSurveyAdd: "/engagement/survey/add",
  pageSurveyForm: "/engagement/survey/form",
  pageSurveyFormEdit: "/engagement/survey/form/:id",
  pageSurveyAddEngaja: "/engagement/survey/add/engaja",
  pageSurveyAddModel: "/engagement/survey/add/:model",
  pageSurveyEdit: "/engagement/survey/edit/:id",
  pageSurveyDetail: "/engagement/survey/:id",
  pageSurveyDetailV2: "/engagement/v2/survey/:id/:delivery",
  pageEngajaDetail: "/engagement/survey/engaja/:id",
  pageFeelingDetail: "/engagement/survey/feeling/recurrence/:id",
  pageFeelingOccurenceDetail:
    "/engagement/survey/feeling/recurrence/:id/occurence/:occurenceId",
  pageSurveyAnswer: "/engagement/survey/answer/:step/:id",
  pageSurveyArea: "/engagement/my-surveys",
  pageRecurrenceSurveyDetail: "/engagement/survey/recurrence/:id",
  pageAddRecipients: "/engagement/survey/:modelId/:id/recipients",
  pageEmployeeDetail: "/engagement/employee/:type/:id",
  pageEmployeeSurveyDetail:
    "/engagement/employee/:employeeId/survey/:engagementId",
  pageEmployeeAnswerDetail:
    "/engagement/employee/:employeeId/survey/:engagementId/answer",
} as const;

const AppRouter = () => {
  useRegisterRoutes([
    {
      path: routes.pageCommunication,
      hideHeader: false,
    },
    {
      path: routes.pageCommunicationAdd,
      hideHeader: true,
    },
    {
      path: routes.pageCommunicationEdit,
      hideHeader: true,
    },
    {
      path: routes.pageCommunicationDetail,
      hideHeader: false,
    },
    {
      path: routes.pageCommunicationInternal,
      hideHeader: true,
    },
    {
      path: routes.pageSurvey,
      hideHeader: false,
    },
    {
      path: routes.pageSurveyAdd,
      hideHeader: true,
    },
    {
      path: routes.pageSurveyForm,
      hideHeader: true,
    },
    {
      path: routes.pageSurveyFormEdit,
      hideHeader: true,
    },
    {
      path: routes.pageSurveyAddModel,
      hideHeader: true,
    },
    {
      path: routes.pageSurveyEdit,
      hideHeader: true,
    },
    {
      path: routes.pageSurveyDetail,
      hideHeader: false,
    },
    {
      path: routes.pageEngajaDetail,
      hideHeader: false,
    },
    {
      path: routes.pageFeelingDetail,
      hideHeader: false,
    },
    {
      path: routes.pageFeelingOccurenceDetail,
      hideHeader: false,
    },
    {
      path: routes.pageRecurrenceSurveyDetail,
      hideHeader: false,
    },
    {
      path: routes.pageAddRecipients,
      hideHeader: true,
    },
    {
      path: routes.pageEmployeeDetail,
      hideHeader: false,
    },
    {
      path: routes.pageSurveyAnswer,
      hideHeader: true,
    },
    {
      path: routes.pageSurveyDetailV2,
      hideHeader: false,
    },
  ]);

  return (
    <ContractProvider>
      <UserProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<RoutesGuard />}>
              <Route
                path={routes?.pageCommunication}
                element={<Communication />}
              />
              <Route
                path={routes?.pageCommunicationAdd}
                element={<EngagementForm type="communication" />}
              />
              <Route
                path={routes?.pageCommunicationEdit}
                element={<EngagementForm type="communication" />}
              />
              <Route
                path={routes?.pageCommunicationDetail}
                element={<CommunicationDetail />}
              />
              <Route
                path={routes?.pageCommunicationInternal}
                element={<CommunicationInternal />}
              />

              <Route path={routes?.pageSurveyArea} element={<SurveyArea />} />
              <Route path={routes?.pageSurvey} element={<Survey />} />
              <Route
                path={routes?.pageSurveyAdd}
                element={<EngagementForm type="survey" />}
              />
              <Route path={routes?.pageSurveyForm} element={<SurveyForm />} />
              <Route
                path={routes?.pageSurveyFormEdit}
                element={<SurveyForm />}
              />
              <Route
                path={routes?.pageSurveyAddEngaja}
                element={<EngagementForm type="survey" forceEngaja={true} />}
              />
              <Route
                path={routes?.pageSurveyAddModel}
                element={<EngagementForm type="survey" />}
              />
              <Route
                path={routes?.pageSurveyEdit}
                element={<EngagementForm type="survey" />}
              />
              <Route
                path={routes?.pageSurveyDetail}
                element={<SurveyDetail />}
              />
              <Route
                path={routes?.pageEngajaDetail}
                element={<EngajaDetail />}
              />
              <Route
                path={routes?.pageFeelingDetail}
                element={<FeelingDetail />}
              />
              <Route
                path={routes?.pageFeelingOccurenceDetail}
                element={<PageOccurenceDetail />}
              />
              <Route
                path={routes?.pageSurveyAnswer}
                element={<SurveyAnswer />}
              />
              <Route
                path={routes?.pageRecurrenceSurveyDetail}
                element={<RecurrenceDetail />}
              />
              <Route
                path={routes?.pageAddRecipients}
                element={<AddRecipients />}
              />
              <Route
                path={routes?.pageEmployeeDetail}
                element={<EmployeeDetail />}
              />

              <Route
                path={routes?.pageEmployeeSurveyDetail}
                element={<EmployeeSurveyDetail />}
              />
              <Route
                path={routes?.pageEmployeeAnswerDetail}
                element={<EmployeeSurveyAnswerDetail />}
              />
              <Route
                path={routes?.pageSurveyDetailV2}
                element={<SurveyDetailV2 />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </UserProvider>
    </ContractProvider>
  );
};

export default AppRouter;
