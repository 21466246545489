import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import {
  OccurrenceTab,
  AnswerTab,
  DetailTab,
  EmployeesTab,
  ResultsTab,
  RecurrenceEmployeesTab,
} from "./components";
import { TabsProps } from "./types";
import { RecurrenceAnalysis } from "./components/RecurrenceAnalysis";

const Tabs = ({
  delivery,
  occurrenceTab,
  answerTab,
  resultTab,
  employeeTab,
  detailTab,
  recurrenceAnalysisTab,
  recurrenceEmployeeTab,
}: TabsProps) => {
  if (delivery === "recurring") {
    let recurringTabItens = [
      {
        label: `Envios`,
        component: <OccurrenceTab data={occurrenceTab?.data || []} />,
      },
      {
        label: `Pessoas`,
        component: (
          <RecurrenceEmployeesTab data={recurrenceEmployeeTab?.data || []} />
        ),
      },
      {
        label: `Detalhes`,
        component: <DetailTab {...detailTab} />,
      },
    ];

    if (recurrenceAnalysisTab) {
      recurringTabItens = [
        {
          label: `Análise`,
          component: <RecurrenceAnalysis {...recurrenceAnalysisTab} />,
        },
        ...recurringTabItens,
      ];
    }

    return <Tab tabItens={recurringTabItens} />;
  }

  return (
    <Tab
      tabItens={[
        {
          label: `Resultados`,
          component: <ResultsTab {...resultTab} />,
        },
        {
          label: `Respostas (${answerTab?.data?.length || 0})`,
          component: <AnswerTab {...answerTab} />,
        },
        {
          label: `Pessoas (${employeeTab?.data?.length || 0})`,
          component: <EmployeesTab {...employeeTab} />,
        },
        {
          label: `Detalhes`,
          component: <DetailTab {...detailTab} />,
        },
      ]}
    />
  );
};

export default Tabs;
