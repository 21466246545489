import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
  padding: 24px;
`;

export { Container };
