import { BoxAdornment, Radio } from "@flash-tecnologia/hros-web-ui-v2";

import { CounterField, Typography } from "../../../..";
import { Divider } from "../../../../v2";
import {
  Box,
  StepContainer,
  StepDescription,
} from "../../../common/components/index";
import { Row, StyleContainer } from "./styles";
import { StepDispatchExitInterface } from "./types";
import { useEffect, useState } from "react";

const StepDispatchExit = ({
  interactionDeadlineDays,
  disabled,
  hide,
  model,
  onChange,
}: StepDispatchExitInterface) => {
  const [alwaysAvailable, setAlwaysAvailable] = useState(true);

  useEffect(() => {
    if (
      interactionDeadlineDays &&
      interactionDeadlineDays >= 1 &&
      alwaysAvailable
    )
      setAlwaysAvailable(false);
  }, [interactionDeadlineDays]);

  if (hide) return <></>;

  return (
    <StepContainer>
      <StepDescription
        model={model}
        title="Envio"
        description={`A pesquisa será enviada automaticamente para colaboradores ao adicionar destinatários nos detalhes da pesquisa. <br/> A pesquisa ficará aberta até o prazo final de resposta.`}
      />
      <StyleContainer>
        <Box>
          <Row>
            <Typography
              variant="headline8"
              fontWeight={700}
              color="var(--color-neutral-30)"
            >
              Período de prazo de respostas
            </Typography>
          </Row>
          <Row>
            <Typography
              variant="body4"
              fontWeight={400}
              color="var(--color-neutral-50)"
            >
              Configure até quando a pesquisa deve receber respostas. Após o
              limite, a pesquisa será fechada e novas respostas não serão
              aceitas.
            </Typography>
          </Row>
          <Divider spacing="xs1" />
          <Row>
            <BoxAdornment
              style={{ width: "100%" }}
              description={
                <>
                  <Typography
                    variant="body3"
                    color="var(--color-neutral-30)"
                    fontWeight={600}
                  >
                    Sempre disponível.
                  </Typography>
                </>
              }
              leftAdornment={
                <Radio
                  disabled={disabled}
                  checked={alwaysAvailable}
                  onChange={() => {
                    onChange(0);
                    setAlwaysAvailable(!alwaysAvailable);
                  }}
                />
              }
            />
          </Row>
          <Divider spacing="xs1" />
          <Row>
            <BoxAdornment
              style={{ width: "100%" }}
              description={
                <Row>
                  <Typography
                    variant="body3"
                    color="var(--color-neutral-30)"
                    fontWeight={600}
                    minWidth={"26px"}
                  >
                    Até
                  </Typography>
                  <Divider spacing="xxxs" type="horizontal" />
                  <CounterField
                    disabled={disabled || alwaysAvailable}
                    value={interactionDeadlineDays || 1}
                    min={1}
                    onChange={(e) => onChange(e)}
                  />
                  <Divider spacing="xxxs" type="horizontal" />
                  <Typography
                    variant="body3"
                    color="var(--color-neutral-30)"
                    fontWeight={600}
                    minWidth="189px"
                  >
                    dias após a data de cada envio.
                  </Typography>
                </Row>
              }
              leftAdornment={
                <Radio
                  checked={disabled || !alwaysAvailable}
                  onChange={() => {
                    setAlwaysAvailable(!alwaysAvailable);
                  }}
                />
              }
            />
          </Row>
        </Box>
      </StyleContainer>
    </StepContainer>
  );
};

export { StepDispatchExit };
