import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  MainContainer,
  Container,
  Number,
  Button,
  LeftLabel,
  RightLabel,
} from "./styles";
import { CounterFieldInterface } from "./types";

const CounterField = ({
  value,
  labels,
  min,
  max,
  onChange,
  disabled,
  percentageMode,
  setIncrementAndDecrement,
}: CounterFieldInterface) => {
  const handleClick = (value: number, operator: "addition" | "subtraction") => {
    const num = setIncrementAndDecrement || 1;
    const newValue = operator === "addition" ? value + num : value - num;

    if (min && min > newValue) return;
    if (max && max < newValue) return;

    onChange(newValue);
  };

  return (
    <MainContainer>
      {labels?.left ? (
        <LeftLabel variant="body4">{labels?.left}</LeftLabel>
      ) : null}
      <Container>
        <Button
          type="button"
          disabled={disabled || min === value}
          onClick={() => handleClick(value, "subtraction")}
        >
          <Icons
            size={17}
            name="IconMinus"
            color={
              min === value
                ? "var(--color-neutral-light4)"
                : "var(--color-neutral-dark4)"
            }
          />
        </Button>
        <Number variant="body3">
          {value}
          {percentageMode ? "%" : ""}
        </Number>
        <Button
          type="button"
          onClick={() => handleClick(value, "addition")}
          disabled={disabled || max === value}
        >
          <Icons
            size={17}
            name="IconPlus"
            color={
              max === value
                ? "var(--color-neutral-light4)"
                : "var(--color-neutral-dark4)"
            }
          />
        </Button>
      </Container>
      {labels?.right ? (
        <RightLabel variant="body4">{labels?.right}</RightLabel>
      ) : null}
    </MainContainer>
  );
};

export { CounterField };
