import React, { useState } from "react";
import {
  Divider,
  Icons,
  Skeleton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled, { useTheme } from "styled-components";
import {
  Frame,
  GreenContainer,
  RedContainer,
  ResultLineWrapper,
  Thermometer,
  YellowContainer,
} from "../ResultsTab/styled";
import { trpc } from "@api/client";
import { generateSurveyDataByModel } from "@components/EngagementForm/common/components";
import { useParams } from "react-router-dom";
import { orderedEngajaQuestions } from "src/utils/ordered-engaja-questions";
import { Drawer } from "@components/index";
import { DrawerContent } from "../DrawerContent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const Card = styled.div`
  align-items: flex-start;
  border: 1px solid;
  border-color: rgb(235, 230, 233);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 24px;
  position: relative;
  width: 100%;
  max-width: 558px;
`;

const RowBetween = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const RowStart = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex;
  gap: 2px;
  padding-bottom: 2px;
  border-bottom: 1.5px solid var(--color-neutral-40);
  cursor: pointer;
`;

const QuestionDetailsWrapper = styled.div`
  width: 100%;
`;

const QuestionMetricWrapper = styled.div``;

const QuestionMetric = ({
  metric,
}: {
  metric: { name: string; value: string | number; total?: string | number };
}) => {
  const theme = useTheme() as any;
  const percentage = `(${(
    (Number(metric.value) / Number(metric.total)) *
    100
  ).toFixed(1)}%)`;

  return (
    <QuestionMetricWrapper>
      <Typography variant="caption" variantColor={theme?.colors?.neutral[50]}>
        {metric.name}
      </Typography>
      <Typography
        variant="body3"
        variantColor={theme?.colors?.neutral[40]}
        weight={600}
      >
        {metric.value} {metric.total && percentage}
      </Typography>
    </QuestionMetricWrapper>
  );
};

const QuestionDetails = ({
  question,
  name,
}: {
  question: any;
  name: string;
}) => {
  const {
    question_answer_1_count,
    question_answer_2_count,
    question_answer_3_count,
    question_answer_4_count,
    question_answer_5_count,
  } = question;
  const theme = useTheme() as any;

  const total =
    question_answer_1_count +
    question_answer_2_count +
    question_answer_3_count +
    question_answer_4_count +
    question_answer_5_count;

  const metrics = [
    {
      name: "Engajados",
      value: `${question_answer_5_count + question_answer_4_count}`,
      total: total,
    },
    {
      name: "Desengajados",
      value: `${question_answer_3_count}`,
      total: total,
    },
    {
      name: "Ativamente desengajados",
      value: `${question_answer_1_count + question_answer_2_count}`,
      total: total,
    },
    { name: "Média", value: question.question_average },
  ];

  return (
    <QuestionDetailsWrapper>
      <Typography variant="body4" variantColor={theme?.colors?.neutral[30]}>
        {name}
      </Typography>
      <RowBetween>
        {metrics.map((metric, index) => (
          <QuestionMetric key={index} metric={metric} />
        ))}
      </RowBetween>
    </QuestionDetailsWrapper>
  );
};

export default function DimensionsDetailsDrawerContent({
  sectionReferenceId,
}: {
  sectionReferenceId: string;
}) {
  const { id = "" } = useParams();
  const engagementId = id;
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const { data: sectionData, isLoading: sectionDataLoading } =
    trpc?.getEngajaSectionResults?.useQuery(
      { sectionReferenceId, engagementId },
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!sectionReferenceId,
      }
    );

  const {
    data: dimensionData,
    refetch,
    isLoading: dimensionDataLoading,
  } = trpc?.getEngajaDimensionDetails?.useQuery(
    { sectionReferenceId, engagementId },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!sectionReferenceId,
    }
  );

  const theme = useTheme() as any;

  const sectionResults = sectionData?.result?.[0];

  const {
    company_respondents_high_answers,
    company_respondents_medium_answers,
    company_respondents_low_answers,
    section_average,
    respondent_count,
  } = sectionResults || {};

  const sectionLowAnswersPercentage =
    (company_respondents_low_answers / respondent_count) * 100;
  const sectionMediumAnswersPercentage =
    (company_respondents_medium_answers / respondent_count) * 100;
  const sectionHighAnswersPercentage =
    (company_respondents_high_answers / respondent_count) * 100;

  const dimensionsResults = dimensionData?.result;

  const engajaModel = generateSurveyDataByModel("engaja");
  const engajaModelSection = engajaModel.find(
    (section) => section.referenceId === sectionReferenceId
  );

  return (
    <Container>
      <Drawer
        content={
          <DrawerContent
            content={[
              {
                title: "Ativamente desengajados (≤2)",
                description:
                  "Estão insatisfeitos, desmotivados e desconectados dos objetivos e valores da empresa. Por isso, não dedicam energia para contribuir com o sucesso organizacional",
                color: "#F45A55",
              },
              {
                title: "Desengajados (2-4) ",
                description:
                  "Tendem a cumprir as tarefas exigidas, mas não estão plenamente comprometidos com os objetivos e valores da organizaçãoe não fazem esforço extra para que ela atinja metas.",
                color: "#F8B81C",
              },
              {
                title: "Engajados  (≥4)",
                description:
                  "Estão comprometidos com os objetivos e valores da organização, motivados e dispostos a contribuir para o sucesso organizacional.",
                color: "#6AA01D",
              },
            ]}
          />
        }
        title="Sobre a escala"
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
      />
      {sectionDataLoading ? (
        <Skeleton height={400} />
      ) : (
        <Card>
          <RowBetween>
            <Typography
              variant="headline9"
              variantColor={theme?.colors?.neutral[30]}
            >
              Distribuição da dimensão
            </Typography>
            <RowStart onClick={() => setDrawerIsOpen(true)}>
              <Icons name="IconInfoCircle" color={theme?.colors?.neutral[40]} />
              <Typography
                variant="caption"
                style={{ fontWeight: 700 }}
                variantColor={theme?.colors?.neutral[40]}
              >
                Sobre a escala
              </Typography>
            </RowStart>
          </RowBetween>
          <RowBetween>
            <Typography
              variant="headline9"
              variantColor={theme?.colors?.neutral[30]}
            >
              Média da empresa: {section_average}
            </Typography>
            <Typography
              variant="headline9"
              variantColor={theme?.colors?.neutral[30]}
            >
              Total: {respondent_count} respostas
            </Typography>
          </RowBetween>
          <Frame>
            <ResultLineWrapper>
              <Typography
                variant="body4"
                variantColor={theme?.colors?.neutral[30]}
              >
                Ativamente desengajados (≤2)
              </Typography>
              <Typography
                variant="body4"
                variantColor={theme?.colors?.neutral[30]}
              >
                {company_respondents_low_answers} (
                {sectionLowAnswersPercentage.toFixed(2)}%)
              </Typography>
              <Thermometer>
                <RedContainer
                  style={{
                    width: `${sectionLowAnswersPercentage}%`,
                  }}
                >
                  <Thermometer width={100} color="#F45A55" />
                </RedContainer>
              </Thermometer>
            </ResultLineWrapper>
            <Divider orientation="horizontal" />
            <ResultLineWrapper>
              <Typography
                variant="body4"
                variantColor={theme?.colors?.neutral[30]}
              >
                Desengajados (2-4)
              </Typography>
              <Typography
                variant="body4"
                variantColor={theme?.colors?.neutral[30]}
              >
                {company_respondents_medium_answers} (
                {sectionMediumAnswersPercentage.toFixed(2)}%)
              </Typography>
              <Thermometer>
                <YellowContainer
                  style={{
                    width: `${sectionMediumAnswersPercentage}%`,
                  }}
                >
                  <Thermometer width={100} color="#F8B81C" />
                </YellowContainer>
              </Thermometer>
            </ResultLineWrapper>
            <Divider orientation="horizontal" />
            <ResultLineWrapper>
              <Typography
                variant="body4"
                variantColor={theme?.colors?.neutral[30]}
              >
                {"Engajados (≥4)"}
              </Typography>
              <Typography
                variant="body4"
                variantColor={theme?.colors?.neutral[30]}
              >
                {company_respondents_high_answers} (
                {sectionHighAnswersPercentage.toFixed(2)}
                %)
              </Typography>
              <Thermometer>
                <GreenContainer
                  style={{
                    width: `${sectionHighAnswersPercentage}%`,
                  }}
                >
                  <Thermometer width={100} color="#6AA01D" />
                </GreenContainer>
              </Thermometer>
            </ResultLineWrapper>
          </Frame>
        </Card>
      )}
      {dimensionDataLoading ? (
        <Skeleton height={400} />
      ) : (
        <Card>
          <Typography
            variant="headline9"
            variantColor={theme?.colors?.neutral[30]}
          >
            Pilares da dimensão
          </Typography>
          {orderedEngajaQuestions(dimensionsResults, engajaModelSection)?.map(
            (question, index) => {
              const question_reference_id = question.question_reference_id;

              const name =
                engajaModelSection?.questions?.find(
                  (question) => question.referenceId === question_reference_id
                )?.title || "";

              return (
                <React.Fragment key={question_reference_id}>
                  <QuestionDetails question={question} name={name} />
                  {index < dimensionsResults.length - 1 && (
                    <Divider orientation="horizontal" />
                  )}
                </React.Fragment>
              );
            }
          )}
        </Card>
      )}
    </Container>
  );
}
