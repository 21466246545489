import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider as NewProvider,
  Button,
  Tab,
  dayjs,
  Menu,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  TitleContainer,
  Title,
  PageContainer,
  DataGrid,
  Divider,
  MenuIcon,
  Tag,
  NotFoundMessage,
  CardSkeleton,
  DataGridSkeleton,
  Modal,
  CarouselCards,
  PeopleTable,
  CampaignReport,
  InteractionDeadlineModalForm,
  Typography,
  FeatureNotification,
} from "../../components";
import { alert, segment, useOwnershipValidation } from "../../utils";
import { FiltersType } from "../../components/TableFilter/types";
import { trpc } from "@api/client";
import { EditOwnersModal } from "src/modals";
import { EngagementModel } from "@models";
import { useUser } from "src/hooks/useUser";
import { EngajaNotificationCard } from "@components/EngajaNotificationCard";
import { useContractValidation } from "src/hooks/useContractValidation";

export default () => {
  const hasEngagementContract = useContractValidation();

  const validateOwnership = useOwnershipValidation();
  const user = useUser();
  const navigate = useNavigate();

  const [intd, setIntd] = useState<{
    visible: boolean;
    value?: string;
    engagementId?: string;
  }>({
    visible: false,
    engagementId: undefined,
    value: undefined,
  });

  const [ownersEditModal, setOwnersEditModal] = useState<{
    open: boolean;
    engagement?: EngagementModel;
  }>({
    open: false,
    engagement: undefined,
  });

  const [recurrenceSearch, setRecurrenceSearch] = useState<string | null>(null);
  const filterConfig: FiltersType = [
    {
      id: "model",
      type: "multi-select",
      label: "Modelo",
      valueInLabel: true,
      options: [
        {
          label: "Personalizado",
          value: "custom",
        },
        {
          label: "Engajamento",
          value: "climate",
        },
        {
          label: "Engaja S/A",
          value: "engaja",
        },
        {
          label: "eNPS",
          value: "enps",
        },
      ],
    },
    {
      id: "status",
      type: "multi-select",
      label: "Status",
      valueInLabel: true,
      options: [
        {
          label: "Enviado",
          value: "send",
        },
        {
          label: "Rascunho",
          value: "draft",
        },
        {
          label: "Agendado",
          value: "scheduled",
        },
      ],
    },
    {
      id: "anonymous",
      type: "boolean",
      label: "Anonimato",
      valueInLabel: true,
      options: [
        {
          label: "Anônimo",
          value: "true",
        },
        {
          label: "Não anônimo",
          value: "false",
        },
      ],
    },
    {
      id: "createdAt",
      type: "date",
      label: "Criação",
      valueInLabel: true,
    },
    {
      id: "sendAt",
      type: "date",
      label: "Envio",
      valueInLabel: true,
    },
  ];
  const [showCampaignReport, setShowCampaignReport] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [filters, setFilters] = useState<FiltersType>(filterConfig);
  const [recurrenceFilters, setRecurrenceFilters] = useState<FiltersType>(
    filterConfig?.map(({ id, ...rest }) => {
      if (id === "status")
        return {
          id,
          ...rest,
          options: [
            {
              label: "Rascunho",
              value: "draft",
            },
            {
              label: "Não iniciado",
              value: "scheduled",
            },
            {
              label: "Em andamento",
              value: "progress",
            },
            {
              label: "Pausado",
              value: "paused",
            },
            {
              label: "Encerrado",
              value: "closed",
            },
          ],
        };

      return { id, ...rest };
    })
  );

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [campaigns, setCampaigns] = useState<any>(null);
  const [recurrences, setRecurrences] = useState<any[]>([]);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [search, setSearch] = useState<any>(null);

  const [selectedCampaignId, setSelectedCampaignId] = useState<string>();
  const [dashboard, setDashboard] = useState<{
    engagements?: number;
    visualization?: number;
    visualizationRate?: number;
    interation?: number;
    interationRate?: number;
  } | null>(null);
  const [showEngajaWarning, setShowEngajaWarning] = useState(false);

  const {
    refetch: getAllowedSurveyModels,
    isFetching: getAllowedSurveyModelsLoading,
  } = trpc?.getAllowedSurveyModels?.useQuery(undefined, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (e) => {
      if (e?.length && e?.includes("engaja") && showEngajaWarning === false)
        setShowEngajaWarning(true);
    },
    onError: (error: unknown) => {
      alert("Ocorreu um erro, tente novamente mais tarde.");
      console.log(error);
    },
    enabled: false,
  });

  useEffect(() => {
    getAllowedSurveyModels();
  }, []);

  const deleteCampaign = () => {
    if (selectedCampaignId) deleteEngagement.mutate({ id: selectedCampaignId });
  };

  const copyEngagement = trpc.copyEngagement.useMutation({
    onSuccess: () => {
      refetchRecurrences();
      refetchEngagements();
    },
    onError: () => {
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  const copyCampaign = (campaignId: string) =>
    copyEngagement.mutate({ id: campaignId });

  const selectCampaign = (campaignId: string, action: "delete" | "export") => {
    setSelectedCampaignId(campaignId);

    if (action === "delete") {
      setShowConfirmModal(!showConfirmModal);
      return;
    }

    setShowCampaignReport(!showCampaignReport);
  };

  const { data: averageRatingByCompanyId } =
    trpc?.getAverageRatingByCompanyId.useQuery(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
    });

  const { refetch: refetchEngagements, isFetching: filterLoading } =
    trpc?.getEngagementsByCompanyId?.useQuery(
      {
        type: "survey",
        search: search || "",
        model:
          filters
            .find((e) => e.id === "model")
            ?.options?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || [],
        status:
          filters
            .find((e) => e.id === "status")
            ?.options?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || [],
        createdAt: filters?.find(({ id }) => id === "createdAt")?.dateRange
          ?.from
          ? {
              from: dayjs(
                filters?.find(({ id }) => id === "createdAt")?.dateRange?.from
              )?.format("DD-MM-YYYY"),
              to: dayjs(
                filters?.find(({ id }) => id === "createdAt")?.dateRange?.to
              )?.format("DD-MM-YYYY"),
            }
          : undefined,
        sendAt: filters?.find(({ id }) => id === "sendAt")?.dateRange?.from
          ? {
              from: dayjs(
                filters?.find(({ id }) => id === "sendAt")?.dateRange?.from
              )?.format("DD-MM-YYYY"),
              to: dayjs(
                filters?.find(({ id }) => id === "sendAt")?.dateRange?.to
              )?.format("DD-MM-YYYY"),
            }
          : undefined,
        anonymous:
          filters
            .find((e) => e.id === "anonymous")
            ?.options?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || [],
        answerFormat:
          filters
            .find((e) => e.id === "answerFormat")
            ?.options?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || [],
      },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: () => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
        },
        onSuccess: (data) => {
          const engagements = data?.engagements;
          if (empty && engagements?.length) setEmpty(false);

          setCampaigns([
            ...engagements
              ?.filter(
                ({ engagementOrigin, recurrence }) =>
                  !engagementOrigin && !recurrence
              )
              ?.map((campaign) => ({
                id: campaign?._id,
                ...campaign,
              })),
          ]);
        },
      }
    );

  const { refetch: refetchRecurrences, isFetching: refetchRecurrencesLoading } =
    trpc?.getEngagementsByCompanyId?.useQuery(
      {
        type: "survey",
        search: recurrenceSearch || "",
        model:
          recurrenceFilters
            .find((e) => e.id === "model")
            ?.options?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || [],
        status:
          recurrenceFilters
            .find((e) => e.id === "status")
            ?.options?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || [],
        createdAt: recurrenceFilters?.find(({ id }) => id === "createdAt")
          ?.dateRange?.from
          ? {
              from: dayjs(
                recurrenceFilters?.find(({ id }) => id === "createdAt")
                  ?.dateRange?.from
              )?.format("DD-MM-YYYY"),
              to: dayjs(
                recurrenceFilters?.find(({ id }) => id === "createdAt")
                  ?.dateRange?.to
              )?.format("DD-MM-YYYY"),
            }
          : undefined,
        sendAt: recurrenceFilters?.find(({ id }) => id === "sendAt")?.dateRange
          ?.from
          ? {
              from: dayjs(
                recurrenceFilters?.find(({ id }) => id === "sendAt")?.dateRange
                  ?.from
              )?.format("DD-MM-YYYY"),
              to: dayjs(
                recurrenceFilters?.find(({ id }) => id === "sendAt")?.dateRange
                  ?.to
              )?.format("DD-MM-YYYY"),
            }
          : undefined,
        anonymous:
          recurrenceFilters
            .find((e) => e.id === "anonymous")
            ?.options?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || [],
        answerFormat:
          recurrenceFilters
            .find((e) => e.id === "answerFormat")
            ?.options?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || [],
      },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: () => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
        },
        onSuccess: (data) => {
          const engagements = data?.engagements;
          if (empty && engagements?.length) setEmpty(false);

          setRecurrences([
            ...engagements
              ?.filter(({ recurrence }) => recurrence?.status)
              ?.map((campaign) => ({
                id: campaign?._id,
                ...campaign,
              })),
          ]);
        },
      }
    );

  useEffect(() => {
    refetchEngagements();
  }, [filters, search]);

  useEffect(() => {
    refetchRecurrences();
  }, [recurrenceFilters, recurrenceSearch]);

  const deleteEngagement = trpc?.deleteEngagement.useMutation({
    onMutate: () => {
      setShowConfirmModal(!showConfirmModal);
      refetchEngagements();
      refetchRecurrences();
    },
    onSuccess: () => {
      (() => {
        refetchEngagements();
        setSelectedCampaignId(undefined);
        setShowSuccessModal(!showSuccessModal);
      })();
    },
    onError: () => {
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  const updateRecurrenceStatus = trpc?.updateRecurrenceStatus.useMutation({
    onSuccess: () => {
      refetchEngagements();
    },
    onError: (e) => {
      console.log(e);
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  const [
    updateInteractionDeadlineLoading,
    setUpdateInteractionDeadlineLoading,
  ] = useState(false);
  const updateInteractionDeadline = trpc?.updateInteractionDeadline.useMutation(
    {
      onMutate: () =>
        setUpdateInteractionDeadlineLoading(!updateInteractionDeadlineLoading),
      onSuccess: () => {
        refetchEngagements();
        alert("Data atualizada com sucesso!", "success");
        setIntd({ ...intd, visible: false });
        setUpdateInteractionDeadlineLoading(!updateInteractionDeadlineLoading);
      },
      onError: (e) => {
        console.log(e);
        alert("Ocorreu um erro, tente novamente mais tarde.");
        setUpdateInteractionDeadlineLoading(!updateInteractionDeadlineLoading);
      },
    }
  );

  const updateRecStatus = (
    id: string,
    status: "scheduled" | "progress" | "paused" | "closed"
  ) => {
    updateRecurrenceStatus.mutate({ id, status });
  };

  const createEngagement = trpc?.createEngagement.useMutation({
    onSuccess: () => {
      refetchRecurrences();
      refetchEngagements();
    },
    onError: () => {
      alert("Ocorreu um erro, tente novamente mais tarde.");
    },
  });

  const { refetch: getEngagementReportByEngagementIdRefetch } =
    trpc?.getEngagementReportByEngagementId?.useQuery(
      {
        screen: recurrences?.find(({ _id }) => _id === selectedCampaignId)
          ?.recurrence
          ? "recurrence-survey-home"
          : "survey-home",
        type: "survey",
        id: selectedCampaignId || "",
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log(error);
        },
        enabled: false,
      }
    );

  const { isFetching: getEngagementsPerformanceLoading } =
    trpc?.getEngagementsPerformance?.useQuery(
      { type: "survey" },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          setDashboard(data);
        },
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");

          console.log("err: ", e);
        },
      }
    );

  if (getEngagementsPerformanceLoading)
    return (
      <PageContainer>
        <CardSkeleton />
        <Divider />
        <DataGridSkeleton />
      </PageContainer>
    );

  return (
    <>
      {/* {!hasEngagementContract && <FreeTrialChronometer />} */}
      <PageContainer>
        <TitleContainer>
          <Title>Pesquisas</Title>
          <Menu
            children={
              <Button size="large" variant="primary">
                Criar novo
                <Icons name="IconPlus" />
              </Button>
            }
            type="default"
            options={[
              {
                label: "Criar pesquisa do zero",
                children: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <Icons name="IconEdit" />
                    <Typography variant="body3" fontWeight={600}>
                      Criar pesquisa do zero
                    </Typography>
                  </div>
                ),
                onClick: () => {
                  segment({
                    track:
                      "people_strategic_hr_engagement_company_surveys_create_blanksurvey",
                  });
                  navigate("/engagement/survey/add/custom");
                },
              },
              {
                children: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <Icons name="IconLayoutGrid" />
                    <Typography variant="body3" fontWeight={600}>
                      Com base em um modelo
                    </Typography>
                  </div>
                ),
                onClick: () => {
                  segment({
                    track:
                      "people_strategic_hr_engagement_company_surveys_create_fromtemplate",
                  });
                  navigate("/engagement/survey/add");
                },
              },
            ]}
          />
        </TitleContainer>
        {showEngajaWarning ? (
          <EngajaNotificationCard
            onClose={() => setShowEngajaWarning(false)}
            title="Novidade: Pesquisa Engaja S/A"
            description="Transforme seu RH! Nosso novo modelo vai revolucionar a forma como
          você entende e motiva seus colaboradores."
            linkButtons={[
              // { label: "Ver tutorial", callback: () => null },
              {
                label: "Usar modelo",
                callback: () =>
                  navigate("/engagement/survey/add", {
                    replace: true,
                    state: { drawerOpen: "engaja" },
                  }),
              },
              // {
              //   label: (
              //     <a
              //       href="https://flashapp.com.br/blog/institucional/lancamento-indice-engajamento-flash"
              //       target="_blank"
              //       rel="noopener noreferrer"
              //       style={{ textDecoration: "none" }}
              //     >
              //       <Box display={"flex"} flexDirection={"row"} gap={"4px"}>
              //         Saiba mais <Icons name="IconExternalLink" size={16} />
              //       </Box>
              //     </a>
              //   ),
              //   callback: () => null,
              // },
            ]}
          />
        ) : null}
        <CampaignReport
          hideMode
          type={"survey"}
          visible={showCampaignReport}
          onClick={() => {
            segment({
              track: "company_engagement_homesurveys_exportreport_clicked",
            });
            setShowCampaignReport(!showCampaignReport);
          }}
          onExport={() => {
            segment({
              track: "company_engagement_exportsurvey_exportreport_clicked",
            });
            if (selectedCampaignId) getEngagementReportByEngagementIdRefetch();
          }}
          onClose={() => {
            segment({
              track: "company_engagement_exportsurvey_cancel_clicked",
            });
            setShowCampaignReport(!showCampaignReport);
          }}
          onFinish={() => {
            setShowCampaignReport(!showCampaignReport);
          }}
          texts={{
            message: (
              <>
                O relatório apresentará somente os <strong>dados dessa</strong>
                <br />
                <strong>pesquisa</strong> convertidos em um arquivo CSV.
              </>
            ),
          }}
        />
        <Divider size="large" />
        <CarouselCards
          cards={[
            {
              title: "Total de pesquisas enviadas",
              icon: "IconCheckbox",
              value: averageRatingByCompanyId?.surveys || 0,
            },
            {
              title: "Taxa média de visualização",
              icon: "IconEyeCheck",
              value: dashboard?.visualizationRate || 0,
              subValue: dashboard?.visualization || 0,
              toolTip: {
                title: "Taxa média de visualização",
                description:
                  "A taxa é calculada a partir do total de destinatários que visualizaram a pesquisa na área de notificações dividido pelo total de destinatários que receberam a pesquisa.",
              },
            },
            {
              title: "Taxa média de resposta",
              value: averageRatingByCompanyId?.answerRate || 0,
              subValue: averageRatingByCompanyId?.answers || 0,
              icon: "IconGraph",
              toolTip: {
                title: "Taxa média de resposta",
                description:
                  "A taxa é calculada a partir do total de destinatários que clicaram no link da pesquisa dividido pelo total de destinatários que receberam campanhas do tipo pesquisa.",
              },
            },
          ]}
        />
        <NewProvider>
          <Divider size="medium" />
          <Tab
            tabItens={[
              {
                label: `Envios únicos (${campaigns?.length || 0})`,
                component: (
                  <>
                    {!empty ? (
                      <DataGrid
                        emptyState={{
                          isFiltered: true,
                          message: filters?.some((filter) => {
                            if (filter?.options?.length)
                              return filter?.options?.some((o) => o?.checked);

                            if (
                              filter?.dateRange?.from &&
                              filter?.dateRange?.to
                            )
                              return true;

                            return false;
                          })
                            ? "O filtro selecionado não apresenta resultados"
                            : "Você ainda não criou nenhuma pesquisa de envio único",
                        }}
                        loading={filterLoading}
                        searchField={{
                          value: search || "",
                          onSearch: (value: string) => setSearch(value || ""),
                          placeholder: "Buscar por pesquisa",
                        }}
                        filterTags={{
                          filters: filters,
                          onFilter: (e) => setFilters(e),
                          onClear: (e) => setFilters(e),
                        }}
                        columns={[
                          {
                            Header: "Nome de identificação",
                            accessor: "name",
                          },
                          {
                            Header: "Modelo",
                            accessor: "model",
                            Cell: ({ value }) => {
                              const models = {
                                custom: "Personalizado",
                                climate: "Engajamento",
                                enps: "eNPS",
                                engaja: "Engaja S/A",
                                feeling: "Sentimento",
                              };

                              return models[value] || "";
                            },
                          },
                          {
                            Header: "Status",
                            accessor: "status",
                            Cell: ({ value }) => (
                              <Tag variant={value}>
                                {value === "send"
                                  ? "Enviado"
                                  : value === "scheduled"
                                  ? "Agendado"
                                  : "Rascunho"}
                              </Tag>
                            ),
                          },
                          {
                            id: "anonymous",
                            Header: "Resposta Anônima",
                            // disableSortBy: true,
                            accessor: "anonymous",
                            Cell: ({ value }) => {
                              return (
                                <Tag variant={value ? "send" : "draft"}>
                                  {value ? "Sim" : "Não"}
                                </Tag>
                              );
                            },
                          },
                          {
                            Header: "Prazo de resposta",
                            accessor: "interactionDeadline",
                            Cell: ({ value }) => (
                              <>
                                {value?.length
                                  ? dayjs(value)?.format("DD/MM/YYYY")
                                  : "-"}
                              </>
                            ),
                          },
                          {
                            Header: "Criação",
                            accessor: "createdAt",
                            Cell: ({ value }) => (
                              <>{dayjs(value)?.format("DD/MM/YYYY HH:mm")}</>
                            ),
                          },
                          {
                            Header: "Envio",
                            accessor: "sendDate",
                            Cell: ({ value }) => (
                              <>
                                {value
                                  ? dayjs(value)?.format("DD/MM/YYYY HH:mm")
                                  : "-"}
                              </>
                            ),
                          },
                          {
                            Header: "Grupos",
                            accessor: "groups",
                            Cell: ({ value }) => <>{value?.length}</>,
                          },
                          {
                            Header: "Destinatários",
                            accessor: "employees",
                            Cell: ({ value }) => <>{value?.length}</>,
                          },
                          {
                            disableSortBy: true,
                            accessor: "_id",
                            sticky: "right",
                            Cell: ({ value, row }) => {
                              if (
                                !validateOwnership(
                                  user as any,
                                  row?.original
                                ) &&
                                row?.original?.status != "draft"
                              ) {
                                return <MenuIcon disabled options={[]} />;
                              }
                              return (
                                <MenuIcon
                                  options={[
                                    {
                                      label: "Ver detalhes da pesquisa",
                                      icon: "IconFileDescription",
                                      disabled:
                                        row?.original?.status === "draft" ||
                                        [
                                          "ShY1SYEMjLQ-qyCMYklnu",
                                          "3h8BQMy-fRc2nfCrLhyXE",
                                        ]?.includes(row?.original?._id)
                                          ? true
                                          : false,
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_viewdetails`,
                                        });
                                        navigate(
                                          `/engagement/survey/${
                                            row?.original?.model === "engaja"
                                              ? "engaja/"
                                              : ""
                                          }${
                                            row?.original?.recurrence?.status
                                              ? "recurrence/"
                                              : ""
                                          }${value}`
                                        );
                                      },
                                    },
                                    // {
                                    //   label: "Exportar relatório da pesquisa",
                                    //   icon: "IconDownload",
                                    //   onClick: () => {
                                    //     segment({
                                    //       track:
                                    //         "company_engagement_surveys_singlesend_exportreport_clicked",
                                    //     });
                                    //     selectCampaign(value, "export");
                                    //   },
                                    //   disabled: row?.original?.status === "draft",
                                    // },
                                    {
                                      label: "Duplicar pesquisa",
                                      disabled:
                                        row?.original?.model === "engaja",
                                      icon: "IconCopy",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_duplicatesurvey`,
                                        });
                                        copyCampaign(value);
                                      },
                                    },
                                    {
                                      label: "Editar prazo de resposta",
                                      icon: "IconCalendarStats",
                                      disabled:
                                        (row?.original?.interactionDeadline &&
                                          dayjs(
                                            dayjs()?.format("YYYY-MM-DD")
                                          ).diff(
                                            row?.original?.interactionDeadline
                                          ) > 0) ||
                                        row?.original?.status === "draft" ||
                                        row?.original?.model === "engaja",
                                      onClick: () => {
                                        segment({
                                          track: `company_engagement_surveys_singlesend_editdeadline_clicked`,
                                        });

                                        setIntd({
                                          ...intd,
                                          engagementId: row?.original?._id,
                                          visible: !intd?.visible,
                                          value:
                                            row?.original?.interactionDeadline,
                                        });
                                      },
                                    },
                                    {
                                      label: "Editar responsáveis",
                                      disabled:
                                        row?.original?.status === "draft",
                                      icon: "IconCheckupList",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_editresponsible`,
                                        });

                                        setOwnersEditModal({
                                          open: true,
                                          engagement: row?.original,
                                        });
                                      },
                                    },
                                    {
                                      label: "Editar pesquisa",
                                      icon: "IconPencil",
                                      disabled: [
                                        "draft",
                                        "scheduled",
                                      ]?.includes(row?.original?.status)
                                        ? false
                                        : true,
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_editsurvey`,
                                        });
                                        navigate(
                                          `/engagement/survey/edit/${value}`
                                        );
                                      },
                                    },
                                    {
                                      label: "Excluir pesquisa",
                                      icon: "IconTrash",
                                      disabled: [
                                        "draft",
                                        "scheduled",
                                      ]?.includes(row?.original?.status)
                                        ? false
                                        : true,
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_deletesurvey`,
                                        });
                                        selectCampaign(value, "delete");
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ]}
                        data={campaigns || []}
                      />
                    ) : (
                      <>
                        <Divider size="large" />
                        <NotFoundMessage
                          title={""}
                          message={"Você ainda não criou nenhuma pesquisa"}
                        >
                          <Button
                            size="large"
                            variant="primary"
                            onClick={() => navigate("/engagement/survey/add")}
                          >
                            {"Criar pesquisa"}
                          </Button>
                        </NotFoundMessage>
                      </>
                    )}
                  </>
                ),
              },
              {
                label: `Envios recorrentes (${recurrences?.length || 0})`,
                component: (
                  <>
                    {!empty ? (
                      <DataGrid
                        emptyState={{
                          isFiltered: true,
                          message: recurrenceFilters?.some((filter) => {
                            if (filter?.options?.length)
                              return filter?.options?.some((o) => o?.checked);

                            if (
                              filter?.dateRange?.from &&
                              filter?.dateRange?.to
                            )
                              return true;

                            return false;
                          })
                            ? "O filtro selecionado não apresenta resultados"
                            : "Você ainda não criou nenhuma pesquisa de envio recorrente",
                        }}
                        loading={refetchRecurrencesLoading}
                        searchField={{
                          value: recurrenceSearch || "",
                          onSearch: (value: string) =>
                            setRecurrenceSearch(value || ""),
                          placeholder: "Buscar por pesquisa",
                        }}
                        filterTags={{
                          filters: recurrenceFilters,
                          onFilter: (e) => setRecurrenceFilters(e),
                          onClear: (e) => setRecurrenceFilters(e),
                        }}
                        columns={[
                          {
                            Header: "Nome de identificação",
                            accessor: "name",
                          },
                          {
                            Header: "Modelo",
                            accessor: "model",
                            Cell: ({ value }) => {
                              const models = {
                                custom: "Personalizado",
                                climate: "Engajamento",
                                enps: "eNPS",
                                feeling: "Sentimento",
                              };

                              return models[value] || "";
                            },
                          },
                          {
                            Header: "Status",
                            accessor: "recurrence",
                            Cell: ({ value, row }) => {
                              const status =
                                row?.original?.status === "draft"
                                  ? "draft"
                                  : value?.status || "";

                              if (!status?.length) return <></>;

                              return (
                                <Tag variant={status}>
                                  {(() => {
                                    if (status === "draft") return "Rascunho";
                                    if (status === "send") return "Enviado";
                                    if (status === "scheduled")
                                      return "Não iniciado";
                                    if (status === "progress")
                                      return "Em andamento";
                                    if (status === "paused") return "Pausado";
                                    if (status === "closed") return "Encerrado";
                                  })()}
                                </Tag>
                              );
                            },
                          },
                          {
                            id: "anonymous",
                            Header: "Resposta Anônima",
                            // disableSortBy: true,
                            accessor: "anonymous",
                            Cell: ({ value }) => {
                              return (
                                <Tag variant={value ? "send" : "draft"}>
                                  {value ? "Sim" : "Não"}
                                </Tag>
                              );
                            },
                          },
                          {
                            Header: "Criação",
                            accessor: "createdAt",
                            Cell: ({ value }) => (
                              <>{dayjs(value)?.format("DD/MM/YYYY HH:mm")}</>
                            ),
                          },
                          {
                            Header: "Início",
                            accessor: "sendDate",
                            Cell: ({ value }) => (
                              <>
                                {value
                                  ? dayjs(value)?.format("DD/MM/YYYY HH:mm")
                                  : null}
                              </>
                            ),
                          },
                          {
                            id: "endDate",
                            Header: "Término",
                            accessor: "recurrence",
                            Cell: ({ value }) => (
                              <>
                                {value && value?.endDate
                                  ? dayjs(value?.endDate)?.format("DD/MM/YYYY")
                                  : null}
                              </>
                            ),
                          },
                          {
                            Header: "Grupos",
                            accessor: "groups",
                            Cell: ({ value }) => <>{value?.length}</>,
                          },
                          {
                            Header: "Destinatários",
                            accessor: "employees",
                            Cell: ({ value, row }) => (
                              <>
                                {row?.original?.recurrence?.employees?.length ||
                                  value?.length ||
                                  ""}
                              </>
                            ),
                          },
                          {
                            disableSortBy: true,
                            accessor: "_id",
                            sticky: "right",
                            Cell: ({ value, row }) => {
                              if (
                                !validateOwnership(
                                  user as any,
                                  row?.original
                                ) &&
                                row?.original?.status != "draft"
                              ) {
                                return <MenuIcon disabled options={[]} />;
                              }
                              return (
                                <MenuIcon
                                  options={[
                                    {
                                      label: "Ver detalhes da pesquisa",
                                      icon: "IconFileDescription",
                                      disabled:
                                        row?.original?.status === "draft" ||
                                        [
                                          "ShY1SYEMjLQ-qyCMYklnu",
                                          "3h8BQMy-fRc2nfCrLhyXE",
                                        ]?.includes(row?.original?._id)
                                          ? true
                                          : false,
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_viewdetails`,
                                        });
                                        navigate(
                                          `/engagement/survey/${
                                            row?.original?.model === "engaja"
                                              ? "engaja/"
                                              : ""
                                          }${
                                            row?.original?.recurrence?.status
                                              ? "recurrence/"
                                              : ""
                                          }${value}`
                                        );
                                      },
                                    },
                                    {
                                      label: "Retomar pesquisa",
                                      icon: "IconPlayerPlay",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_resumesurvey`,
                                        });
                                        updateRecStatus(
                                          row?.original?._id,
                                          "progress"
                                        );
                                      },
                                      disabled:
                                        row?.original?.recurrence?.status ===
                                        "paused"
                                          ? false
                                          : true,
                                    },
                                    {
                                      label: "Encerrar pesquisa",
                                      icon: "IconX",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_closesurvey`,
                                        });
                                        updateRecStatus(
                                          row?.original?._id,
                                          "closed"
                                        );
                                      },
                                      disabled:
                                        [
                                          "paused",
                                          "progress",
                                          "scheduled",
                                        ]?.includes(
                                          row?.original?.recurrence?.status
                                        ) && row?.original?.status !== "draft"
                                          ? false
                                          : true,
                                    },
                                    {
                                      label: "Pausar pesquisa",
                                      icon: "IconPlayerPause",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_pausesurvey`,
                                        });
                                        updateRecStatus(
                                          row?.original?._id,
                                          "paused"
                                        );
                                      },
                                      disabled:
                                        row?.original?.recurrence?.status ===
                                        "progress"
                                          ? false
                                          : true,
                                    },
                                    // {
                                    //   label: "Exportar relatório da pesquisa",
                                    //   icon: "IconDownload",
                                    //   onClick: () => {
                                    //     segment({
                                    //       track:
                                    //         "company_engagement_surveys_recurringsend_exportreport_clicked",
                                    //     });
                                    //     selectCampaign(value, "export");
                                    //   },
                                    //   disabled: row?.original?.status === "draft",
                                    // },
                                    {
                                      label: "Duplicar pesquisa",
                                      disabled:
                                        row?.original?.model === "engaja",
                                      icon: "IconCopy",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_duplicatesurvey`,
                                        });
                                        copyCampaign(value);
                                      },
                                    },
                                    {
                                      label: "Editar pesquisa",
                                      icon: "IconPencil",
                                      disabled:
                                        [
                                          "draft",
                                          "scheduled",
                                          "progress",
                                        ]?.includes(
                                          row?.original?.recurrence?.status
                                        ) || row?.status === "draft"
                                          ? false
                                          : true,
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_editsurvey`,
                                        });
                                        navigate(
                                          `/engagement/survey/edit/${value}`
                                        );
                                      },
                                    },
                                    {
                                      label: "Editar responsáveis",
                                      disabled:
                                        row?.original?.status === "draft",
                                      icon: "IconCheckupList",
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_editresponsible`,
                                        });
                                        setOwnersEditModal({
                                          open: true,
                                          engagement: row?.original,
                                        });
                                      },
                                    },
                                    {
                                      label: "Excluir pesquisa",
                                      icon: "IconTrash",
                                      disabled: [
                                        "draft",
                                        "scheduled",
                                      ]?.includes(row?.original?.status)
                                        ? false
                                        : true,
                                      onClick: () => {
                                        segment({
                                          track: `people_strategic_hr_engagement_company_surveys_deletesurvey`,
                                        });
                                        selectCampaign(value, "delete");
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ]}
                        data={recurrences || []}
                      />
                    ) : (
                      <>
                        <Divider size="large" />
                        <NotFoundMessage
                          title={""}
                          message={"Você ainda não criou nenhuma pesquisa"}
                        >
                          <Button
                            size="large"
                            variant="primary"
                            onClick={() => navigate("/engagement/survey/add")}
                          >
                            {"Criar pesquisa"}
                          </Button>
                        </NotFoundMessage>
                      </>
                    )}
                  </>
                ),
              },
              {
                label: totalEmployees
                  ? `Pessoas (${totalEmployees})`
                  : "Pessoas",
                component: (
                  <>
                    <PeopleTable
                      originPage="survey"
                      getTotalEmployees={(result) =>
                        setTotalEmployees(result || 0)
                      }
                    />
                  </>
                ),
              },
            ]}
          />
          <Modal
            title={"Tem certeza que deseja excluir este rascunho?"}
            message={
              "Excluir um rascunho irá removê-lo permanentemente da sua lista."
            }
            open={showConfirmModal}
            rightButton={{
              label: "Sim, excluir rascunho",
              onClick: () => deleteCampaign(),
            }}
            leftButton={{
              label: "Cancelar",
              onClick: () => {
                setSelectedCampaignId(undefined);
                setShowConfirmModal(!showConfirmModal);
              },
            }}
            status="alert"
            onClose={() => setShowConfirmModal(!showConfirmModal)}
          />
          <Modal
            title={"Pronto"}
            message={"Seu rascunho foi excluído com sucesso."}
            open={showSuccessModal}
            rightButton={{
              label: "Voltar para engajamento",
              onClick: () => setShowSuccessModal(!showSuccessModal),
            }}
            status="success"
            onClose={() => setShowSuccessModal(!showSuccessModal)}
          />
        </NewProvider>
        <InteractionDeadlineModalForm
          loading={updateInteractionDeadlineLoading}
          hideMode
          visible={intd?.visible}
          value={intd?.value}
          onChange={(e) => {
            setIntd({ ...intd, value: e });
          }}
          onClick={() => setIntd({ ...intd, visible: !intd?.visible })}
          onClose={() => setIntd({ ...intd, visible: !intd?.visible })}
          onFinish={() => {
            if (intd?.engagementId && intd?.value) {
              updateInteractionDeadline.mutate({
                id: intd?.engagementId,
                interactionDeadline: intd?.value,
              });
            }
          }}
        />
        <EditOwnersModal
          open={ownersEditModal?.open}
          engagement={ownersEditModal?.engagement}
          onClose={() =>
            setOwnersEditModal({
              open: false,
              engagement: undefined,
            })
          }
          onSuccess={() => refetchEngagements()}
        />
        <FeatureNotification id="survey-engaja-notification" />
      </PageContainer>
    </>
  );
};
