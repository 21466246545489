import { Typography } from "../Typography";
import { Divider } from "../v2";
import { Container } from "./styles";
import { EmptyStateProps } from "./types";

const EmptyState = ({ title, subTitle }: EmptyStateProps) => (
  <Container>
    <svg
      width="176"
      height="142"
      viewBox="0 0 176 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="35.5234"
        y="9"
        width="104.952"
        height="116"
        rx="12"
        fill="var(--color-neutral-90)"
      />
      <g filter="url(#filter0_d_6965_79854)">
        <rect
          x="24.4766"
          y="86.332"
          width="97.3571"
          height="27.619"
          rx="4"
          fill="white"
        />
        <rect
          x="27.2422"
          y="89.0938"
          width="22.0952"
          height="22.0952"
          rx="6"
          fill="var(--color-neutral-60)"
        />
        <path
          d="M38.8852 101.413V101.136C38.8852 99.4241 41.4814 99.3412 41.4814 97.2283C41.4814 95.6541 40.3628 94.8945 38.5952 94.8945C36.5237 94.8945 35.1014 96.165 35.2809 97.9188L36.5928 98.8579C36.3856 97.1731 37.2556 96.3722 38.4847 96.3722C39.3961 96.3722 39.8104 96.7726 39.8104 97.3802C39.8104 98.775 37.4214 98.9131 37.4214 101.012V101.413H38.8852ZM39.0233 104.285V102.324H37.2556V104.285H39.0233Z"
          fill="white"
        />
        <rect
          x="54.8594"
          y="93.2383"
          width="22.0952"
          height="4.14286"
          rx="2.07143"
          fill="var(--color-neutral-80)"
        />
        <rect
          x="54.8594"
          y="102.906"
          width="34.5238"
          height="4.14286"
          rx="2.07143"
          fill="var(--color-neutral-90)"
        />
      </g>
      <g filter="url(#filter1_d_6965_79854)">
        <rect
          x="54.1641"
          y="53.1914"
          width="97.3571"
          height="27.619"
          rx="4"
          fill="white"
        />
        <rect
          x="56.9297"
          y="55.9531"
          width="22.0952"
          height="22.0952"
          rx="6"
          fill="var(--color-neutral-60)"
        />
        <path
          d="M68.5727 68.272V67.9958C68.5727 66.2834 71.1689 66.2006 71.1689 64.0877C71.1689 62.5134 70.0503 61.7539 68.2827 61.7539C66.2112 61.7539 64.7889 63.0244 64.9684 64.7782L66.2803 65.7172C66.0731 64.0325 66.9431 63.2315 68.1722 63.2315C69.0836 63.2315 69.4979 63.632 69.4979 64.2396C69.4979 65.6344 67.1089 65.7725 67.1089 67.8715V68.272H68.5727ZM68.7108 71.1444V69.1834H66.9431V71.1444H68.7108Z"
          fill="white"
        />
        <rect
          x="84.5469"
          y="60.0977"
          width="22.0952"
          height="4.14286"
          rx="2.07143"
          fill="var(--color-neutral-80)"
        />
        <rect
          x="84.5469"
          y="69.7656"
          width="34.5238"
          height="4.14286"
          rx="2.07143"
          fill="var(--color-neutral-90)"
        />
      </g>
      <g filter="url(#filter2_d_6965_79854)">
        <rect
          x="24.4766"
          y="20.0469"
          width="97.3571"
          height="27.619"
          rx="4"
          fill="white"
        />
        <rect
          x="27.2422"
          y="22.8086"
          width="22.0952"
          height="22.0952"
          rx="6"
          fill="var(--color-neutral-60)"
        />
        <path
          d="M38.8852 35.1275V34.8513C38.8852 33.1389 41.4814 33.056 41.4814 30.9432C41.4814 29.3689 40.3628 28.6094 38.5952 28.6094C36.5237 28.6094 35.1014 29.8799 35.2809 31.6337L36.5928 32.5727C36.3856 30.8879 37.2556 30.087 38.4847 30.087C39.3961 30.087 39.8104 30.4875 39.8104 31.0951C39.8104 32.4899 37.4214 32.6279 37.4214 34.727V35.1275H38.8852ZM39.0233 37.9999V36.0389H37.2556V37.9999H39.0233Z"
          fill="white"
        />
        <rect
          x="54.8594"
          y="26.9531"
          width="22.0952"
          height="4.14286"
          rx="2.07143"
          fill="var(--color-neutral-80)"
        />
        <rect
          x="54.8594"
          y="36.6211"
          width="34.5238"
          height="4.14286"
          rx="2.07143"
          fill="var(--color-neutral-90)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6965_79854"
          x="0.476562"
          y="66.332"
          width="145.359"
          height="75.6172"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6965_79854"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6965_79854"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_6965_79854"
          x="30.1641"
          y="33.1914"
          width="145.359"
          height="75.6172"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6965_79854"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6965_79854"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_6965_79854"
          x="0.476562"
          y="0.046875"
          width="145.359"
          height="75.6172"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6965_79854"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6965_79854"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
    <Divider spacing="xs1" />
    <Typography
      variant="headline6"
      color="var(--color-neutral-20)"
      fontWeight={700}
    >
      {title || ""}
    </Typography>
    <Divider spacing="xxs3" />
    <Typography
      variant="body3"
      color="var(--color-neutral-40)"
      fontWeight={400}
    >
      {subTitle || ""}
    </Typography>
  </Container>
);

export { EmptyState };
