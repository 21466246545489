import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { alert, getPeriod } from "@utils";
import { TransferListInterface } from "@components/v2/TransferList/types";
import { OrganizationalUnit } from "../steps/Employee/types";
import { DraftSurvey } from "./types";

const useViewModel = (engagementId?: string) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successData, setSuccessData] = useState<{ date?: string }>({});
  const [filters, setFilters] = useState<
    TransferListInterface["filter"]["sections"]
  >([
    {
      id: "section-group",
      title: "Grupos",
      options: [],
      search: "",
    },
    {
      id: "section-department",
      title: "Departamento",
      options: [],
      search: "",
    },
    {
      id: "section-role",
      title: "Cargo",
      options: [],
      search: "",
    },
  ]);
  const [organizationalData, setOrganizationalData] = useState<{
    roles: OrganizationalUnit;
    groups: OrganizationalUnit;
    departments: OrganizationalUnit;
  }>({
    departments: [],
    groups: [],
    roles: [],
  });
  const [engagement, setEngagement] = useState<any>();
  const [employees, setEmployees] = useState<
    {
      id: string;
      name: string;
      email?: string;
      corporateEmail?: string;
    }[]
  >([]);
  const {
    data: models = [],
    refetch: getSurveyModels,
    isFetching: getSurveyModelsLoading,
  } = trpc?.getSurveyModels.useQuery(undefined, {
    retry: false,
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const { data: engajaPDFUrl } = trpc?.getS3File?.useQuery(
    {
      path: "people/engagement/termo_anuencia_engaja_2024.pdf",
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  const { refetch: getEngagementById, isFetching: getEngagementByIdLoading } =
    trpc?.getEngagementById?.useQuery(
      { id: engagementId || "" },
      {
        retry: false,
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => setEngagement(data),
        onError: (e: any) => {
          console.log("err: ", e);
        },
      }
    );

  const { mutate: upsertSurveyMutation, isLoading: upsertSurveyLoading } =
    trpc?.upsertSurvey?.useMutation({
      onSuccess: (e) => {
        setIsModalOpen(true);
      },
      onError: (e: any) => {
        alert(e?.message, "error");
      },
    });

  const { isFetching: getInternalEmployeesByCompanyIdLoading } =
    trpc?.getInternalEmployeesByCompanyId?.useQuery(
      {
        status: "ACTIVE",
      },
      {
        retry: false,
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          if (data?.employees?.length)
            setEmployees([
              ...data?.employees?.map(
                ({ _id, name, email, corporateEmail }) => ({
                  id: _id,
                  name,
                  email,
                  corporateEmail,
                })
              ),
            ]);
        },
        onError: (e: any) => {
          console.log("err: ", e);
        },
      }
    );

  trpc?.getOrganizationalData?.useQuery(undefined, {
    retry: false,
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: (data) =>
      setOrganizationalData({
        departments: data?.departments?.map((department) => ({
          id: department?._id,
          name: department?.name,
          employees: department?.employees || [],
        })),
        groups: data?.groups?.map((department) => ({
          id: department?._id,
          name: department?.name,
          employees: department?.employees?.map((e) => e?._id) || [],
        })),
        roles: data?.roles?.map((role) => ({
          id: role?._id,
          name: role?.name,
          employees: role?.employees || [],
        })),
      }),
    onError: () => alert("Erro ao buscar cargos, grupos e departamentos."),
  });

  useEffect(() => {
    if (
      organizationalData?.roles?.length ||
      organizationalData?.groups?.length ||
      organizationalData?.departments?.length
    ) {
      const groupOptions = organizationalData?.groups?.map(({ id, name }) => ({
        value: id,
        title: name,
        asideText: "",
        checked: false,
        hidden: false,
      }));

      const departmentOptions = organizationalData?.departments?.map(
        ({ id, name }) => ({
          value: id,
          title: name,
          asideText: "",
          checked: false,
          hidden: false,
        })
      );

      const roleOptions = organizationalData?.roles?.map(({ id, name }) => ({
        value: id,
        title: name,
        asideText: "",
        checked: false,
        hidden: false,
      }));

      const updatedFilters = filters?.map(({ id, ...rest }) => {
        if (id === "section-role") return { id, ...rest, options: roleOptions };
        if (id === "section-group")
          return { id, ...rest, options: groupOptions };
        if (id === "section-department")
          return { id, ...rest, options: departmentOptions };
        return { id, ...rest };
      });

      if (updatedFilters?.length) setFilters([...updatedFilters]);
    }
  }, [organizationalData]);

  useEffect(() => {
    if (!models?.length) getSurveyModels();
  }, []);

  useEffect(() => {
    if (engagementId && !engagement?._id) getEngagementById();
  }, [engagementId]);

  const upsertSurvey = ({ values }: { values: DraftSurvey }) => {
    const now = dayjs();
    const currentDate = now?.format("YYYY-MM-DD");
    const currentPeriod = getPeriod(dayjs());

    const {
      _id,
      interactionDeadline,
      interactionDeadlineDays,
      employees,
      recurrence,
      companyData,
      isDraft,
      startDate,
      ...rest
    } = values;

    const status = isDraft
      ? "draft"
      : values?.delivery === "continuous" ||
        (getPeriod(dayjs()) === values?.startDate?.period &&
          dayjs()?.format("YYYY-MM-DD") === values?.startDate?.date)
      ? "send"
      : "scheduled";

    if (_id) rest["_id"] = _id;
    if (values?.delivery === "single") {
      if (interactionDeadline)
        rest["interactionDeadline"] = interactionDeadline;

      rest["startDate"] = startDate;
      rest["employees"] = employees || [];
    }

    if (values?.delivery === "continuous") {
      rest["startDate"] = {
        date: currentDate,
        period: currentPeriod,
      };
      rest["interactionDeadlineDays"] = interactionDeadlineDays || 0;
      rest["employees"] = [];
    }

    if (values?.delivery === "recurring") {
      rest["startDate"] = startDate;
      rest["employees"] = employees || [];
      rest["recurrence"] = {
        daysOfTheWeek:
          recurrence?.frequency === "week"
            ? recurrence?.daysOfTheWeek
                ?.filter((d) => d?.checked)
                ?.map((d) => d?.value) || []
            : [],
        employees: employees || [],
        endDate: recurrence?.endDate,
        frequency: recurrence?.frequency,
        interactionDeadlineConfig: {
          calculatedDate: {
            multiplier:
              recurrence?.interactionDeadlineConfig?.calculatedDate
                ?.multiplier || 1,
            period:
              recurrence?.interactionDeadlineConfig?.calculatedDate?.period ||
              "day",
          },
          nextMode: recurrence?.interactionDeadlineConfig?.nextMode || false,
        },
        occurrenceLimit: recurrence?.occurrenceLimit,
        repeat: recurrence?.repeat || 0,
        status: status === "send" ? "progress" : "scheduled",
      };
    }

    if (values?.modelId?.includes("engaja_")) {
      rest["companyData"] = {
        economicSector: values?.companyData?.economicSector || "other",
        employees: values?.companyData?.employees || "unknown",
        size: values?.companyData?.size || "unknown",
      };
    }

    upsertSurveyMutation({
      ...rest,
      status,
      modelId: values?.modelId || null,
      name: values?.name || "",
      anonymous: values?.anonymous || false,
      description: values?.description,
      notifications: {
        email: values?.notifications?.email || false,
        push: values?.notifications?.push || false,
        reminder: {
          enabled: values?.notifications?.reminder?.enabled || false,
          minRate: values?.notifications?.reminder?.minRate || 0,
        },
      },
      title: values?.title,
      owners: values?.owners || [],
      sections:
        values?.sections?.map((s) => ({
          ...s,
          title: s?.title || "",
          description: s?.description || undefined,
          referenceId: s?.referenceId,
          questions:
            s?.questions?.map((q) => {
              let questionComplement: any = {};

              if (
                ["emoji", "heart", "star", "scale"]?.includes(
                  q?.answerFormat || ""
                )
              ) {
                questionComplement = {
                  ...questionComplement,
                  upperLabel: q?.upperLabel,
                  lowerLabel: q?.lowerLabel,
                  scaleRange: {
                    min: q?.scaleRange?.min,
                    max: q?.scaleRange?.max,
                  },
                };
              }

              if (
                [
                  "multiple-choice",
                  "select-box",
                  "multi-select",
                  "dropdown",
                ]?.includes(q?.answerFormat || "")
              ) {
                if (
                  ["multiple-choice", "select-box"]?.includes(
                    q?.answerFormat || ""
                  )
                ) {
                  questionComplement = {
                    ...questionComplement,
                    justification: q?.justification || false,
                  };
                }

                questionComplement = {
                  ...questionComplement,
                  options:
                    q?.options?.map((o) => ({
                      referenceId: o?.referenceId,
                      value: o?.value || "",
                    })) || [],
                };
              }

              return {
                referenceId: q?.referenceId,
                required: q?.required || false,
                isENPS: q?.isENPS || false,
                title: q?.title || "",
                answerFormat: q?.answerFormat || "text",
                ...questionComplement,
              };
            }) || [],
        })) || [],
    });
  };

  const loading = {
    getSurveyModels: getSurveyModelsLoading,
    getEngagementById: getEngagementByIdLoading,
    getInternalEmployeesByCompanyId: getInternalEmployeesByCompanyIdLoading,
    upsertSurvey: upsertSurveyLoading,
  };

  return {
    models,
    engagement,
    loading,
    employees,
    organizationalData,
    filters,
    engajaPDFUrl,
    setFilters,
    upsertSurvey,
    isModalOpen,
    setIsModalOpen,
    successData,
  };
};

export { useViewModel };
