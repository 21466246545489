export type DaysOfTheWeek =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export type SurveyModel = {
  _id: string;
  name: string;
  caption: string;
  description: string;
  icon: string;
  news: boolean;
  estimatedTimeInMinutes: number;
  delivery: ["single" | "recurring" | "continuous"];
  companyIds: string[];
  active: boolean;
  link?: string;

  minRecipients: number;

  notification: {
    push: {
      userCanEdit: boolean;
      value: boolean;
    };
    email: {
      userCanEdit: boolean;
      value: boolean;
    };
    reminder: {
      userCanEdit: boolean;
      value: boolean;
      minRate: number;
    };
  };

  anonymous: {
    userCanEdit: boolean;
    value: boolean;
  };

  sections: {
    userCanDelete: boolean;
    id: string;
    title: {
      userCanEdit: boolean;
      value: string;
    };
    description: {
      userCanEdit: boolean;
      value: string;
    };
    questions: {
      userCanDelete: boolean;
      id: string;
      isENPS: boolean;
      required: {
        userCanEdit: boolean;
        value: boolean;
      };
      title: {
        userCanEdit: boolean;
        value: string;
      };
      answerFormat: {
        userCanEdit: boolean;
        value:
          | "text"
          | "date"
          | "multiple-choice"
          | "select-box"
          | "multi-select"
          | "dropdown"
          | "emoji"
          | "heart"
          | "star"
          | "scale";
      };
      justification: {
        userCanDelete: boolean;
        value: boolean;
      };
      options?: {
        userCanDelete: boolean;
        id: string;
        label: {
          userCanEdit: boolean;
          value: string;
        };
      }[];
      upperLabel?: {
        userCanEdit: boolean;
        value: string;
      };
      lowerLabel?: {
        userCanEdit: boolean;
        value: string;
      };
      scaleRange?: {
        userCanEdit: boolean;
        value: {
          min: number;
          max: number;
        };
      };
    }[];
  }[];

  analysisConfig: {
    performance: boolean;
    extremeAverages: boolean;
    averagePerQuestion: boolean;
    ENPS: boolean;
    heatmap: boolean;
  };
};

export type Engagement = {
  name: string;
  owners: { id: string; name: string }[];
  type: "communication" | "research" | "enps";
  status: "draft" | "send" | "scheduled";
  modelId?: string;
  model?:
    | "voluntaryExit"
    | "involuntaryExit"
    | "feeling"
    | "engaja"
    | "climate"
    | "enps"
    | "custom";
  enpsSection?: boolean;
  createdBy?: string;
  createdAt?: Date;
  updatedAt?: Date;
  sendDate?: Date;
  _id?: string;
  employees?: string[];
  groups?: string[];
  companyId?: string;
  active?: boolean;
  templateId?: string;
  notifications?: {
    push?: boolean;
    email?: boolean;
    reminder?: {
      enabled: boolean;
      minRate?: number;
      lastSentDate?: Date;
    };
  };

  like?: boolean;
  title?: string;
  message?: string;
  link?: {
    label: string;
    url: string;
  };
  imageUrl?: {
    origin: string;
    path: string;
    signedUrl?: string;
  };
  pdfFile?: {
    origin: string;
    path: string;
    signedUrl?: string;
  };

  sections?: {
    id?: string;
    referenceId?: string;
    title?: string;
    description?: string;
    questions?: {
      id?: string;
      referenceId?: string;
      isENPS?: boolean;
      required?: boolean;
      title?: string;
      answerFormat?:
        | "text"
        | "date"
        | "multiple-choice"
        | "select-box"
        | "multi-select"
        | "dropdown"
        | "emoji"
        | "heart"
        | "star"
        | "scale";
      justification?: boolean;
      options?: {
        id?: string;
        referenceId?: string;
        value?: string;
      }[];
      upperLabel?: string;
      lowerLabel?: string;
      scaleRange?: {
        min?: number;
        max?: number;
      };
    }[];
  }[];
  description?: string;
  anonymous?: boolean;
  monitoring?: {
    companyId?: string;
    employeeId?: string;
    engagementId?: string;
    like?: number;
    open?: boolean;
    visualized?: boolean;
    draft?: boolean;
    sections?: {
      id: string;
      questions: {
        id: string;
        value: string | number | string[];
        justification?: string;
      }[];
    }[];
  };
  analytics?: {
    answerRate?: number;
    answers?: number;
    averageRating?: number;
    comments?: number;
    companyId?: string;
    detractors?: number;
    employees?: number;
    engagementId?: string;
    engagements?: number;
    enpsAnswerRate?: number;
    enpsAnswers?: number;
    enpsScore?: number;
    like?: number[];
    likeCount?: number;
    likeRate?: number;
    neutrals?: number;
    open?: number;
    openRate?: number;
    promoters?: number;
    ratingAccumulator?: number;
    views?: string[];
    visualization?: number;
    visualizationRate?: number;
  };

  engagementOrigin?: string;

  interactionDeadline?: string;
  interactionDeadlineDays?: number;
  startDate?: {
    date?: string;
    period?: "morning" | "afternoon" | "night";
  };

  recurrence?: {
    employees?: string[];
    status?: "scheduled" | "progress" | "paused" | "closed";
    repeat?: number;
    frequency?: "day" | "week" | "month" | "year";
    active?: boolean;

    daysOfTheWeek?: DaysOfTheWeek;

    occurrenceLimit?: number;
    endDate?: string;

    interactionDeadlineConfig?: {
      nextMode?: boolean;

      calculatedDate?: {
        multiplier?: number;
        period?: "day" | "week" | "month";
      };
    };

    lastDispatchDate?: string;
  };
};

export type DraftQuestion = {
  referenceId?: string;
  required?: boolean;
  isENPS?: boolean;
  title?: string;
  answerFormat?:
    | "text"
    | "date"
    | "multiple-choice"
    | "select-box"
    | "multi-select"
    | "dropdown"
    | "emoji"
    | "heart"
    | "star"
    | "scale";
  justification?: boolean;
  options?: {
    referenceId?: string;

    value?: string;
  }[];
  upperLabel?: string;
  lowerLabel?: string;
  scaleRange?: {
    min?: number;
    max?: number;
  };
};

export type DraftSection = {
  referenceId?: string;
  title?: string;
  description?: string;
  questions?: DraftQuestion[];
};

export type DraftSurvey = {
  termsAndConditions?: {
    acceptedBy?: string;
    date?: string;
    type?: string;
  };
  everyoneWithAccess?: boolean;
  delivery?: "single" | "recurring" | "continuous";
  selectedSectionIndex?: number;
  modelId?: string | null;
  name?: string;
  isDraft?: boolean;

  _id?: string;
  owners?: { id: string; name: string }[];
  title?: string;
  employees?: string[];
  notifications?: {
    push?: boolean;
    email?: boolean;
    reminder?: {
      enabled?: boolean;
      minRate?: number;
    };
  };
  startDate?: {
    date?: string;
    period?: "morning" | "afternoon" | "night";
  };
  recurrence?: {
    employees?: string[];

    repeat?: number;
    frequency?: "day" | "week" | "month" | "year";
    daysOfTheWeek?: {
      label: string;
      value:
        | "monday"
        | "tuesday"
        | "wednesday"
        | "thursday"
        | "friday"
        | "saturday"
        | "sunday";
      checked: boolean;
    }[];
    occurrenceLimit?: number;
    endDate?: string;
    interactionDeadlineConfig?: {
      nextMode?: boolean;
      calculatedDate?: {
        multiplier?: number;
        period?: "day" | "week" | "month";
      };
    };
  };
  interactionDeadline?: string;
  interactionDeadlineDays?: number;
  companyData?: {
    economicSector?:
      | "industry"
      | "retail"
      | "wholesale"
      | "services"
      | "financial"
      | "education"
      | "healthcare"
      | "culture"
      | "construction"
      | "technology"
      | "transportation"
      | "other";
    employees?:
      | "1-50"
      | "51-200"
      | "201-500"
      | "501-1000"
      | "1001-5000"
      | "5000+"
      | "unknown";
    size?: "mei" | "microenterprise" | "small" | "medium" | "large" | "unknown";
  };
  description?: string;
  anonymous?: boolean;
  sections?: DraftSection[];
};

export type SimplifiedQuestion = {
  id: string;
  title: string;
  answerFormat: string;
};

export type SimplifiedSection = {
  id: string;
  title: string;
  description: string;
  questions: SimplifiedQuestion[];
};

export const convertSections = (
  sections: SurveyModel["sections"] | undefined
): SimplifiedSection[] => {
  return (
    sections?.map((section) => ({
      id: section.id,
      title: section.title.value,
      description: section.description.value,
      questions: section.questions.map((question) => ({
        id: question.id,
        title: question.title.value,
        answerFormat: question.answerFormat.value,
      })),
    })) || []
  );
};
