import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding: 0px;
`;

export const SectionContainer = styled.div`
  gap: 24px;
`;

export const QuestionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 54px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-neutral-90);

  &:last-child {
    border-bottom: none;
  }
`;

export const QuestionsHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 16px;
  padding-bottom: 6px;
  margin-bottom: 4px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
