import {
  Icons,
  LinkButton,
  Typography,
  IconTypes,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  CloseButton,
  WarnArea,
  WarnContent,
  WarnDescription,
  WarnIcon,
  WarnTitle,
} from "./styled";
import { ReactNode, useState } from "react";

const MaintenanceCard = ({
  title,
  description,
  iconName,
  closeButton,
  type,
  button,
}: {
  title: string;
  description: ReactNode;
  iconName?: IconTypes;
  type?: "info" | "error";
  closeButton?: boolean;
  button?: {
    title: string;
    onClick: () => void;
  };
}) => {
  const [open, setOpen] = useState(true);

  if (open) {
    return (
      <WarnArea type={type}>
        <WarnIcon type={type}>
          <Icons
            name={iconName || "IconAlertCircle"}
            size={30}
            fill="transparent"
          />
        </WarnIcon>
        <div>
          <WarnContent>
            <WarnTitle variant="body3" type={type}>
              {title}
            </WarnTitle>
            <WarnDescription variant="body4" type={type}>
              {description}
            </WarnDescription>
          </WarnContent>
          {button && (
            <LinkButton onClick={button?.onClick} variant="neutral">
              <Typography variant="caption">{button?.title}</Typography>
              <Icons name="IconArrowUpRight" fill="transparent" size={16} />
            </LinkButton>
          )}
        </div>
        {closeButton ? (
          <CloseButton type={type} onClick={() => setOpen(false)}>
            <Icons name="IconX" fill="transparent" size={15} />
          </CloseButton>
        ) : null}
      </WarnArea>
    );
  } else return <></>;
};

export { MaintenanceCard };
