import { dayjs, Icons, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

import { StyleContainer } from "./styles";
import { TagList } from "@components/TagList";
import { useUser } from "src/hooks/useUser";
import { Typography } from "@components/Typography";
import { Divider } from "@components/v2";
import { StepContainer } from "../../components/StepContainer";
import { Box } from "../../components/Box";
import {
  EditStepButton,
  TableReview,
} from "@components/EngagementForm/survey/steps/StepReview/components";
import { Tag } from "@components/Tag";
import { SurveyModel } from "../../screen/types";

const Review = ({
  formik,
  onStepClick,
  selectedReviewStep,
  employees,
  selectedModel,
}: {
  formik: any;
  onStepClick: (step: number) => void;
  selectedReviewStep: number;
  onReviewStepSelect?: (step: number) => void;
  employees: { id: string; name: string }[];
  selectedModel: SurveyModel | undefined;
}) => {
  const user = useUser();
  const formatInteractionDeadline = () => {
    if (formik.values.interactionDeadline) {
      return dayjs(formik.values.interactionDeadline).format("DD/MM/YYYY");
    }
    if (formik.values.interactionDeadlineDays > 0) {
      return `${formik.values.interactionDeadlineDays} dias após o envio`;
    }
    return "Não definido";
  };

  const formatRecurrence = () => {
    if (!formik.values.recurrence) {
      return formik.values.delivery === "single"
        ? "Essa pesquisa foi configurada para envio único."
        : null;
    }

    const { frequency, repeat, daysOfTheWeek, endDate, occurrenceLimit } =
      formik.values.recurrence;
    const parts: any = [];

    if (frequency && repeat) {
      if (frequency === "day") {
        parts.push(`A cada ${repeat} dia(s)`);
      } else if (
        frequency === "week" &&
        daysOfTheWeek?.some((d: any) => d.checked)
      ) {
        const days = daysOfTheWeek
          .filter((d: any) => d.checked)
          .map((d: any) => d.label)
          .join(", ");
        parts.push(`Toda(s) ${days}`);
      } else if (frequency === "month") {
        parts.push(`Dia ${repeat} de cada mês`);
      }
    }

    if (endDate) {
      parts.push(`até ${dayjs(endDate).format("DD/MM/YYYY")}`);
    } else if (occurrenceLimit && occurrenceLimit > 0) {
      parts.push(`por ${occurrenceLimit} ocorrência(s)`);
    }

    return parts.length > 0 ? parts.join(" ") : null;
  };

  return (
    <StepContainer>
      <StyleContainer>
        {selectedReviewStep === 0 && (
          <>
            <Box>
              <EditStepButton onClick={() => onStepClick(1)} />
              <Typography
                variant="headline8"
                fontWeight={700}
                color="var(--color-neutral-30)"
              >
                Revisar detalhes
              </Typography>
              <Divider spacing={"quark"} />
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-50)"
              >
                Confira os detalhes da pesquisa.
              </Typography>
              <Divider spacing="s1" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Modelo da pesquisa
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {selectedModel?.name || "Não definido"}
              </Typography>
              <Divider spacing="xs2" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Nome de identificação da pesquisa
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {formik.values.name || "Não definido"}
              </Typography>
              <Divider spacing="xs2" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Nome de exibição pública
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {formik.values.title || "Não definido"}
              </Typography>
              <Divider spacing="xs2" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Descrição
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {formik.values.description || "Não definido"}
              </Typography>
            </Box>
            <Divider spacing="xs2" />
          </>
        )}

        {selectedReviewStep === 1 && (
          <>
            <Box>
              <EditStepButton onClick={() => onStepClick(2)} />
              <Typography
                variant="headline8"
                fontWeight={700}
                color="var(--color-neutral-30)"
              >
                Revisar envio
              </Typography>
              <Divider spacing={"quark"} />
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-50)"
              >
                Confira quando sua pesquisa será enviada.
              </Typography>
              <Divider spacing="s1" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                {formik.values.recurrenceMode
                  ? "Início da recorrência"
                  : "Data de envio"}
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {formik.values.startDate?.date
                  ? dayjs(formik.values.startDate.date).format("DD/MM/YYYY")
                  : "Não definido"}
              </Typography>
              <Divider spacing="xs2" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Período
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {formik.values.startDate?.period === "morning"
                  ? "Manhã"
                  : formik.values.startDate?.period === "afternoon"
                  ? "Tarde"
                  : formik.values.startDate?.period === "night"
                  ? "Noite"
                  : "Não definido"}
              </Typography>

              <Divider spacing="xs2" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Prazo de resposta
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {formatInteractionDeadline()}
              </Typography>

              {formik.values.delivery === "recurring" &&
                formik.values.recurrence && (
                  <>
                    <Divider spacing="xs2" />
                    <Typography
                      variant="body4"
                      fontWeight={700}
                      color="var(--color-neutral-50)"
                    >
                      Configuração de recorrência
                    </Typography>
                    <Typography
                      variant="body3"
                      fontWeight={600}
                      color="var(--color-neutral-40)"
                    >
                      {formatRecurrence() || "Não definido"}
                    </Typography>
                  </>
                )}
              {formik.values.delivery === "single" && (
                <>
                  <Divider spacing="xs2" />
                  <Typography
                    variant="body4"
                    fontWeight={700}
                    color="var(--color-neutral-50)"
                  >
                    Configuração de envio
                  </Typography>
                  <Typography
                    variant="body3"
                    fontWeight={600}
                    color="var(--color-neutral-40)"
                  >
                    Essa pesquisa foi configurada para envio único.
                  </Typography>
                </>
              )}
            </Box>
          </>
        )}

        {selectedReviewStep === 2 && (
          <>
            <Box>
              <EditStepButton onClick={() => onStepClick(3)} />
              <Typography
                variant="headline8"
                fontWeight={700}
                color="var(--color-neutral-30)"
              >
                Revisar configurações
              </Typography>
              <Divider spacing={"quark"} />
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-50)"
              >
                Confira as configurações habilitadas.
              </Typography>
              <Divider spacing="s1" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Anonimato
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {formik.values.anonymous
                  ? "Padrão - Habilitado (as pessoas não serão identificadas)"
                  : "Padrão - Desabilitado (as pessoas serão identificadas)"}
              </Typography>

              {(formik.values.notifications?.email ||
                formik.values.notifications?.push) && (
                <>
                  <Divider spacing="s1" />
                  <Typography
                    variant="body4"
                    fontWeight={700}
                    color="var(--color-neutral-50)"
                  >
                    Notificações
                  </Typography>
                  <Typography
                    variant="body3"
                    fontWeight={600}
                    color="var(--color-neutral-40)"
                  >
                    {`Habilitado - ${
                      formik.values.notifications?.push
                        ? "Push no aplicativo"
                        : ""
                    }${
                      formik.values.notifications?.push &&
                      formik.values.notifications?.email
                        ? " e "
                        : ""
                    }${
                      formik.values.notifications?.email
                        ? "E-mail corporativo"
                        : ""
                    }`}
                  </Typography>
                </>
              )}

              <Divider spacing="s1" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Envio da notificação
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {formik.values.notifications?.reminder?.enabled
                  ? `Notificar a cada 3 dias até atingir a taxa de resposta de ${formik.values.notifications?.reminder?.minRate}%`
                  : "Desabilitado"}
              </Typography>

              <Divider spacing="s1" />
              <div
                style={{ display: "flex", flexDirection: "row", gap: "4px" }}
              >
                <Typography
                  variant="body4"
                  fontWeight={700}
                  color="var(--color-neutral-50)"
                >
                  Criador
                </Typography>
                <Tooltip
                  title="O criador é um responsável que não pode ser removido da lista de responsáveis e suas permissões não podem ser modificadas."
                  placement="top-start"
                >
                  <button>
                    <Icons
                      name="IconInfoCircle"
                      fill="transparent"
                      color="#83727D"
                      size={16}
                    />
                  </button>
                </Tooltip>
              </div>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {user?.name}
              </Typography>

              <Divider spacing="s1" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Responsáveis
              </Typography>
              <Divider spacing={"quark"} />
              {formik.values.everyoneWithAccess ? (
                <Typography
                  variant="body3"
                  fontWeight={600}
                  color="var(--color-neutral-40)"
                >
                  Todos com acesso ao módulo
                </Typography>
              ) : (
                <TagList
                  tags={
                    formik.values.owners?.map((owner: any) => ({
                      id: owner?.id,
                      label: owner?.name,
                    })) ?? []
                  }
                />
              )}
            </Box>
            <Divider spacing="xs2" />
          </>
        )}

        {selectedReviewStep === 3 && (
          <>
            <Box>
              <EditStepButton onClick={() => onStepClick(4)} />
              <Typography
                variant="headline8"
                fontWeight={700}
                color="var(--color-neutral-30)"
              >
                Revisar perguntas
              </Typography>
              <Divider spacing={"quark"} />
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-50)"
              >
                Confira as perguntas que serão enviadas nessa pesquisa.
              </Typography>
              <Divider spacing="s1" />
              <TableReview sections={formik.values.sections} />
            </Box>
            <Divider spacing="xs2" />
          </>
        )}

        {selectedReviewStep === 4 && (
          <>
            <Box>
              {formik.values.delivery !== "continuous" && (
                <EditStepButton onClick={() => onStepClick(5)} />
              )}
              <Typography
                variant="headline8"
                fontWeight={700}
                color="var(--color-neutral-30)"
              >
                Revisar destinatários
              </Typography>
              <Divider spacing={"quark"} />
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-50)"
              >
                Confira as pessoas para quem a pesquisa será enviada.
              </Typography>
              <Divider spacing="s1" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Pessoas
              </Typography>
              <Divider spacing={"quark"} />
              {formik.values.employees?.length > 0 ? (
                <>
                  {formik.values.employees
                    ?.slice(0, 11)
                    ?.map((employeeId: string) => {
                      const employee = employees?.find(
                        (e) => e.id === employeeId
                      );
                      return (
                        <Tag key={employeeId} variant="item">
                          {employee?.name || employeeId}
                        </Tag>
                      );
                    })}
                  {formik.values.employees.length > 11 && (
                    <Tag variant="item">{`+ ${
                      formik.values.employees.length - 11
                    }`}</Tag>
                  )}
                </>
              ) : (
                <Typography
                  variant="body3"
                  fontWeight={600}
                  color="var(--color-neutral-40)"
                >
                  Nenhum destinatário selecionado
                </Typography>
              )}
            </Box>
            <Divider spacing="xs2" />
          </>
        )}
      </StyleContainer>
    </StepContainer>
  );
};

export { Review };
